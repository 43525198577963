import { connect, batch } from 'react-redux';

import TemplateEditableElement from './component';

import {templateTypeSelector} from "../../../selectors/template";
import {
    editableElementDataSelector,
    createEditableStyleSelector, createFontSizeSiblingsSelector
} from "../../../selectors/editableElement";
import {
    templateEditableElementInitialized,
    templateEditableElementRemove,
    editableFocused,
    editableUnfocused,
} from "../../../actions/templateActions";
import {textValignOverrideChanged, themeFontScaleUpdated} from "../../../actions/interfaceActions";
import {checkNested, getNested, zconsole} from "../../../../business/helpers/utilities";
import {camelCase} from "lodash";
import {
    updateEditableTextDataRequested,
    updateTextFundamentalFontScaleRequested, updateTextMetricsRequested,
    updateTextNoOverflowFontScaleRequested
} from "../../../../business/actions/businessActions";
import {isLegacyTemplateSelector, isPublicTemplateSelector, templateIdSelector} from "../../../selectors/staticInfo";

const mapStateToProps = (state, ownProps) => {
    const editableStyleSelector = createEditableStyleSelector();
    const textSiblingsSelector = createFontSizeSiblingsSelector(state, ownProps);

    return {
        //TemplateEditableElement props
        templateType: templateTypeSelector(state),
        editableData: editableElementDataSelector(state, ownProps),
        textSiblings: textSiblingsSelector(state, ownProps),
        styles: editableStyleSelector(state, ownProps),
        isLegacyTemplate: isLegacyTemplateSelector(state),
        isPublicTemplate: isPublicTemplateSelector(state),
        templateId: templateIdSelector(state),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    elementInitializedDispatch: (type, id, ref, isNonBackgroundImage) => dispatch(templateEditableElementInitialized(type, id, ref, isNonBackgroundImage)),
    elementRemoveDispatch: (type, id) => dispatch(templateEditableElementRemove(type, id)),
    onMouseEnter: () => dispatch(editableFocused(ownProps.id)),
    onMouseLeave: () => dispatch(editableUnfocused(ownProps.id)),
    resetValignValue: (value = 0) => dispatch(textValignOverrideChanged(ownProps.id, value)),
    updateTextFundamentalFontScale: (scale) => dispatch(updateTextFundamentalFontScaleRequested(ownProps.id, scale)),
    updateTextNoOverflowFontScale: (scale) => dispatch(updateTextNoOverflowFontScaleRequested(ownProps.id, scale)),
    updateTextMetrics: (metrics) => dispatch(updateTextMetricsRequested(ownProps.id, metrics)),
    updateEditableTextData: (metrics, adjustments) => dispatch(updateEditableTextDataRequested(ownProps.id, metrics, adjustments)),
    handleFontSizeChange: (scale) => {
        const globalFontSizeName = checkNested(ownProps, 'nodeData', 'attributes', 'data-editable-fontsize') ? camelCase(getNested(ownProps, 'nodeData', 'attributes', 'data-editable-fontsize')) : null;
        if(globalFontSizeName){
            dispatch(themeFontScaleUpdated(globalFontSizeName, parseFloat(scale)));
        }
    },
});

const mergeProps = (stateProps, dispatchProps, ownProps) =>({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    elementInitialized: (ref, isNonBackgroundImage) => dispatchProps.elementInitializedDispatch(stateProps.templateType, ownProps.id, ref, isNonBackgroundImage),
    elementRemove: () => dispatchProps.elementRemoveDispatch(stateProps.templateType, ownProps.id),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TemplateEditableElement);
