import { connect } from 'react-redux';
import ImageLibrary from '../ImageLibrary/container';
import * as ui from '../../actions/interfaceActions';

const mapStateToProps = (state, ownProps) => {
    return {
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onSelectImage: (libraryImageId) => dispatch(ui.libraryImageClicked(ownProps.id, libraryImageId)),
    onDeleteImage: (imageId) =>  dispatch(ui.userUploadDeleteClicked(imageId)),
    deletable: ownProps.libraryCode === 'user',
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageLibrary);
