import { combineReducers } from 'redux';

import staticInfo from "./staticInfo";
import templateName from './templateName';
import templateId from './templateId';
import templateType from './templateTypeReducer';
import downloading from './downloading';
import entities from './entities';
import theme from './theme';
import defaultTheme from './defaultTheme';
import rectangleTemplateData from './rectangleTemplateDataReducer';
import squareTemplateData from './squareTemplateDataReducer';
import verticalTemplateData from './verticalTemplateDataReducer';

const businessReducer = combineReducers({
    templateId,
    templateName,
    staticInfo,
    templateType,
    downloading,
    entities,
    theme,
    defaultTheme,
    rectangleTemplateData,
    squareTemplateData,
    verticalTemplateData,
});

export default businessReducer;
