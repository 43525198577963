import { SET_FLASH, CLEAR_FLASH } from '../actions/flashActions';

const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action)=>{
    const {type, payload} = action;

    switch (type) {
        case SET_FLASH: {
            return [
                ...state,
                payload.flashData,
            ];
        }
        case CLEAR_FLASH: {
            return state.filter((flashData) => flashData.id !== payload.id );
        }
        // TODO why for is that?
        // case type && /(.*:STATE_MODIFIER:.*)/.test(type): {// Clear flash on all state modifying actions
        //     return INITIAL_STATE;
        // }
        default: {
            return state;
        }
    }
};
