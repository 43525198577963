import lodash from 'lodash';

import { EDITABLE_ATTRIBUTES, EDITABLE_TYPES } from '../../interface/constants';
import { getVisibilityValue } from '../../interface/selectors/utilities';
import { deleteProp, getParentNode } from "./utilities";
import { getUniqueId } from "./getId";

export const isEditableElement = (nodeData) => {
    const attributes = nodeData.attributes || {};
    let isEditable = EDITABLE_ATTRIBUTES.editable in attributes || 'data-editable' in attributes;
    return isEditable;
};

export const userCanEdit = (nodeData, editType) => {
    const attributes = nodeData.attributes || {};
    const hasAttribute = (key) => key in attributes;
    //TODO remove legacy
    const hasDataEditableAttributeOf = (key) => attributes['data-editable'] === key;

    return hasAttribute(EDITABLE_ATTRIBUTES[editType]) || hasDataEditableAttributeOf(editType);
};

export const getEditableName = (nodeData) => nodeData.attributes[EDITABLE_ATTRIBUTES.name] || nodeData.attributes['data-name'];

export const isEditableTextWrapper = (nodeData) => userCanEdit(nodeData, EDITABLE_TYPES.text);

export const getTextId = (parentNode, nodes) => parentNode && parentNode.children && lodash.find(parentNode.children, (childId) => nodes[childId] && nodes[childId].type === 3);

export const getImageId = (parentNode, nodes) => parentNode && parentNode.children && lodash.find(parentNode.children, (childId) => nodes[childId] && nodes[childId].name === 'img');

export const getEditableDataFromNodeData = (nodeData, nodesData) => {
    const {id, attributes} = nodeData;
    let textData = null;
    let nonBackgroundImageData = null;

    if (userCanEdit(nodeData, EDITABLE_TYPES.text)) {
        const textId = getTextId(nodeData, nodesData);
        const textNode = nodesData[textId];

        textData = {
            id: textId,
            value: textNode.value,
        };
    }

    if(attributes[EDITABLE_ATTRIBUTES.nonBackgroundImage] === 'true'){
        const imageId = getImageId(nodeData, nodesData);
        const imageNode = nodesData[imageId];
        nonBackgroundImageData = {id: imageId}
    }

    const toreturn = {
        id,
        idKey: getUniqueId(),
        name: getEditableName(nodeData),
        nonBackgroundImage: !! userCanEdit(nodeData, EDITABLE_TYPES.image) && attributes[EDITABLE_ATTRIBUTES.nonBackgroundImage] === 'true',
        nonBackgroundImageData: userCanEdit(nodeData, EDITABLE_TYPES.image) && nonBackgroundImageData,
        [EDITABLE_TYPES.text]: userCanEdit(nodeData, EDITABLE_TYPES.text) && textData,
        [EDITABLE_TYPES.visibility]: userCanEdit(nodeData, EDITABLE_TYPES.visibility) && getVisibilityValue(attributes[EDITABLE_ATTRIBUTES.visibility]),
    };

    // if(attributes[EDITABLE_ATTRIBUTES.nonBackgroundImage] === 'true'){
    //     console.log('returning', toreturn);
    // }

    return toreturn;
};

export const getEditableDataFromNodesData = (nodesData) => {
    let editableDataList = [];

    Object.values(nodesData).forEach((nodeData) =>
        isEditableElement(nodeData) && editableDataList.push(getEditableDataFromNodeData(nodeData, nodesData)));

    return editableDataList;
};

export const compareAndMergeByName = (nodesA, nodesB) => {

    let comparedNodes = {...nodesB};

    Object.values(nodesA).forEach((comparatorNode) => {
        if (!isEditableElement(comparatorNode)) { return; }

        let excludedId = [];
        let excludedParentId = [];
        const comparatorId = comparatorNode.id;
        const comparatorEditableName = getEditableName(comparatorNode);

        comparedNodes = Object.values(comparedNodes).reduce((nodesMap, node) => {
            const {id} = node;

            if (excludedId.indexOf(id) !== -1 || excludedParentId.indexOf(id) !== -1) {
                return nodesMap;
            }

            if (!isEditableElement(node) ||
                comparatorEditableName !== getEditableName(node) ||
                id === comparatorId) {
                return {
                    ...nodesMap,
                    [id]: node,
                };
            }

            let res = {...nodesMap};


            excludedId.push(id);
            const parentNode = getParentNode(id, comparedNodes);

            //merging text id also
            if (isEditableTextWrapper(comparatorNode)) {
                const comparatorTextId = getTextId(comparatorNode, nodesA);
                const comparedTextId = getTextId(node, comparedNodes);

                excludedId.push(comparedTextId);

                res = {
                    ...res,
                    [comparatorId]: {
                        ...node,
                        id: comparatorId,
                        children: node.children.map((childId) => childId === comparedTextId ? comparatorTextId : childId),
                        parent: parentNode.id,
                    },
                    [comparatorTextId]: {
                        ...comparedNodes[comparedTextId],
                        id: comparatorTextId,
                        parent: comparatorId,
                    },
                };
            } else {
                res = {
                    ...res,
                    [comparatorId]: {
                        ...node,
                        id: comparatorId,
                        parent: parentNode.id,
                    },
                };
            }

            excludedParentId.push(parentNode.id);

            res = {
                ...res,
                [parentNode.id]: {
                    ...parentNode,
                    children: parentNode.children.map((childId) => childId === id ? comparatorId : childId),
                },
            };

            return res;
        }, {});

        // if we have duplicated text id  due to the merge, remove it
        if (excludedId.length) {
            excludedId.forEach((id) => comparedNodes = deleteProp(comparedNodes, id));
        }
    });

    return comparedNodes;
};

export const mergeEditableId = (nodesDataList) => {
    for (let i = 0; i < nodesDataList.length - 1; i++) {
        const comparatorNodes = nodesDataList[i].nodes;

        for (let j = i + 1; j < nodesDataList.length; j++) {
            const currNodes = nodesDataList[j].nodes;
            nodesDataList[j].nodes = compareAndMergeByName(comparatorNodes, currNodes);
        }
    }

    return nodesDataList;
};
