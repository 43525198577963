import { connect } from 'react-redux';

import ToggleButton from './component';

import { templateTypeSelector, allAvailableTemplateTypesSelector } from "../../selectors/template";
import { toggleTemplateTypeClicked } from '../../actions/templateActions';

const mapStateToProps = (state) => ({
    templateType: templateTypeSelector(state),
    templates: allAvailableTemplateTypesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    onToggleClick: (type) => dispatch(toggleTemplateTypeClicked(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToggleButton);
