import { connect } from 'react-redux';

import TemplateRootElement from './component';

import { templateTypeSelector } from "../../../selectors/template";
import { templateRootElementInitialized, templateRootElementUpdate, templateRootElementRemove } from "../../../actions/templateActions";
import { rootDataSelector } from "../../../selectors/template";

const mapStateToProps = (state) => ({
    nodeData: rootDataSelector(state),
    templateType: templateTypeSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    elementInitializedDispatch: (type, ref) => dispatch(templateRootElementInitialized(type, ref)),
    elementUpdateDispatch: (type) => dispatch(templateRootElementUpdate(type)),
    elementRemoveDispatch: (type) => dispatch(templateRootElementRemove(type)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) =>({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    elementInitialized: (ref) => dispatchProps.elementInitializedDispatch(stateProps.templateType, ref),
    elementUpdate: () => dispatchProps.elementUpdateDispatch(stateProps.templateType),
    elementRemove: () => dispatchProps.elementRemoveDispatch(stateProps.templateType),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TemplateRootElement);
