import { takeEvery, takeLatest, put, select, call } from 'redux-saga/es/effects';
import { delay } from 'redux-saga';

import * as business from '../actions/businessActions';
import * as templateBusiness from '../actions/templateActions';
import * as stateModifiers from '../actions/stateModifierActions';
import {
    allAvailableTemplateTypesSelector,
    currentTemplateDataSelector, currentTemplateSelector, currentTemplateTypeSelector,
    templateTypeSelector
} from '../../interface/selectors/template';
import {editableIdSelector, getEditableDataSelector} from "../../interface/selectors/editableElement";
import {getRootData, zconsole} from "../helpers/utilities";
import { getEl, addEl, deleteEl } from '../../interface/helpers/registry';
import {filter, kebabCase, size} from "lodash";
import {EDITABLE_ATTRIBUTES} from "../../interface/constants";
import {themeActiveFontsIdSelector, themeFontsSelector} from "../../interface/selectors/theme";

export default function* () {
    yield takeEvery(business.TOGGLE_TEMPLATE_TYPE_REQUESTED, function* (action) { yield put(stateModifiers.toggleTemplateType(action.payload.templateName)); });

    yield takeLatest(templateBusiness.TEMPLATE_ROOT_ELEMENT_INITIALIZED_REQUESTED, function* (action) {
        const {type, ref} = action.payload;
        const rootData = getRootData(ref);
        yield call(addEl, type, ref);
        yield put(stateModifiers.updateRootData(rootData, type));

        let i = 0;
        while (i < 4) {
            yield delay(300);
            yield put(templateBusiness.templateRootElementUpdateRequested(type));
            i++;
        }
    });
    yield takeEvery(templateBusiness.TEMPLATE_ROOT_ELEMENT_UPDATE_REQUESTED, function* (action) {
        const {type} = action.payload;
        const rootRef = yield call(getEl, type);

        if (!rootRef) {
            return;
        }

        //TODO update key
    });
    yield takeEvery(templateBusiness.TEMPLATE_ROOT_ELEMENT_REMOVE_REQUESTED, function* (action) {
        const {id, type} = action.payload;
        yield call(deleteEl, type + id);
    });
    yield takeEvery(templateBusiness.TEMPLATE_EDITABLE_ELEMENT_INITIALIZED_REQUESTED, function* (action) {
        const {id, type, ref, isNonBackgroundImage} = action.payload;
        yield call(addEl, type + id, ref);

        const editableData = yield select(state => getEditableDataSelector(state, {id, ref, isNonBackgroundImage}));
        yield put(stateModifiers.updateEditableData(id, editableData, type));
    });

    yield takeEvery(templateBusiness.TEMPLATE_EDITABLE_ELEMENT_REMOVE_REQUESTED, function* (action) {
        const {id, type} = action.payload;
        yield call(deleteEl, type + id);
    });

    yield takeEvery(business.UPDATE_EDITABLE_TEXT_REQUESTED, function* (action) {
        const {id, value} = action.payload;
        const templateTypes = yield select(allAvailableTemplateTypesSelector);

        for (const type of templateTypes) {
            yield put(stateModifiers.updateTemplateEditableText(id, value, type));
        }
    });

    /**
     * This is changed in the UI. The user can drag a slider to change the vertical offset of an item.
     * It also gets fired by the template code when switching fonts, so that the text will default to the new font's global offset setting
     */
    yield takeEvery(business.UPDATE_TEXT_VALIGN_OVERRIDE_REQUESTED, function* (action) {
        const {id, value} = action.payload;

        //* Apply the alignment override only to this template type instead of all
        const templateType = yield select(templateTypeSelector);
        yield put(stateModifiers.updateTextValignOverride(id, value, templateType));

        //* Apply the alignment override to all template types
        // const templateTypes = yield select(allAvailableTemplateTypesSelector);
        //
        // for (const type of templateTypes) {
        //     yield put(stateModifiers.updateTextValignOverride(id, value, type));
        // }
    });

    /**
     * If a user chooses to reset the vertical alignment override, we need to check the font settings for the corresponding
     * font and reset it to whatever that font's default override is, rather than just resetting it to 0.
     */
    yield takeEvery(business.RESET_TEXT_VALIGN_OVERRIDE_REQUESTED, function* (action) {
        const {id} = action.payload;
        let valignToSet = 0;
        const currentTemplateData = yield select(currentTemplateDataSelector);
        if(currentTemplateData && size(currentTemplateData.nodeData) && typeof currentTemplateData.nodeData[id] !== 'undefined' && size(currentTemplateData.nodeData[id])){
            const thisItem = currentTemplateData.nodeData[id];
            if(typeof thisItem.attributes !== 'undefined' && typeof thisItem.attributes[EDITABLE_ATTRIBUTES.font] !== 'undefined' && thisItem.attributes[EDITABLE_ATTRIBUTES.font]){
                const thisFont = thisItem.attributes[EDITABLE_ATTRIBUTES.font];
                const activeFonts = { ...yield select(themeActiveFontsIdSelector) };
                const thisActiveFontId = activeFonts[thisFont]; // If it's at template font, will be null/undefined
                if(thisActiveFontId){
                    // console.log('found active font')
                    const allFonts = {...yield select(themeFontsSelector)}
                    const thisFont = allFonts[thisActiveFontId];
                    if(thisFont && typeof thisFont.global_offset !== 'undefined' && thisFont.global_offset){
                        valignToSet = thisFont.global_offset;
                    }
                }
            }
        }

        //* Apply the alignment override only to this template type instead of all
        const templateType = yield select(templateTypeSelector);
        yield put(stateModifiers.updateTextValignOverride(id, valignToSet, templateType));

        //* Apply the alignment override to all template types
        // const templateTypes = yield select(allAvailableTemplateTypesSelector);
        //
        // for (const type of templateTypes) {
        //     yield put(stateModifiers.resetTextValignOverride(id, value, type));
        // }
    });

    yield takeEvery(business.UPDATE_TEXT_FUNDAMENTAL_FONT_SCALE_REQUESTED, function* (action) {
        const {id, value} = action.payload;

        //* Apply the alignment override to all template types
        const templateTypes = yield select(allAvailableTemplateTypesSelector);
        for (const type of templateTypes) {
            yield put(stateModifiers.updateTextFundamentalFontScale(id, value, type));
        }
    });

    yield takeEvery(business.UPDATE_TEXT_NO_OVERFLOW_FONT_SCALE_REQUESTED, function* (action) {
        const {id, value} = action.payload;

        //* Apply the alignment override only to this template type instead of all
        const templateType = yield select(templateTypeSelector);
        yield put(stateModifiers.updateTextNoOverflowFontScale(id, value, templateType));
    });

    yield takeEvery(business.UPDATE_TEXT_METRICS_REQUESTED, function* (action) {
        const {id, value} = action.payload;

        //* Apply the alignment override to all template types
        const templateTypes = yield select(allAvailableTemplateTypesSelector);
        for (const type of templateTypes) {
            yield put(stateModifiers.updateTextMetrics(id, value, type));
        }
    });

    yield takeEvery(business.UPDATE_EDITABLE_TEXT_DATA_REQUESTED, function* (action) {
        const {id, metrics, adjustments} = action.payload;
        const { fundamentalScale, overflowScale } = adjustments;

        //* Apply the override to all template types
        const templateTypes = yield select(allAvailableTemplateTypesSelector);
        for (const type of templateTypes) {
            yield put(stateModifiers.updateTextMetrics(id, metrics, type));
            // yield put(stateModifiers.updateTextFundamentalFontScale(id, fundamentalScale, type));
            yield put(stateModifiers.updateTextAdjustmentsWithoutOverflowFontScale(id, adjustments, type));
        }

        //* Apply the overflowScale override only to this template type instead of all
        const templateType = yield select(templateTypeSelector);
        yield put(stateModifiers.updateTextNoOverflowFontScale(id, overflowScale, templateType));
    });

    yield takeEvery(business.UPDATE_EDITABLE_IMAGE_REQUESTED, function* (action) {
        const {id, src, position, size, nonBackgroundImage} = action.payload;
        const templateTypes = yield select(allAvailableTemplateTypesSelector);
        const currentTemplateType = yield select(currentTemplateTypeSelector);
        const currentTemplate = yield select(currentTemplateSelector);
        const thisEditableItem = currentTemplate && currentTemplate.editableData && currentTemplate.editableData[id] || false;
        const thisEditableItemImageURL = thisEditableItem && thisEditableItem.image && thisEditableItem.image.src || false;

        // Update all template sizes to the new image URL (if it's different than the current image url)
        if(src !== thisEditableItemImageURL || !thisEditableItemImageURL ){
            for (const type of templateTypes) {
                yield put(stateModifiers.updateTemplateEditableImage(id, src, type, nonBackgroundImage));
            }
        }

        // Update only the active size to the new crop
        yield put(stateModifiers.updateTemplateEditableImageCrop(id, position, size, currentTemplateType));
    });
    yield takeEvery(business.UPDATE_EDITABLE_VISIBILITY_REQUESTED, function* (action) {
        const {id, isVisible} = action.payload;
        const templateTypes = yield select(allAvailableTemplateTypesSelector);

        for (const type of templateTypes) {
            yield put(stateModifiers.updateEditableVisibility(id, isVisible, type));
        }
    });
}
