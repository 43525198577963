import React from 'react';
import RangeStylizedControl from "../../../image_editor/js/interface/widgets/RangeStylizedControl/component";
import PropTypes from "prop-types";
import FontDemoScreen from "./FontDemoScreen";

export default class UserFontConfigurator extends React.Component {
    static propTypes = {
        globalOffsetField: PropTypes.object.isRequired,
        globalScaleField: PropTypes.object.isRequired,
        fontName: PropTypes.string.isRequired,
        fontData: PropTypes.string.isRequired,
    }

    state = {
        globalOffsetValue: 0,
        globalScaleValue: 0,
    }

    componentDidMount() {
        const { globalOffsetField, globalScaleField } = this.props;
        this.setState({
            globalOffsetValue: globalOffsetField && typeof globalOffsetField.value !== 'undefined' && globalOffsetField.value ? parseFloat(globalOffsetField.value) : 0,
            globalScaleValue: globalScaleField && typeof globalScaleField.value !== 'undefined' && globalScaleField.value ? parseFloat(globalScaleField.value) : 0
        });
    }

    _verticalAlignChange = event => {
        this.props.globalOffsetField.value = event.target.value;
        this.setState({ globalOffsetValue: parseFloat(event.target.value) });
    }

    _verticalAlignReset = () => {
        this.props.globalOffsetField.value = 0;
        this.setState({ globalOffsetValue: 0 });
    }

    _scaleChange = event => {
        this.props.globalScaleField.value = event.target.value;
        this.setState({ globalScaleValue: parseFloat(event.target.value) });
    }

    _scaleReset = () => {
        this.props.globalScaleField.value = 0;
        this.setState({ globalScaleValue: 0 });
    }

    _inlineStyles = () => {
        const { fontName, fontData } = this.props;
        return (
            <style>
                {`@font-face {
                  font-family: "${fontName}";
                  src: url(data:font/truetype;charset=utf-8;base64,${fontData});
                }`}
            </style>
        );
    }

    render() {
        const { globalOffsetValue, globalScaleValue } = this.state;
        return(
            <div>
                {this._inlineStyles()}
                <div className="row mb-4 font-config-controls-row">
                    <div className="col-sm-6">
                        <RangeStylizedControl
                            onChange={this._verticalAlignChange}
                            onReset={this._verticalAlignReset}
                            value={parseFloat(globalOffsetValue)}
                            min={-1}
                            max={1}
                            step={0.001}
                            label="Adjust text vertical alignment"
                            rangeLabels={['Down', 'Center', 'Up']}
                        />
                    </div>
                    <div className="col-sm-6">
                        <RangeStylizedControl
                            onChange={this._scaleChange}
                            onReset={this._scaleReset}
                            value={parseFloat(globalScaleValue)}
                            min={-1}
                            max={1}
                            step={0.001}
                            label="Adjust text size"
                            rangeLabels={['Smaller', 'Default', 'Larger']}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <FontDemoScreen globalOffsetValue={parseFloat(globalOffsetValue)} globalScaleValue={parseFloat(globalScaleValue)} fontName={this.props.fontName} />
                    </div>
                </div>
            </div>
        );
    }
}