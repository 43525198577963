export const REMOVE_FLASH_ELEMENT = 'INTERFACE:REMOVE_FLASH_ELEMENT';
export const removeFLashElement = (id) => ({
    type: REMOVE_FLASH_ELEMENT,
    payload: { id },
});

export const SET_FLASH = 'UI:STATE_MODIFIER:SET_FLASH';
export const setFlash = (flashData) => ({
    type: SET_FLASH,
    payload: { flashData },
});

export const CLEAR_FLASH = 'UI:STATE_MODIFIER:CLEAR_FLASH';
export const clearFlash = (id) => ({
    type: CLEAR_FLASH,
    payload: { id },
});


