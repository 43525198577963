export const USER_UPLOADS_REQUESTED = 'SERVICE:MASON:USER_UPLOADS:REQUESTED';
export const userUploadsRequested = ({user_image_library_folder, page, per_page, search_term}) => {
    return {
        type: USER_UPLOADS_REQUESTED,
        payload: {
            user_image_library_folder,
            page,
            per_page,
            search_term
        }
    };
};

export const USER_UPLOADS_REQUEST_SUCCEEDED = 'SERVICE:MASON:USER_UPLOADS:REQUEST_SUCCEEDED';
export const userUploadsRequestSucceeded = (response) => {
    return(
        {
            type: USER_UPLOADS_REQUEST_SUCCEEDED,
            payload: response
        }
    );
};

export const USER_UPLOADS_REQUEST_FAILED = 'SERVICE:MASON:USER_UPLOADS:REQUEST_FAILED';
export const userUploadsRequestFailed = (errors) => {
    return(
        {
            type: USER_UPLOADS_REQUEST_FAILED,
            payload: errors
        }
    );
};

export const CREATE_USER_UPLOAD_REQUESTED = 'SERVICE:MASON:USER_UPLOAD:CREATE_REQUESTED';
export const createUserUploadRequested = ({name, file, user_image_library_folder}) => {
    return(
        {
            type: CREATE_USER_UPLOAD_REQUESTED,
            payload: {name, file, user_image_library_folder}
        }
    );
};

export const CREATE_USER_UPLOAD_REQUEST_SUCCEEDED = 'SERVICE:MASON:USER_UPLOAD:CREATE_REQUEST_SUCCEEDED';
export const createUserUploadRequestSucceeded = (response) => {
    return(
        {
            type: CREATE_USER_UPLOAD_REQUEST_SUCCEEDED,
            payload: response
        }
    );
};

export const CREATE_USER_UPLOAD_REQUEST_FAILED = 'SERVICE:MASON:USER_UPLOAD:CREATE_REQUEST_FAILED';
export const createUserUploadRequestFailed = (response) => {
    return(
        {
            type: CREATE_USER_UPLOAD_REQUEST_FAILED,
            payload: response
        }
    );
};

export const DELETE_USER_UPLOAD_REQUESTED = 'SERVICE:MASON:USER_UPLOAD:DELETE_REQUESTED';
export const deleteUserUploadRequested = (id) => {
    return(
        {
            type: DELETE_USER_UPLOAD_REQUESTED,
            payload: id
        }
    );
};

export const DELETE_USER_UPLOAD_REQUEST_SUCCEEDED = 'SERVICE:MASON:USER_UPLOAD:DELETE_REQUEST_SUCCEEDED';
export const deleteUserUploadRequestSucceeded = (id) => {
    return(
        {
            type: DELETE_USER_UPLOAD_REQUEST_SUCCEEDED,
            payload: id
        }
    );
};

export const DELETE_USER_UPLOAD_REQUEST_FAILED = 'SERVICE:MASON:USER_UPLOAD:DELETE_REQUEST_FAILED';
export const deleteUserUploadRequestFailed = (response) => {
    return(
        {
            type: CREATE_USER_UPLOAD_REQUEST_FAILED,
            payload: response
        }
    );
};