import React from 'react';
import PropTypes from 'prop-types';

import TemplateElement from '../TemplateElement/container';
import TemplateEditableElement from '../TemplateEditableElement/container';
import TemplateText from '../TemplateText/component';

import {isImageNode, isPresent, isTextNode} from "../../../../business/helpers/utilities";
import { isEditableElement } from "../../../../business/helpers/elementsData";
import {isATextNode} from "../../../../business/helpers/normalizeFonts";

const createText = (value) => <TemplateText text={value}/>;
const createWrapperComponent = ({Component, id, nodeData, parentRef}) => <Component id={id} nodeData={nodeData} parentRef={parentRef}/>;

createWrapperComponent.propTypes = {
    Component: PropTypes.element.isRequired,
    id: PropTypes.string.isRequired,
    nodeData: PropTypes.object,
};

const TemplateConstructorComponent = (props) => {
    const {nodeData, editableText, parentRef} = props;

    if (!nodeData) {
        return null;
    }

    const {type, value, id, attributes} = nodeData;

    if (isEditableElement(nodeData)) {
        return createWrapperComponent({
            Component: TemplateEditableElement,
            id,
            nodeData,
            parentRef
        });
    }

    if (isTextNode(type)) {
        if (isPresent(editableText) && editableText.length === 0) {
            if (editableText.length === 0) {
                // const text = 'INVISIBLE_FIELD';
                const text = '';

                return (
                    <span style={{visibility: 'hidden'}}>
                        {createText(text)}
                    </span>
                );
            }
        }

        return createText(isPresent(editableText) ? editableText : value);
    }

    //* Not currently needed
    // if (isImageNode(nodeData)){
    //     // console.log('you are looking at an image node.')
    // }

    const content = (createWrapperComponent({
        Component: TemplateElement,
        nodeData,
        id,
        parentRef
    }));

    return content;
};

TemplateConstructorComponent.propTypes = {
    nodeData: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        attributes: PropTypes.object,
        children: PropTypes.arrayOf(PropTypes.string),
    }),
    editableText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ])
};

export default TemplateConstructorComponent;
