import { all } from "redux-saga/effects";

import template from './templateListener';
import download from './downloadListener';
import upload from './uploadListener';
import autoSave from './autoSaveListener';
import theme from './themeListener';
import imageLibrary from './imageLibraryListener';
import entities from './entitiesListener';

export default function* () {
    yield all([
        template(),
        download(),
        upload(),
        autoSave(),
        theme(),
        imageLibrary(),
        entities(),
    ]);
}
