import React from 'react';
import PropTypes from 'prop-types';
import titleCase from 'title-case';

const fileLabelPlaceholder = 'Click here to upload image file...';

export default class UploadForm extends React.Component {

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        pending: PropTypes.bool.isRequired,
    };

    state = {
        name: '',
        fileLabel: fileLabelPlaceholder,
        errors: ''
    };

    nameInput = React.createRef();

    fileInput = React.createRef();

    handleFileChange = (e) => {
        e.preventDefault();

        const input_element = this.fileInput.current;
        const fileNameExists = !!(input_element.files && input_element.files[0] && input_element.files[0].name);
        const fileName = fileNameExists ? input_element.files[0].name : input_element.value;
        const name = fileName || '';

        this.setFileLabel(name);
        this.setName(titleCase(this.removeFileExtension(name)));
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const { user_image_library_folder } = this.props;
        const file = this.fileInput.current.files && this.fileInput.current.files[0];
        const name = this.nameInput.current.value;
        const errors = [];

        if (!file || !name) {
            if(!file){
                errors.push('Image file is required.');
            }
            if(!name){
                errors.push('Image name is required.');
            }

            this.setState({errors});

            return;
        }

        this.props.onSubmit({ name, file, user_image_library_folder });
    };

    handleNameChange = (e) => {
        e.preventDefault();
        this.setName(e.target.value);
    };

    setName(name) {
        this.setState({name});
    }

    setFileLabel(fileLabel) {
        this.setState({fileLabel});
    }

    removeFileExtension(string) {
        return string.substr(0, string.lastIndexOf('.')) || string;
    }

    errorDisplay(){
        const {errors} = this.state;
        if(!errors || !errors.length){
            return;
        }
        return (
        <div className="errors">
            {errors.map((error, index) => (
                <div key={index} className="alert alert-danger" role="alert">{error}</div>
            ))}
        </div>
        )

    }

    render() {
        const {pending} = this.props;
        const {name, fileLabel} = this.state;

        return (
            <div>
                {this.errorDisplay()}
                <h6 className="sidebar-header upload-header">Upload Image</h6>

                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label className="custom-file d-block custom-file-image">
                            <input id="image" ref={this.fileInput} type="file" name="image_upload"
                                   className="custom-file-input" onChange={this.handleFileChange}/>
                            <span id="image-upload" className="custom-file-control custom-file-name"
                                  data-content={fileLabel}/>
                        </label>
                    </div>

                    <div className="form-group">
                        <input ref={this.nameInput} type="text" className="form-control" id="name" placeholder="Name"
                               value={name} onChange={this.handleNameChange}/>
                    </div>

                    <span className="mr-2">
                        <button type="submit" className="btn btn-outline-primary" disabled={pending}>Upload</button>
                        <span style={{display: pending ? 'inline-block' : 'none' }}><i className="fa fa-refresh fa-spin fa-fw ml-2" aria-hidden="true" /></span>
                    </span>
                </form>
            </div>
        );
    }
}
