import React from 'react';
import PropTypes from 'prop-types';

import ImageCanvas from '../ImageCanvas/component';
import ToggleButton from '../ToggleButton/container';
import DownloadButton from '../DownloadButton/container';
import Sidebar from '../Sidebar/container';
import StickyWrapper from '../StickyWrapper/component';
import TemplateState from '../TemplateState/container';

export default class ImageEditor extends React.Component {

    static propTypes = {
        hasTemplateDom: PropTypes.bool.isRequired,
        templateName: PropTypes.string,
        editorInitialized: PropTypes.func.isRequired,
    };

    state = {
        activeTab: 'content',
    };

    _setActiveTab = (activeTab) => () => {
        this.setState({activeTab});
    }

    componentDidMount() {
        this.props.editorInitialized();
    }

    render() {
        const {hasTemplateDom, templateName} = this.props;

        if (!hasTemplateDom) {
            return (
                <div className="alert alert-info" role="alert">
                    <strong>Oops!</strong> Looks like your template is still in process, please check back later.
                </div>
            );
        }

        return (
            <div className="row">
                <div className="editor-sidebar px-3">
                    <Sidebar setActiveTab={this._setActiveTab} activeTab={this.state.activeTab} />
                </div>
                <div className="editor-main p-0 d-flex flex-column flex-grow">
                    <div className="editor-header px-3 py-2">
                        <div className="row d-flex align-items-center">
                            <div className="col-md">
                                <h3 className="text-left mb-0">{templateName}</h3>
                            </div>
                            <div className="col-md">
                                <ul className="nav justify-content-sm-end flex-wrap flex-md-nowrap">
                                    <li className="nav-item d-flex align-items-center">
                                        <TemplateState/>
                                    </li>
                                    <li className="nav-item">
                                        <ToggleButton/>
                                    </li>
                                    <li className="nav-item">
                                        <DownloadButton/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="editor-inner p-4">
                        <div className="card text-center">
                            <ImageCanvas/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
