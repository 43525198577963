// import 'babel-polyfill';
import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import { render } from 'react-dom';
import UserColorConfigurator from "./widgets/UserColorConfigurator";

const renderPack = () => {
    const dataNode = document.getElementById('component-user-color-configurator');
    const containerNode = document.getElementById('user-color-configurator-container');
    const primaryColorField = document.getElementById('template_theme_brand_color_1');
    // const primaryColorField = document.querySelector('input[name="template_theme[brand_color_1]"]');
    const primaryColorContrastField = document.getElementById('template_theme_brand_color_1_contrast');
    const secondaryColorField = document.getElementById('template_theme_brand_color_2');
    // const secondaryColorField = document.querySelector('input[name="template_theme[brand_color_2]"]');
    const secondaryColorContrastField = document.getElementById('template_theme_brand_color_2_contrast');

    if (!dataNode || !containerNode || !primaryColorField || !primaryColorContrastField || !secondaryColorField || !secondaryColorContrastField) {
        return;
    }

    const initializeData = JSON.parse(dataNode.getAttribute('data'));

    if(
        initializeData
        // typeof initializeData.brand_color_1 !== 'undefined' &&
        // typeof initializeData.brand_color_1_contrast !== 'undefined' &&
        // typeof initializeData.brand_color_2 !== 'undefined' &&
        // typeof initializeData.brand_color_2_contrast !== 'undefined'
    ){
        const { brand_color_1, brand_color_1_contrast, brand_color_2, brand_color_2_contrast } = initializeData;
        render(
            <UserColorConfigurator
                primaryColorField={primaryColorField}
                primaryColorContrastField={primaryColorContrastField}
                secondaryColorField={secondaryColorField}
                secondaryColorContrastField={secondaryColorContrastField}
                primaryColorValue={brand_color_1}
                primaryContrastValue={brand_color_1_contrast}
                secondaryColorValue={brand_color_2}
                secondaryContrastValue={brand_color_2_contrast}
            />
            ,containerNode);
    }
};

document.addEventListener('turbolinks:load', renderPack);