import {takeEvery, put} from 'redux-saga/es/effects';

import * as ui from '../actions/interfaceActions';
import * as business from '../../business/actions/businessActions';
import { SIDEBAR_CONTENT_CODES } from "../constants";
import * as autosave from "../actions/autoSaveActions";

export default function* () {
    yield takeEvery(ui.GET_LIBRARY_IMAGES,                 function*(action){
        const { code, userImageLibraryFolder, page, per_page, search_term } = action.payload;
        if(code === 'user') {
            yield put(business.userUploadsRequested({
                user_image_library_folder: userImageLibraryFolder,
                page,
                per_page,
                search_term
            }));
        }
        else {
            yield put(business.imageLibraryRequested(code, page, per_page, search_term));
        }
    });
    yield takeEvery(ui.LIBRARY_IMAGE_CLICKED,           function*(action){
        const {id, libraryImageId} = action.payload;
        yield put(ui.autoCropRequested(id, libraryImageId));
        yield put(ui.resetSidebarRequested());
        yield put(autosave.templateAutosave());
    });
    yield takeEvery(ui.REQUEST_CURRENT_IMAGE_LIBRARY,     function*(action){
        yield put(ui.resetSidebarRequested()); // 1/23 Whenever we click to a different image library, we need to reset the nav stack
        const {id, library, userImageLibraryFolder} = action.payload;
        yield put(ui.pageForwardRequested(SIDEBAR_CONTENT_CODES.imageFiledImageLibrary, {
            libraryCode: library,
            id,
            userImageLibraryFolder
        }));
    });
}
