import {takeEvery, put} from 'redux-saga/es/effects';

import * as ui from '../actions/interfaceActions';
import * as stateModifier from '../actions/stateModifierActions';
import * as business from '../../business/actions/businessActions';
import {FORM_NAMES} from "../constants";

export default function* () {
  yield takeEvery(ui.THEME_COLOR_UPDATED, function* (action) {
    yield put(business.updateThemeColorRequested(action.payload.themeColorName, action.payload.value));
  });

  yield takeEvery(ui.THEME_FONT_UPDATED, function* (action) {
    yield put(business.updateThemeFontRequested(action.payload.themeFontName, action.payload.value));
  });

  yield takeEvery(ui.THEME_FONT_SCALE_UPDATED, function* (action) {
    yield put(business.updateThemeFontSizeRequested(action.payload.themeFontSizeName, action.payload.value));
  });

  yield takeEvery(ui.THEME_FONT_SCALE_RESET, function* (action) {
    yield put(business.resetThemeFontSizeRequested(action.payload.themeFontSizeName));
  });

  yield takeEvery(ui.THEME_COLOR_SWITCH_REQUESTED, function* () {
    yield put(business.switchThemeColorsRequested());
  });


  yield takeEvery(ui.THEME_RESET_REQUESTED, function* () {
    yield put(business.initializeThemeColorsRequested());
    yield put(business.initializeThemeFontsRequested());
  });

  yield takeEvery(ui.THEME_SAVE_REQUESTED, function* () {
    yield put(stateModifier.updateFormState(FORM_NAMES.theme, true));
    yield put(business.saveTemplateThemeRequested());
  });
  yield takeEvery(ui.THEME_SAVE_SUCCEEDED, function* () {
    yield put(stateModifier.updateFormState(FORM_NAMES.theme, false));
  });
  yield takeEvery(ui.THEME_SAVE_FAILED, function* (action) {
    yield put(stateModifier.updateFormState(FORM_NAMES.theme, false));
    yield put(ui.errorsReceived(action.payload));
  });
}
