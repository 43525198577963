import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

import { FLASH } from "../../constants";

import './styles.scss';

const FlashElement = (props) => {
    const {flashType, message, deleteElement} = props;
    const wrapperClassNames = classname(`flash-element alert`, {
        'alert-info': flashType === FLASH.types.notice,
        'alert-danger': flashType === FLASH.types.alert,
    });

    return (
        <div className={wrapperClassNames} role="alert">
            <span>{message}</span>
            <span onClick={deleteElement}>
                <i
                    className="fa fa-times flash-element-icon"
                    aria-hidden="true"
                />
            </span>
        </div>
    );
};

FlashElement.propTypes = {
    flashType: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    deleteElement: PropTypes.func.isRequired,
};

export default FlashElement;
