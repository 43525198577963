import { takeEvery, put } from 'redux-saga/es/effects';

import * as business from '../actions/businessActions';
import * as stateModifiers from '../actions/stateModifierActions';
import { templateAutosaveRequested } from "../actions/autoSaveActions";

export default function* () {
    yield takeEvery(business.CREATE_DOWNLOAD_REQUESTED, function* () {
        yield put(stateModifiers.startDownload());
    });
    yield takeEvery(business.DOWNLOAD_COMPLETED, function* () {
        yield put(stateModifiers.stopDownload());
        yield put(templateAutosaveRequested(true));
    });
}
