import {
    UPDATE_THEME_COLOR,
    UPDATE_THEME_FONT,
    SWITCH_THEME_COLORS, UPDATE_THEME_FONT_SIZE,
} from "../actions/stateModifierActions";

export const INITIAL_STATE = {
    colors: {},
    fonts: {},
    fontSizes: {}
};

export default (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case UPDATE_THEME_COLOR:
            return {
                ...state,
                colors: {
                    ...state.colors,
                    [payload.themeColorName]: payload.value ? payload.value.hex : state.colors[payload.themeColorName],
                },
            };
        case UPDATE_THEME_FONT:
            return {
                ...state,
                fonts: {
                    ...state.fonts,
                    [payload.themeFontName]: payload.value,
                },
            };
        case SWITCH_THEME_COLORS:
            return {
            ...state,
            colors: {
                ...state.colors,
                ...payload.newColors
            },
        };
        case UPDATE_THEME_FONT_SIZE:
            return {
                ...state,
                fontSizes: {
                    ...state.fontSizes,
                    [payload.themeFontSizeName]: payload.value,
                },
            };

        default:
            return state;
    }
};
