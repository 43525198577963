import {takeEvery, put, select} from 'redux-saga/es/effects';
import { size, filter, kebabCase } from 'lodash';
import * as mason_template_theme from '../../services/mason/resource/templateTheme/actions';
import * as business from '../actions/businessActions';
import * as ui from '../../interface/actions/interfaceActions';
import * as stateModifiers from '../actions/stateModifierActions';
import { themeActiveFontsIdSelector, themeActiveColorsSelector, themeFontsSelector } from "../../interface/selectors/theme";
import { currentTemplateDataSelector, staticInfoSelector } from "../../interface/selectors/template";
import { EDITABLE_ATTRIBUTES } from "../../interface/constants";

export default function* () {
  yield takeEvery(business.INITIALIZE_THEME_COLORS_REQUESTED, function* () {
    const defaultTheme = yield select((state) => state.business.defaultTheme);

    for (const [themeColorName, hexValue] of Object.entries(defaultTheme.colors)) {
      yield put(stateModifiers.updateThemeColor(themeColorName, {hex: hexValue}));
    }
  });
  yield takeEvery(business.INITIALIZE_THEME_FONTS_REQUESTED, function* () {
    const defaultTheme = yield select((state) => state.business.defaultTheme);

    for (const [themeFontName, value] of Object.entries(defaultTheme.fonts)) {
      yield put(stateModifiers.updateThemeFont(themeFontName, value));
    }
  });

  yield takeEvery(business.UPDATE_THEME_COLOR_REQUESTED, function* (action) {
    yield put(stateModifiers.updateThemeColor(action.payload.themeColorName, action.payload.value));
  });

  /**
   * When the font is changed, update the font itself, and also update the global scale default for this slot to be the
   * font's globally-configured (user font config page) setting.
   */
  yield takeEvery(business.UPDATE_THEME_FONT_REQUESTED, function* (action) {
    const fontId = action.payload.value;
    yield put(stateModifiers.updateThemeFont(action.payload.themeFontName, fontId));

    const themeFonts = yield select(themeFontsSelector);
    const thisFont = themeFonts[fontId];
    const themeFontSizeName = action.payload.themeFontName.substr(0, action.payload.themeFontName.indexOf('Font'));
    if(thisFont && typeof thisFont.global_scale !== 'undefined'){
      const globalScale = parseFloat(thisFont.global_scale);
      yield put(stateModifiers.updateThemeFontSize(themeFontSizeName, globalScale));
    }
    else {
      yield put(stateModifiers.updateThemeFontSize(themeFontSizeName, 0));
    }

  });
  yield takeEvery(business.UPDATE_THEME_FONT_SIZE_REQUESTED, function* (action) {
    // const name = action.payload.themeFontSizeName;
    // const currentFontSize = yield select((state) => themeActiveFontSizeSelector(state, {name}));
    // const newFontSize = action.payload.value;
    yield put(stateModifiers.updateThemeFontSize(action.payload.themeFontSizeName, action.payload.value));
  });

  /**
   * Find an element in the dom that uses this font size and get its font family so that we can find that font family's defaults
   */
  yield takeEvery(business.RESET_THEME_FONT_SIZE_REQUESTED, function* (action) {
    const { themeFontSizeName } = action.payload;
    const currentTemplateData = yield select(currentTemplateDataSelector);
    let fontScaleToSet = 0;
    if(currentTemplateData && size(currentTemplateData.nodeData)){
      const filteredResults = filter(currentTemplateData.nodeData, item => {
        return item.attributes !== 'undefined' &&
          typeof item.attributes[EDITABLE_ATTRIBUTES.fontSize] !== 'undefined' &&
          item.attributes[EDITABLE_ATTRIBUTES.fontSize] === kebabCase(themeFontSizeName) &&
          typeof item.attributes[EDITABLE_ATTRIBUTES.font] !== 'undefined' &&
          item.attributes[EDITABLE_ATTRIBUTES.font];
      })
      if(size(filteredResults)){
        if(typeof filteredResults[0].attributes !== 'undefined' && typeof filteredResults[0].attributes[EDITABLE_ATTRIBUTES.font] !== 'undefined' && filteredResults[0].attributes[EDITABLE_ATTRIBUTES.font]){
          const thisFont = filteredResults[0].attributes[EDITABLE_ATTRIBUTES.font];
          const activeFonts = { ...yield select(themeActiveFontsIdSelector) };
          const thisActiveFontId = activeFonts[thisFont]; // If it's at template font, will be null/undefined
          if(thisActiveFontId){
            const allFonts = {...yield select(themeFontsSelector)}
            const thisFont = allFonts[thisActiveFontId];
            if(thisFont && typeof thisFont.global_scale !== 'undefined' && thisFont.global_scale){
              fontScaleToSet = thisFont.global_scale;
            }
          }
        }
      }
    }
    yield put(stateModifiers.updateThemeFontSize(themeFontSizeName, fontScaleToSet));
  });

  yield takeEvery(business.SWITCH_THEME_COLORS_REQUESTED, function* (action) {
    const themeColors = yield select(themeActiveColorsSelector);
    const newColors = {
      brandColor1: themeColors.brandColor2,
      brandColor1Contrast: themeColors.brandColor2Contrast,
      brandColor2: themeColors.brandColor1,
      brandColor2Contrast: themeColors.brandColor1Contrast
    };
    yield put(stateModifiers.switchThemeColors(newColors));
  });

  yield takeEvery(business.SAVE_TEMPLATE_THEME_REQUESTED, function* () {
    const templateId = yield select((state) => state.business.templateId);
    const themeFonts = yield select(themeActiveFontsIdSelector);
    const themeColors = yield select(themeActiveColorsSelector);
    const staticInfo = yield select(staticInfoSelector);
    yield put(mason_template_theme.updateTemplateThemeRequested(templateId, {...themeFonts, ...themeColors}, staticInfo));
  });
  yield takeEvery(business.SAVE_TEMPLATE_THEME_SUCCEEDED, function* () {
    yield put(ui.themeSaveSucceeded());
  });
  yield takeEvery(business.SAVE_TEMPLATE_THEME_FAILED, function* (action) {
    yield put(ui.themeSaveFailed(action.payload));
  });
}
