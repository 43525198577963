import { connect } from 'react-redux';

import ImageEditor from './component';

import { editorInitialized } from '../../actions/interfaceActions';
import { templateNameSelector, hasDomDataSelector } from "../../selectors/template";

const mapStateToProps = (state) => {
    // console.log('state', state);
    return ({
        templateName: templateNameSelector(state),
        hasTemplateDom: hasDomDataSelector(state),
    })
};

const mapDispatchToProps = dispatch => ({
    editorInitialized: () => dispatch(editorInitialized()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageEditor);
