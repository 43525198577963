import { takeEvery, put } from 'redux-saga/effects';

import * as endpoints from './endpoints';
import * as mason_template_theme from './actions';
import * as business from '../../../../business/actions/businessActions';
import { size } from 'lodash';

const extractErrors = (response) => {
    console.log('extract errors response', response);
    return size(response) && size(response.data) && size(response.data.errors) ? response.data.errors : [];
};

const handleUpdateTemplateThemeRequested = function*(action) {
    try {
        // console.log('handleUpdateTemplateThemeRequested');
        const response = yield endpoints.updateTemplateTheme(action.payload);
        yield put(business.updateDefaultThemeRequested());
        yield put(business.createTemplateSnapshotRequested());
        yield put(mason_template_theme.updateTemplateThemeRequestSucceeded(response));
    } catch(e) {
        yield put(mason_template_theme.updateTemplateThemeRequestFailed(e.response));
    }

};

export default function*(action) {
    yield takeEvery(mason_template_theme.UPDATE_TEMPLATE_THEME_REQUESTED,             handleUpdateTemplateThemeRequested);
    yield takeEvery(mason_template_theme.UPDATE_TEMPLATE_THEME_REQUEST_SUCCEEDED,     function*(action){ yield put(business.saveTemplateThemeSucceeded()); });
    yield takeEvery(mason_template_theme.UPDATE_TEMPLATE_THEME_REQUEST_FAILED,        function*(action){ yield put(business.saveTemplateThemeFailed(extractErrors(action.payload))); });
}