import { PAGE_FORWARD, PAGE_BACK, FRONT_PAGE } from "../actions/stateModifierActions";
import { SIDEBAR_CONTENT_CODES } from "../constants";

const templateForm = {
    contentName: SIDEBAR_CONTENT_CODES.templateForm,
    contentProps: {},
};

export default (state = [templateForm], action)=>{
    const {type, payload} = action;

    switch (type) {
        case PAGE_FORWARD: {
            const {contentName, contentProps} = payload;
            const nextPage = {
                contentName,
                contentProps,
            };
            return [
                ...state,
                nextPage,
            ];
        }
        case PAGE_BACK: {
            return state.filter((_, i, arr) => i < arr.length - 1);
        }
        case FRONT_PAGE: {
            return [state[0]];
        }
        default: {
            return state;
        }
    }
};
