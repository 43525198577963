export const EDITABLE_TYPES = {
    image: 'image',
    text: 'text',
    visibility: 'visibility',
};

export const EDITABLE_ATTRIBUTES = {
    editable: 'data-editable-element',
    image: 'data-editable-image',
    text: 'data-editable-text',
    name: 'data-editable-name',
    visibility: 'data-editable-visibility',
    library: 'data-editable-library',
    userImageLibraryFolder: 'data-user-library-folder', // ZACH ADDED 1/18/21 -- For filtering the library images shown to just one folder
    color: 'data-color-override-name', // TODO REMOVE -- 12/11/20 replacing with "colorOverrides"
    colorProperty: 'data-color-override-property', // TODO REMOVE -- 12/11/20 replacing with "colorOverrides"
    colorOverrides: 'data-color-override', // ZACH ADDED 12/11/20
    customInlineStyle: 'data-style', // ZACH ADDED 1/6/22
    font: 'data-editable-font',
    fontSize: 'data-editable-fontsize',
    nonBackgroundImage: 'data-non-background-image'
};

export const SIDEBAR_CONTENT_CODES = {
    imageFiledImageLibrary: 'ImageFieldImageLibrary',
    uploadForm: 'UploadForm',
    cropper: 'Cropper',
    templateForm: 'TemplateForm',
};

export const FLASH = {
    timeout: 10000,
    types: {
        alert: 'alert',
        notice: 'notice',
    },
};

export const FORM_NAMES = {
    theme: 'themeForm',
    userUpload: 'userUpload',
};
