import { connect } from 'react-redux';

import DownloadButton from './component';

import { downloadingSelector } from "../../selectors/state";
import { downloadButtonClicked } from "../../actions/interfaceActions";

const mapStateToProps = (state) => ({
    downloading: downloadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    handleDownloadButtonClicked: () => dispatch(downloadButtonClicked()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadButton);
