import React from 'react';
import PropTypes from 'prop-types';

import TemplateConstructor from '../TemplateConstructor/container';

import { isPresent } from "../../../../business/helpers/utilities";

export default class TemplateRootElement extends React.Component {

    static propTypes = {
        elementInitialized: PropTypes.func.isRequired,
        elementUpdate: PropTypes.func.isRequired,
        elementRemove: PropTypes.func.isRequired,
        nodeData: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            attributes: PropTypes.object,
            children: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
    };

    element = React.createRef();

    handleResize = this.props.elementUpdate;

    componentDidMount() {
        this.props.elementInitialized(this.element);
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        this.props.elementRemove();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {attributes, name, children} = this.props.nodeData;
        const childNodes = isPresent(children) && children.map((id) => <TemplateConstructor key={id} id={id} parentRef={this.element}/>);
    }

    render() {
        const {nodeData} = this.props;

        if (!isPresent(nodeData)) {
            return null;
        }



        const {attributes, name, children} = nodeData;
        // console.log('root element children are', children);
        const CustomTagName = `${name}`;
        const childNodes = isPresent(children) && children.map((id) => <TemplateConstructor key={id} id={id} parentRef={this.element}/>);

        return (
            <CustomTagName {...attributes} ref={this.element}>
                {childNodes}
            </CustomTagName>
        );
    }
}
