import axios from 'axios';

export const getImageLibrary = ({code, page, per_page, search_term}) => {
    return axios.get(`/image_library/${code}.json`, {
        params: {
            page,
            per_page,
            search_term
        }
    });
};