import {normalize} from "normalizr";
import {OrderedMap} from 'immutable';
import {camelCase, isElement} from 'lodash';

import {domDataSchema} from "../../services/mason/schema/domData";
import {DomParser} from "./domParser";
import {getEl} from "../../interface/helpers/registry";
import {connect} from "react-redux";
import ImageEditor from "../../interface/widgets/ImageEditor/component";
import {hasDomDataSelector, templateNameSelector} from "../../interface/selectors/template";

export const equalSize = (positionA, positionB) => positionA.width === positionB.width && positionA.height === positionB.height;

export const objectEqual = (x, y, equalConstructor = true) => {
    // if both x and y are null or undefined and exactly the same
    if (x === y) { return true; }

    // if they are not strictly equal, they both need to be Objects
    if (!(x instanceof Object) || !(y instanceof Object)) { return false; }

    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.
    if (x.constructor !== y.constructor && equalConstructor) { return false; }


    for (const p in x) {
        // other properties were tested using x.constructor === y.constructor
        if (!x.hasOwnProperty(p)) { continue; }

        // allows to compare x[ p ] and y[ p ] when set to undefined
        if (!y.hasOwnProperty(p)) { return false; }

        // if they have the same strict value or identity then they are equal
        if (x[p] === y[p]) { continue; }

        // Numbers, Strings, Functions, Booleans must be strictly equal
        if (typeof (x[p]) !== "object") { return false; }

        // Objects and Arrays must be tested recursively
        if (!Object.equals(x[p], y[p])) { return false; }
    }

    for (const p in y) {
        //Y has property that X does not
        // allows x[ p ] to be set to undefined
        if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) { return false; }
    }
    return true;
};

export const isPresent = (item) => {
    if (!item && item !== 0 && item !== '') {
        return false;
    }

    if (Array.isArray(item) && item.length === 0) {
        return false;
    }

    if (typeof item === 'object' && Object.entries(item).length === 0) {
        return false;
    }

    return true;
};

/**
 * Checks deeply for nested properties
 *
 * @param obj
 * @param level
 * @param rest
 * @returns {*}
 */
export const checkNested = (obj, level,  ...rest) => {
    if (obj === undefined || !obj) return false;
    if (rest.length === 0 && obj.hasOwnProperty(level)) return true;
    return checkNested(obj[level], ...rest)
};


/**
 * Checks deeply for nested property and returns it
 *
 * @param obj
 * @param args
 * @returns {*}
 */
export const getNested = (obj, ...args) => {
    return args.reduce((obj, level) => obj && obj[level], obj)
};

export const isTextNode = (nodeType) => nodeType === 3;

export const isImageNode = (nodeData) => nodeData && nodeData.name === 'img';

export const getPosition = (el) => {
    if (!el) {
        return {};
    }

    const rect = el.getBoundingClientRect();

    return {
        top: rect.top + pageYOffset,
        right: rect.right + pageXOffset,
        bottom: rect.bottom + pageYOffset,
        left: rect.left + pageXOffset,
        width: rect.width,
        height: rect.height,
        offsetWidth: el.offsetWidth,
        offsetHeight: el.offsetHeight,
    };
};

/**
 * Search up in the dom tree to see if any parent elements are rotated.
 * This allows us to rotate the selection box
 * - NOTE from https://css-tricks.com/get-value-of-css-rotation-through-javascript/
 *
 * @param el
 * @returns {object|null}
 */
function findRotationUpInDomTree(el) {
    while (el.parentNode) {
        el = el.parentNode;
        if(el && isElement(el)) {
            var style = window.getComputedStyle(el);
            var transform = style.getPropertyValue("-webkit-transform") ||
                style.getPropertyValue("-moz-transform") ||
                style.getPropertyValue("-ms-transform") ||
                style.getPropertyValue("-o-transform") ||
                style.getPropertyValue("transform") ||
                null;

            if(transform && transform !== 'none'){
                var values = transform.split('(')[1];
                values = values.split(')')[0];
                values = values.split(',');

                var a = values[0]; // 0.866025
                var b = values[1]; // 0.5
                var c = values[2]; // -0.5
                var d = values[3]; // 0.866025
                var angle = Math.round(Math.asin(b) * (180/Math.PI));

                if (angle) {
                    var transformOrigin = style.getPropertyValue("-webkit-transform-origin") ||
                        style.getPropertyValue("-moz-transform-origin") ||
                        style.getPropertyValue("-ms-transform-origin") ||
                        style.getPropertyValue("-o-transform-origin") ||
                        style.getPropertyValue("transform-origin") ||
                        null;

                    return {
                        angle: angle,
                        transformOrigin
                    };
                }
            }
        }

    }
    return null;
}


function findDepthInDomTree(el) {
    let zIndex = 1;
    while (el.parentNode) {
        el = el.parentNode;
        if(el && isElement(el)) {
            zIndex++;
        }

    }
    return zIndex;
}

export const getOverlayPosition = (editableId, templateType, isLegacyTemplate) => {
    const rootRef = getEl(templateType);
    const editableEl = getEl(templateType + editableId);

    if (!rootRef || !editableEl) { return {}; }

    const isEditableText = editableEl.current && editableEl.current.hasAttribute('data-editable') && editableEl.current.getAttribute('data-editable') === 'text';

    const templatePos = getPosition(rootRef.current);
    let editablePos = getPosition(editableEl.current);

    const zIndex = findDepthInDomTree(editableEl.current);

    let rotationAngle, transformOrigin = undefined;
    const rotationObj = findRotationUpInDomTree(editableEl.current);
    if(rotationObj){
        rotationAngle = rotationObj.angle;
        transformOrigin = rotationObj.transformOrigin;
    }

    //* For legacy templates, the editable text elements aren't necessarily coded within a parent container, so we just grab its position directly.
    if(isEditableText && !isLegacyTemplate){
        if(editableEl.current.parentNode){
            // if(editableEl.current.getAttribute('data-name') === 'gameday-ribbon-text'){
            //     console.log('looking at ribbon text node:', editableEl.current);
            //     console.log('computed style: ', window.getComputedStyle(editableEl.current));
            // }
            editablePos = getPosition(editableEl.current.parentNode);
        }
    }

    // Calculate relative position of Editable Element on page before scaling
    const relativePos = {
        top: Math.abs(editablePos.top - templatePos.top),
        bottom: Math.abs(editablePos.bottom - templatePos.bottom),
        left: Math.abs(editablePos.left - templatePos.left),
        right: Math.abs(editablePos.right - templatePos.right),
    };

    // Calculate the scaling factor of the template
    const templateScale = templatePos.offsetWidth / templatePos.width;

    // Calculate the position of the Editable Element on page after scaling
    return {
        zIndex,
        top: relativePos.top * templateScale || 0,
        bottom: relativePos.bottom * templateScale || 0,
        left: relativePos.left * templateScale || 0,
        right: relativePos.right * templateScale || 0,
        width: editablePos.offsetWidth,
        height: editablePos.offsetHeight,
        rotationAngle,
        transformOrigin
    };
};

export const parseStyles = (styles) => styles
    .split(';')
    .filter((style) => style.split(':')[0] && style.split(':')[1])
    .map((style) => [
        camelCase(style.split(':')[0].trim()),
        style.split(':')[1].trim()
    ])
    .reduce((styleObj, style) => ({
        ...styleObj,
        [style[0]]: style[1],
    }), {});

/**
 * Parses a comma separated string of css properties into an array
 * @param styles
 * @returns {*}
 */
export const parseStyleProperties = (styles) => styles
    .split(',')
    .map((styleProp) => camelCase(styleProp.trim()));

export const hasOnlySpaces = (str) => str.replace(/\s/g, '').length === 0;

export const createNormalizedNodeData = (dom) => {
    const domParser = new DomParser();
    const reactTemplate = dom.getElementById('react-template');
    const template = reactTemplate && reactTemplate.parentNode;
    const domData = template && domParser.createTree(template);

    return template && normalize(domData, domDataSchema).entities;
};

//getting data from the root template element
// its for being able to calculate relative position values based on the root elements rendered position
export const getRootData = (ref) => {
    const element = ref.current;
    const position = getPosition(element);

    return {position};
};

export const getParentNode = (childId, nodes) => {
    for (const node of Object.values(nodes)) {
        if (node.children.indexOf(childId) === -1) { continue; }

        return node;
    }

    return null;
};

export const arrToMap = (arr) => {
    return arr.reduce((acc, item) => ({
        ...acc,
        [item.id]: item,
    }), {});
};

export function arrToImmutableMap(arr, RecordModel) {
    return arr.reduce((acc, item) => acc.set(item.id, RecordModel ? new RecordModel(item) : item), new OrderedMap());
}

export const hasField = (obj = {}, fieldName) => fieldName in obj && isPresent(obj[fieldName]);

export const deleteProp = (obj, prop) => Object.entries(obj).reduce((res, [key, val]) => {
    if (key === prop) { return res; }

    return {
        ...res,
        [key]: val,
    };
}, {});

export const isSingleDomElement = (tagName) => {
    if (tagName === 'br' || tagName === 'img') {
        return true;
    }

    return false;
};

export const roundToTwo = num => {
    return +(Math.round(Number(num) + "e+2") + "e-2");
}

export const roundToFive = num => {
    return +(Math.round(Number(num) + "e+5") + "e-5");
}

/**
 * Console log function that checks for user as an admin first
 * TODO Check if user is an admin from redux store
 *
 * @param output
 * @param output2
 * @param output3
 * @param output4
 * @param output5
 */
export const zconsole = (
    output,
    output2 = null,
    output3 = null,
    output4 = null,
    output5 = null,
) => {
    if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
        output = output ? JSON.parse(JSON.stringify(output)) : null;
        output2 = output2 ? JSON.parse(JSON.stringify(output2)) : null;
        output3 = output3 ? JSON.parse(JSON.stringify(output3)) : null;
        output4 = output4 ? JSON.parse(JSON.stringify(output4)) : null;
        output5 = output5 ? JSON.parse(JSON.stringify(output5)) : null;
        if ( output && output2 && output3 && output4 && output5 ) {
            console.log(output, output2, output3, output4, output5);
        } else if (output && output2 && output3 && output4 ) {
            console.log(output, output2, output3, output4);
        } else if (output && output2 && output3) {
            console.log(output, output2, output3);
        } else if (output && output2) {
            console.log(output, output2);
        } else if (output) {
            console.log(output);
        }
    }
};
