import { connect } from 'react-redux';

import Downloadable from './component';

import * as ui from '../../actions/interfaceActions';

const mapStateToProps = (state) => {
    return {
        downloading: state.business.downloading,
        filename: state.business.templateName
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleDownloadCompleted: () => dispatch(ui.fileDownloaded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Downloadable);
