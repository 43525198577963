import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const FontFormField = (props) => {
    const {name, activeFontId, fonts, handleFontChange, label} = props;
    const displayLabel = label ? label : name;
    const options = fonts && fonts.map(({name, id}) =>
        <option key={id} value={id}>{name}</option>
    );

    return (
        <div className="font-form-field mb-3">
            <div className="">{displayLabel}:</div>
            <select
                className="form-control"
                value={activeFontId || ''}
                onChange={handleFontChange}
            >
                <option value="0">- Template Default -</option>
                {options}
            </select>
        </div>
    );
};

FontFormField.propTypes = {
    fonts: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
    })),
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    activeFontId: PropTypes.number,
    handleFontChange: PropTypes.func.isRequired,
};

export default FontFormField;
