import { combineReducers } from 'redux';

import nodeData from './nodeDataReducer';
import rootData from './rootDataReducer';
import editableData from './editableDataReducer';

import reUseReducer from '../helpers/reUseReducer';
import { TEMPLATE_TYPES } from "../constants";

const rectangleTemplateDataReducer = combineReducers({
    nodeData,
    rootData,
    editableData,
});

export default reUseReducer(TEMPLATE_TYPES.rectangle, rectangleTemplateDataReducer);
