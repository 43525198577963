import { combineReducers } from 'redux';
import modal from './modal';
import flash from './flash';
import spinner from './spinner';
import forms from './forms';
import sidebarStack from './sidebarStack';
import highlights from './highlights';
import formTextField from './formTextField';
import autoSave from './autoSaveReducer';

const uiReducer = combineReducers({
    modal,
    flash,
    spinner,
    forms,
    sidebarStack,
    highlights,
    formTextField,
    autoSave,
});

export default uiReducer;
