import React from 'react';
import PropTypes from 'prop-types';

export default class Range extends React.Component {

    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.number.isRequired,
        min: PropTypes.number,
        max: PropTypes.number,
        step: PropTypes.number,
        id: PropTypes.string,
        name: PropTypes.string,
    };

    static defaultProps = {
        min: -1,
        max: 1,
        step: 0.01,
    };

    input = React.createRef();

    handleChange = e => {
        this.props.onChange(e);
    };

    render() {
        return <input
            {...this.props}
            onChange={this.handleChange}
            value={this.props.value}
            ref={this.input}
            type="range"
        />;
    }
}
