import React from 'react';
import PropTypes from 'prop-types';

import Spinner from '../Spinner/component';

import { isPresent } from "../../../business/helpers/utilities";

const SpinnerContainer = (props) => {
    const {message} = props;

    if (!isPresent(message) || !message.length) {
        return null;
    }

    return (<Spinner />);
};

SpinnerContainer.propTypes = {
    message: PropTypes.string,
};

export default SpinnerContainer;
