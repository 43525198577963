import React from 'react';
import { StickyContainer, Sticky } from 'react-sticky';
import PropTypes from 'prop-types';

const StickyWrapper = (props) => (
    <StickyContainer className="col-md-8">
        <Sticky>
            {
                ({style}) => {
                    return (
                        <div style={style}>
                            {props.children}
                        </div>
                    );
                }
            }
        </Sticky>
    </StickyContainer>
);

StickyWrapper.propTypes = {
    children: PropTypes.object,
};

export default StickyWrapper;
