import axios from "axios";
import {AUTH_TOKEN} from "../../image_editor/js/services/mason/config";

document.addEventListener('turbolinks:load', (event)=>{
    if(document.body.classList.contains('subscription-form-new')) {
        jQuery(document).ready(async function ($) {
            let stripeFieldsActive = false;
            const form = document.getElementById('payment-form');

            if (form === null) {
                return null;
            }

            const stripe_public_key = document.querySelector("meta[name='stripe_public_key']").getAttribute('content');
            const clientSecret = document.querySelector("input[name='client_secret']").value;
            // eslint-disable-next-line
            const stripe = Stripe(stripe_public_key);
            const elements = stripe.elements();

            const elementTypes = ['cardNumber', 'cardExpiry', 'cardCvc'];
            let createdElements = [];

            const createElem = (elementType) => {
                const element = elements.create(elementType);
                element.mount(`#${elementType}-element`);
                createdElements.push(element);

                element.addEventListener('change', (event) => {
                    const errorElement = document.getElementById(`${elementType}-errors`);
                    const displayError = document.getElementById(elementType + '-errors');

                    if (event.error) {
                        errorElement.textContent = event.error.message;
                    } else {
                        displayError.textContent = '';
                    }
                });
            };

            const unMountElem = (element) => {
                element.unmount();
            };

            const mountElem = (element) => {
                const componentName = element && element._componentName ? element._componentName : false;
                if (componentName) {
                    element.mount(`#${componentName}-element`);
                } else {
                    // Error
                }
            };

            const disableSubmitButton = () => {
                const button = document.getElementById('stripe-submit');
                button.setAttribute("disabled", "true");
            };

            const enableSubmitButton = () => {
                const button = document.getElementById('stripe-submit');
                button.removeAttribute("disabled", "false");
            };

            const handleSourceCreated = (result) => {
                if (result.error) {
                    enableSubmitButton();
                    console.error('CARD ERROR: ', result.error.message);
                } else {
                    $('#stripe_token_id').val(result.token.id);
                    form.submit();
                }
            };

            const handleFormSubmit = (event) => {
                event.preventDefault();
                disableSubmitButton();

                let card;
                let token;
                if (stripeFieldsActive === true) {
                    card = createdElements[0];
                    if(card){
                        stripe
                            .createToken(card)
                            .then(handleSourceCreated);
                    }
                } else {
                    form.submit();
                }


            };

            elementTypes.forEach(createElem);
            form.addEventListener('submit', handleFormSubmit);


            const $payByMethod = $('input[name="pay-by-method"]');
            if ($payByMethod.length) {
                const changePayByMethod = () => {
                    const activeVal = $(document).find('input[name="pay-by-method"]:checked').val();
                    if (activeVal === 'saved-card') {
                        $('.credit-card-fields').hide();
                        stripeFieldsActive = false;
                        createdElements.forEach(unMountElem);
                    } else {
                        if (createdElements.length) {
                            createdElements.forEach(mountElem);
                        } else {
                            elementTypes.forEach(createElem);
                        }

                        $('.credit-card-fields').show();
                        stripeFieldsActive = true;
                    }
                };
                $(document).on('change', '[name="pay-by-method"]', changePayByMethod);
                changePayByMethod();
            }

            const renderPageWithCouponApplied = (subtotal, total, discount) => {
                $('#subtotal-amount').text((subtotal/100).toLocaleString(undefined, { minimumFractionDigits: 2 }));
                $('#total-amount').text((total/100).toLocaleString(undefined, { minimumFractionDigits: 2 }));
                $('#discount-amount').text((discount/100).toLocaleString(undefined, { minimumFractionDigits: 2 }));
                $('.coupon-success-display').show();
            };

            let submitting = false;
            $('.apply-coupon').on('click', async (e) => {
                e.preventDefault();

                if(!$('input[name="coupon"]').val()){
                    $('#coupon-error').text('No coupon code entered.');
                    $('.coupon-error-display').show();
                    return;
                }


                if(submitting === false){
                    submitting = true;
                    $('#coupon-loading-spinner').show();
                    $('.coupon-error-display').hide();
                    $('.coupon-success-display').hide();

                    const couponData = new FormData();
                    couponData.append('authenticity_token', AUTH_TOKEN);
                    couponData.append('coupon', $('input[name="coupon"]').val());
                    couponData.append('price', $('input[name="price"]').val());
                    const coupon = axios.post(`/subscribe/check_coupon`, couponData, {})
                        .then(response => {
                            submitting = false;
                            $('#coupon-loading-spinner').hide();
                            // console.log('success', response);
                            const { data: { session: { amount_subtotal, amount_total, total_details: { amount_discount }}}} = response;
                            renderPageWithCouponApplied(amount_subtotal, amount_total, amount_discount);
                        })
                        .catch(e => {
                            submitting = false;
                            $('#coupon-loading-spinner').hide();
                            $('#coupon-error').text(e.response.data.message);
                            $('.coupon-error-display').show();
                        });
                }

            })
        });
    }
});
