document.addEventListener('turbolinks:load', (event)=>{
    jQuery( document ).ready(function( $ ) {
        let currentPlan = {
            level: null,
            term: null
        };
        const $pricingTableContainer = $(document).find('#pricing-table');
        if($pricingTableContainer.length){
            let term = 'annual'; // monthly, annual
            let currency = 'usd'; // usd, gbp, eur

            const data = {
                usd: $('#component-manage-subscription').data('usd'),
                gbp: $('#component-manage-subscription').data('gbp'),
                eur: $('#component-manage-subscription').data('eur')
            }

            const getTermLabel = term => {
                return term === 'annual' ? 'yr' : 'mo';
            }

            const updateOutput = () => {
                term = $(document).find('#subscription-length').val();
                currency = $(document).find('#currency').val();
                var currencySymbol = '';
                switch (currency) {
                    case 'usd':
                        currencySymbol = '$';
                        break;
                    case 'gbp':
                        currencySymbol = '£';
                        break;
                    case 'eur':
                        currencySymbol = '€';
                        break;
                }

                var $planBasic = $('.plan-basic');
                var $planPremium = $('.plan-premium');
                var activeBasic = data[currency]['basic'][term];
                var activePremium = data[currency]['premium'][term];
                var basicPrice = activeBasic.unit_amount / 100;
                var premiumPrice = activePremium.unit_amount / 100;

                $planBasic.find('.price').html(`${currencySymbol}${basicPrice}`);
                $planBasic.find('.period').html(`${getTermLabel(term)}`);

                $planPremium.find('.price').html(`${currencySymbol}${premiumPrice}`);
                $planPremium.find('.period').html(`${getTermLabel(term)}`);

                if(term === 'annual'){
                    $planBasic.find('.annual-discount').show();
                    $planPremium.find('.annual-discount').show();
                }
                else {
                    $planBasic.find('.annual-discount').hide();
                    $planPremium.find('.annual-discount').hide();
                }

                if(currentPlan.term && currentPlan.level){
                    $planBasic.removeClass('active');
                    $planPremium.removeClass('active');

                    if(
                        (currentPlan.term === 'month' && term === 'monthly') ||
                        (currentPlan.term === 'year' && term === 'annual')
                    ) {
                        if(currentPlan.level === 'basic'){
                            $planBasic.addClass('active');
                        }
                        else if(currentPlan.level === 'premium'){
                            $planPremium.addClass('active');
                        }
                    }


                }
            };

            $('#plan-selection-form button').on('click', function(){
                const plan = $(this).val();
                const stripeId = data[currency][plan][term].id;
                $('#stripe-id').val(stripeId);
                $(document).find('#plan-selection-form').trigger('submit');
            });

            //* Initialize the current plan if there is one
            const currentPlanTerm = $(document).find('#current-plan-term');
            const currentPlanLevel = $(document).find('#current-plan-level');
            currentPlan.term = currentPlanTerm.length ? currentPlanTerm.val() : null;
            currentPlan.level = currentPlanLevel.length ? currentPlanLevel.val() : null;

            $(document).on('change', '#subscription-length', updateOutput);
            $(document).on('change', '#currency', updateOutput);

            updateOutput();
        }
    });
});