import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles.scss';

const TemplateState = (props) => {
    const {saving, isDirty, hasError} = props;
    const className = classnames('template-state-icon fa', {
        ['fa-unlock text-warning']: isDirty && !saving && !hasError,
        ['fa-refresh text-info spinning']: saving,
        ['fa-unlock text-danger']: hasError && !saving,
        ['fa-lock text-success']: !saving && !isDirty && !hasError,
    });

    return (
        <div>
            <i className={className}/>
        </div>
    );
};

TemplateState.propTypes = {
    saving: PropTypes.bool.isRequired,
    isDirty: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
};

export default TemplateState;
