import React from 'react';
import PropTypes from 'prop-types';

import TemplateFormField from '../TemplateFormField/container';

import { EDITABLE_TYPES } from "../../../constants";
import { isPresent } from "../../../../business/helpers/utilities";

const EditableElementsForm = (props) => {
    const {editableElementsId} = props;
    const fields = isPresent(editableElementsId) && editableElementsId.map((id, i) =>
        <TemplateFormField dataCy={`TemplateFormField-${i}`} key={id} id={id}/>
    );

    return (
        <div className="editable-form">
            <div className="sidebar-header">
                <h5>Content</h5>
                <hr />
            </div>
            {fields || 'no editable fields'}
        </div>
    );
};

EditableElementsForm.propTypes = {
    editableElementsId: PropTypes.arrayOf(PropTypes.string),
    editableElements: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            style: PropTypes.object,
            [EDITABLE_TYPES.image]: PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.bool,
            ]),
            [EDITABLE_TYPES.text]: PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.bool,
            ]),
            [EDITABLE_TYPES.visibility]: PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.bool,
            ]),
        }),
    ),
};

export default EditableElementsForm;
