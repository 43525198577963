export const TEMPLATE_AUTOSAVE = 'INTERFACE:TEMPLATE_AUTOSAVE';
export const templateAutosave = (immediately) => ({
    type: TEMPLATE_AUTOSAVE,
    payload: { immediately },
});

export const TEMPLATE_STATE_DIRTY = 'INTERFACE:TEMPLATE_STATE_DIRTY';
export const templateStateDirty = () => ({
    type: TEMPLATE_STATE_DIRTY,
});

export const TEMPLATE_STATE_SAVING = 'INTERFACE:TEMPLATE_STATE_SAVING';
export const templateStateSaving = () => ({
    type: TEMPLATE_STATE_SAVING,
});

export const TEMPLATE_STATE_SAVING_SUCCESS = 'INTERFACE:TEMPLATE_STATE_SAVING_SUCCESS';
export const templateStateSavingSuccess = () => ({
    type: TEMPLATE_STATE_SAVING_SUCCESS,
});

export const TEMPLATE_STATE_SAVING_FAIL = 'INTERFACE:TEMPLATE_STATE_SAVING_FAIL';
export const templateStateSavingFail = (error) => ({
    type: TEMPLATE_STATE_SAVING_FAIL,
    payload: { error },
});

export const STATE_MODIFIER_TEMPLATE_STATE_DIRTY = 'UI:STATE_MODIFIER:TEMPLATE_STATE_DIRTY';
export const stateModifierTemplateStateDirty = () => ({
    type: STATE_MODIFIER_TEMPLATE_STATE_DIRTY,
});

export const STATE_MODIFIER_TEMPLATE_STATE_SAVING = 'UI:STATE_MODIFIER:TEMPLATE_STATE_SAVING';
export const stateModifierTemplateStateSaving = () => ({
    type: STATE_MODIFIER_TEMPLATE_STATE_SAVING,
});

export const STATE_MODIFIER_TEMPLATE_STATE_SAVING_SUCCESS = 'UI:STATE_MODIFIER:TEMPLATE_STATE_SAVING_SUCCESS';
export const stateModifierTemplateStateSavingSuccess = () => ({
    type: STATE_MODIFIER_TEMPLATE_STATE_SAVING_SUCCESS,
});

export const STATE_MODIFIER_TEMPLATE_STATE_SAVING_FAIL = 'UI:STATE_MODIFIER:TEMPLATE_STATE_SAVING_FAIL';
export const stateModifierTemplateStateSavingFail = (error) => ({
    type: STATE_MODIFIER_TEMPLATE_STATE_SAVING_FAIL,
    payload: { error },
});
