import { schema } from 'normalizr';
import { libraryImageList } from './libraryImage';

export const imageLibrary = new schema.Entity(
    'imageLibrary',
    {
        library_images: libraryImageList
    },
    {
        idAttribute: 'code'
    }
);