import {START_DOWNLOAD} from "../actions/stateModifierActions";
import {STOP_DOWNLOAD} from "../actions/stateModifierActions";

export default (state = false, action)=>{
    switch (action.type) {
        case START_DOWNLOAD: {
            return true;
        }
        case STOP_DOWNLOAD: {
            return false;
        }
        default: {
            return state;
        }
    }
};
