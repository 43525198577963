export const IMAGE_LIBRARY_REQUESTED = 'SERVICE:MASON:IMAGE_LIBRARY:REQUESTED';
export const imageLibraryRequested = ({code, page, per_page, search_term}) => {
    return {
        type: IMAGE_LIBRARY_REQUESTED,
        payload: { code, page, per_page, search_term }
    };
};

export const IMAGE_LIBRARY_REQUEST_SUCCEEDED = 'SERVICE:MASON:IMAGE_LIBRARY:REQUEST_SUCCEEDED';
export const imageLibraryRequestSucceeded = (response) => {
    return(
        {
            type: IMAGE_LIBRARY_REQUEST_SUCCEEDED,
            payload: response
        }
    );
};

export const IMAGE_LIBRARY_REQUEST_FAILED = 'SERVICE:MASON:IMAGE_LIBRARY:REQUEST_FAILED';
export const imageLibraryRequestFailed = (error) => {
    return(
        {
            type: IMAGE_LIBRARY_REQUEST_FAILED,
            payload: { error },
        }
    );
};
