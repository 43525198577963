import SmartCrop from 'smartcrop';
import cropToBackgroundSize from './cropToBackgroundSize';
import cropToBackgroundPosition from './cropToBackgroundPosition';
import tracking from 'tracking';
import face from 'tracking/build/data/face';

export default (imageUrl, { image }) => {
    const cropArea = {
        height: image.height,
        width: image.width,
        ruleOfThirds: true
    };

    return loadImage(imageUrl).then((image)=>{
        return new Promise((resolve, reject) => {
            faceDetectionTracking(image).then((boostData)=>{
                const cropOptions = {
                    ruleOfThirds: true,
                    boost: boostData
                };

                const options = Object.assign(cropArea, cropOptions);

                resolve({image: image, cropOptions: options});
            });
        });
    }).then(({image, cropOptions}) => {
        return new Promise((resolve, reject) => {
            SmartCrop.crop(image, cropOptions).then((result)=>{
                resolve({image: image, crop: result});
            });
        });
    }).then(({image, crop}) => {
        const imageDimensions = {
            height: image.naturalHeight,
            width: image.naturalWidth
        };

        const autocrop = {
            position: cropToBackgroundPosition(imageDimensions, crop.topCrop),
            scale: cropToBackgroundSize(imageDimensions, crop.topCrop)
        };
        return autocrop;
    });
};

const loadImage = (src) => {
    const promise = new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (e) => reject(e);
        img.setAttribute('crossorigin', 'anonymous');
        img.src = src;
    });

    return promise;
};

const faceDetectionTracking = (img) => {
    return new Promise((resolve, reject) => {
        // TODO: face detection is disabled because its slow
        // We need to look at speeding this up by either using in external service for face detection and/or
        // storing Face detection results after the first try
        // const tracking = window.tracking;
        // const tracker = new tracking.ObjectTracker("face");
        // tracking.track(img, tracker);
        //
        // tracker.on('track', (event) => {
        //     console.log('tracking.js detected ' + event.data.length + ' faces', event.data);
        //
        //     const boostData = event.data.map((face) => {
        //         return {
        //             x: face.x,
        //             y: face.y,
        //             width: face.width,
        //             height: face.height,
        //             weight: 1.0
        //         };
        //     });
        //
        //     resolve(boostData)
        // });
        resolve([]);
    });
};