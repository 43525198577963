import { takeEvery, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { imageLibrary } from '../../schema/imageLibrary';
import { libraryImage } from '../../schema/libraryImage';

import * as endpoints from './endpoints';
import * as mason_user_upload from './actions';
import * as business from '../../../../business/actions/businessActions';

const handleUserUploadsRequested = function*(action) {
    const { user_image_library_folder, page, per_page, search_term } = action.payload;
    // console.log('before get uploads with search_term', search_term);


    try {
        const response = yield endpoints.getUserUploads({user_image_library_folder, page, per_page, search_term});
        if(response){
            yield put(mason_user_upload.userUploadsRequestSucceeded(response));
        }
    }
    catch (e) {
        yield put(mason_user_upload.userUploadsRequestFailed(e));
    }
};

const extractLibraryEntities = (response)=>{
    return normalize(response.data, imageLibrary).entities;
};

const extractLibraryImageEntities = (response)=>{
    return normalize(response.data, libraryImage).entities;
};

const extractErrors = (response) => {
    return response.data;
};

const handleCreateUserUploadRequested = function*(action) {
    const {name, file, user_image_library_folder} = action.payload;

    try {
        const response = yield endpoints.createUserUpload({name, file, user_image_library_folder});
        yield put(mason_user_upload.createUserUploadRequestSucceeded(response));
    } catch(e) {
        yield put(mason_user_upload.createUserUploadRequestFailed(e.response));
    }

};

const handleDeleteUserUploadRequested = function*(action) {
    const id = action.payload;

    try {
        const response = yield endpoints.deleteUserUpload({id});
        yield put(mason_user_upload.deleteUserUploadRequestSucceeded(id));
    } catch(e) {
        yield put(mason_user_upload.deleteUserUploadRequestFailed(e.response));
    }

};

export default function*(action) {
    yield takeEvery(mason_user_upload.USER_UPLOADS_REQUESTED,                   handleUserUploadsRequested);
    yield takeEvery(mason_user_upload.USER_UPLOADS_REQUEST_SUCCEEDED,           function*(action){
        yield put(business.userUploadsRequestSucceeded(extractLibraryEntities(action.payload)));
    });
    yield takeEvery(mason_user_upload.USER_UPLOADS_REQUEST_FAILED, function*(action){ yield put(business.userUploadsRequestFailed(action.payload)); });

    yield takeEvery(mason_user_upload.CREATE_USER_UPLOAD_REQUESTED,             handleCreateUserUploadRequested);
    yield takeEvery(mason_user_upload.CREATE_USER_UPLOAD_REQUEST_SUCCEEDED,     function*(action){ yield put(business.createUserUploadSucceeded(extractLibraryImageEntities(action.payload))); });
    yield takeEvery(mason_user_upload.CREATE_USER_UPLOAD_REQUEST_FAILED,        function*(action){ yield put(business.createUserUploadFailed(extractErrors(action.payload))); });

    yield takeEvery(mason_user_upload.DELETE_USER_UPLOAD_REQUESTED,             handleDeleteUserUploadRequested);
    yield takeEvery(mason_user_upload.DELETE_USER_UPLOAD_REQUEST_SUCCEEDED,     function*(action){ yield put(business.deleteUserUploadSucceeded(action.payload)); });
    yield takeEvery(mason_user_upload.DELETE_USER_UPLOAD_REQUEST_FAILED,        function*(action){ yield put(business.deleteUserUploadFailed(extractErrors(action.payload))); });
}