import { connect } from 'react-redux';

import Template from './component';

import { hasDomDataSelector, templateTypeSelector } from "../../../selectors/template";
import {isLegacyTemplateSelector} from "../../../selectors/staticInfo";

const mapStateToProps = (state) => ({
    hasDomData: hasDomDataSelector(state),
    templateType: templateTypeSelector(state),
    isLegacyTemplate: isLegacyTemplateSelector(state)
});

export default connect(mapStateToProps)(Template);
