export default (image, background) => {
    //image { height: height, width: width }
    //background { x: x-pos, y: y-pos, height: heightScale, width: widthScale } (Floats)

    // 100% for background-position is where the right side of the image touches the right side of the frame
    // 0% for background-position is where the left side of the image touches the left side of the frame

    const cropHeight = image.naturalHeight / background.height;
    const cropWidth = image.naturalWidth / background.width;

    const cropPosX = background.x * (image.naturalWidth - cropWidth);
    const cropPosY = background.y * (image.naturalHeight - cropHeight);

    return {
        x: cropPosX,
        y: cropPosY,
        height: cropHeight,
        width: cropWidth
    };
};