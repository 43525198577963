import { connect } from 'react-redux';

import TemplateState from './component';

import { isDirtyStateSelector, isSavingStateSelector, hasErrorSelector } from "../../selectors/autoSave";

const mapStateToProps = (state) => ({
    saving: isSavingStateSelector(state),
    isDirty: isDirtyStateSelector(state),
    hasError: hasErrorSelector(state),
});

export default connect(mapStateToProps)(TemplateState);
