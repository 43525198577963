import React from 'react';
import PropTypes from 'prop-types';
import ContrastColorElement from "./ContrastColorElement";

export default class ContrastColorPickers extends React.Component {
    static propTypes = {
        styles: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        // compareValue: PropTypes.string.isRequired
    };

    static defaultProps = {
        height: 'auto'
    }

    constants = {
        lightVal: "#FFFFFF",
        darkVal: "#000000"
    }

    _setActiveVal(hex){
        this.setState({ activeVal: hex });
    }

    render() {
        const { label, onChange, value, compareValue } = this.props;
        const { lightVal, darkVal } = this.constants;
        return (
            <div className="contrast-color-pickers row">
                <ContrastColorElement
                    // label={`${label} Light Contrast`}
                    label={`Light Contrast`}
                    value={lightVal}
                    compareValue={compareValue}
                    active={value === lightVal}
                    onClick={onChange}
                    // onClick={this._setActiveVal}
                />
                <ContrastColorElement
                    // label={`${label} Dark Contrast`}
                    label={`Dark Contrast`}
                    value={darkVal}
                    compareValue={compareValue}
                    active={value === darkVal}
                    onClick={onChange}
                    // onClick={this._setActiveVal}
                />
            </div>
        );
    }
}
