import { connect } from 'react-redux';

import TemplateConstructorComponent from './component';

import { createNodeDataWithThemeSelector } from "../../../selectors/template";

const mapStateToProps = () => {
    const nodeDataSelector = createNodeDataWithThemeSelector();

    return (state, ownProps) => ({
        nodeData: nodeDataSelector(state, ownProps),
    });
};

export default connect(mapStateToProps)(TemplateConstructorComponent);
