import 'bootstrap/dist/js/bootstrap';
import './subscription_form';
import './subscription_form_new';
import './order_confirmation';
import './plan_selection_form';
import './dropzone';
import isotope from 'isotope-layout';


document.addEventListener('turbolinks:load', (event)=>{
  jQuery( document ).ready(function( $ ) {

    //* Bootstrap tooltips
    $('[data-toggle="tooltip"]').tooltip()

    //* Filterable Grids (for templates and such)
    var elem = $('.grid');
    var filters = {};
    if(elem.length){
      var iso = new isotope('.grid');

      $('.filter-button-group').on( 'click', 'button', function() {
        $(this).siblings().removeClass('active');
        $(this).addClass('active');
        var filterGroup = $(this).attr('data-filter-group');
        var filterValue = $(this).attr('data-filter');
        filters[filterGroup] = filterValue === '*' ? '*' : `.${filterValue}`;

        isoArrange();
      });

      $('.filter-select').on( 'change', function() {
        var filterValue = $(this).val();
        var filterGroup = $(this).attr('data-filter-group');
        filters[filterGroup] = filterValue === '*' ? '*' : `.${filterValue}`;

        isoArrange();
      });

      const isoArrange = () => {
        var filterValue = concatValues( filters );
        // console.log('filterval', filterValue);
        iso.arrange({
          filter: filterValue === '*' ? '*' : `${filterValue}`,
        });

        if ( !iso.filteredItems.length ) {
          var currentVal = $('.filter-button-group button.active').text();
          if(currentVal){
            $('.template-type-label-dynamic-replace').html(`"${currentVal}" templates`);
          }
          else {
            $('.template-type-label-dynamic-replace').html('templates of that type');
          }
          $('.filter-none-found').show();
        }
        else {
          $('.filter-none-found').hide();
        }
      }

      // flatten object by concatting values
      const concatValues = obj => {
        // console.log('obj', obj);
        // console.log('obj size', Object.keys(obj).length);
        var value = '';
        for ( var prop in obj ) {
          value += obj[ prop ] !== '*' ? obj[ prop ] : '';
        }
        value = !value ? "*" : value;
        return value;
      }

      setTimeout(function(){ isoArrange(); }, 100);
    }
  });
});