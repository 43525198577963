import { takeEvery, put } from 'redux-saga/es/effects';

import * as mason_image_library from '../../services/mason/resource/imageLibrary/actions';
import * as business from '../actions/businessActions';
import * as ui from '../../interface/actions/interfaceActions';

export default function* () {
    yield takeEvery(business.IMAGE_LIBRARY_REQUESTED, function* (action) { yield put(mason_image_library.imageLibraryRequested(action.payload)); });
    yield takeEvery(business.IMAGE_LIBRARY_REQUEST_SUCCEEDED, function* (action) { yield put(business.updateEntitiesRequested(action.payload)); });
    yield takeEvery(business.IMAGE_LIBRARY_REQUEST_FAILED, function* (action) { yield put(ui.errorsReceived(action.payload.errors)); });
}
