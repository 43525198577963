import {
    UPDATE_TEMPLATE_EDITABLE_IMAGE,
    UPDATE_TEMPLATE_EDITABLE_VISIBILITY,
    UPDATE_TEMPLATE_EDITABLE_TEXT,
    UPDATE_EDITABLE_ELEMENT_POSITION,
    UPDATE_EDITABLE_DATA,
    UPDATE_TEXT_VALIGN_OVERRIDE,
    UPDATE_TEXT_FUNDAMENTAL_FONT_SCALE,
    UPDATE_TEXT_NO_OVERFLOW_FONT_SCALE,
    UPDATE_TEXT_METRICS,
    UPDATE_TEXT_ADJUSTMENTS_WITHOUT_OVERFLOW_FONT_SCALE,
    UPDATE_TEMPLATE_EDITABLE_IMAGE_CROP,
} from "../actions/stateModifierActions";

import { getUniqueId } from "../helpers/getId";

//editable elements additional data
const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    const {type, payload} = action;
    switch (type) {
        case UPDATE_EDITABLE_DATA:
            if (!state[payload.id]) {
                return state;
            }

            return {
                ...state,
                [payload.id]: {
                    ...state[payload.id],
                    ...payload.editableDataItem,
                    idKey: getUniqueId(),
                },
            };
        case UPDATE_TEMPLATE_EDITABLE_IMAGE: {
            if (!state[payload.id]) {
                return state;
            }

            return {
                ...state,
                [payload.id]: {
                    ...state[payload.id],
                    idKey: getUniqueId(),
                    image: {
                        ...state[payload.id].image,
                        src: payload.src,
                    },
                },
            };
        }
        case UPDATE_TEMPLATE_EDITABLE_IMAGE_CROP: {
            if (!state[payload.id]) {
                return state;
            }

            return {
                ...state,
                [payload.id]: {
                    ...state[payload.id],
                    idKey: getUniqueId(),
                    image: {
                        ...state[payload.id].image,
                        backgroundPosition: payload.position,
                        backgroundSize: payload.size,
                    },
                },
            };
        }
        case UPDATE_TEMPLATE_EDITABLE_VISIBILITY: {
            if (!state[payload.id]) {
                return state;
            }

            return {
                ...state,
                [payload.id]: {
                    ...state[payload.id],
                    visibility: {
                        ...state[payload.id].visibility,
                        isVisible: payload.isVisible,
                    }
                },
            };
        }
        case UPDATE_TEMPLATE_EDITABLE_TEXT: {
            if (!state[payload.id]) {
                return state;
            }

            return {
                ...state,
                [payload.id]: {
                    ...state[payload.id],
                    idKey: getUniqueId(),
                    text: {
                        ...state[payload.id].text,
                        value: payload.value,
                    }
                },
            };
        }

        case UPDATE_TEXT_VALIGN_OVERRIDE: {
            if (!state[payload.id]) {
                return state;
            }

            return {
                ...state,
                [payload.id]: {
                    ...state[payload.id],
                    idKey: getUniqueId(),
                    text: {
                        ...state[payload.id].text,
                        vertical_align_override: payload.value,
                    }
                },
            };
        }

        case UPDATE_TEXT_FUNDAMENTAL_FONT_SCALE: {
            if (!state[payload.id]) {
                return state;
            }

            return {
                ...state,
                [payload.id]: {
                    ...state[payload.id],
                    idKey: getUniqueId(),
                    text: {
                        ...state[payload.id].text,
                        fundamental_font_scale: payload.value,
                    }
                },
            };
        }

        case UPDATE_TEXT_ADJUSTMENTS_WITHOUT_OVERFLOW_FONT_SCALE: {
            if (!state[payload.id]) {
                return state;
            }

            return {
                ...state,
                [payload.id]: {
                    ...state[payload.id],
                    idKey: getUniqueId(),
                    text: {
                        ...state[payload.id].text,
                        adjustments: {
                            ...payload.value,
                            overflowScale: undefined // Why the fuck did I add this...? I guess maybe cuz I am applying it separately?
                        },
                    }
                },
            };
        }

        case UPDATE_TEXT_NO_OVERFLOW_FONT_SCALE: {
            if (!state[payload.id]) {
                return state;
            }

            return {
                ...state,
                [payload.id]: {
                    ...state[payload.id],
                    idKey: getUniqueId(),
                    text: {
                        ...state[payload.id].text,
                        adjustments: {
                            ...state[payload.id].text.adjustments,
                            overflowScale: payload.value
                        }
                        // no_overflow_font_scale: payload.value,
                    }
                },
            };
        }

        case UPDATE_TEXT_METRICS: {
            if (!state[payload.id]) {
                return state;
            }

            return {
                ...state,
                [payload.id]: {
                    ...state[payload.id],
                    idKey: getUniqueId(),
                    text: {
                        ...state[payload.id].text,
                        metrics: payload.value,
                    }
                },
            };
        }

        case UPDATE_EDITABLE_ELEMENT_POSITION: {
            if (!state[payload.id]) {
                return state;
            }

            return {
                ...state,
                [payload.id]: {
                    ...state[payload.id],
                    idKey: getUniqueId(),
                    position: payload.position,
                },
            };
        }
        default: {
            return state;
        }
    }
};
