import React from 'react';

import Scalable from '../Scalable/container';
import Downloadable from '../Downloadable/container';
import Overlays from '../Overlays/container';
import Template from '../TemplateLayout/Template/container';

const ImageCanvas = () => {
    return (
        <Scalable>
            <Downloadable>
                <div className="image-canvas" id="image-canvas" style={{overflow: 'hidden'}}>
                    <style>
                        {`.image-canvas table {border-collapse: unset}`}
                    </style>
                    <Template/>
                </div>
            </Downloadable>
            <Overlays/>
        </Scalable>
    );
};

export default ImageCanvas;
