import { createSelector } from 'reselect';
import { nodesDataSelector, nodeDataSelector, currentTemplateDataSelector, createNodeDataWithThemeSelector } from './template';
import {getEditableImageValue, updateStyleInNode} from './utilities';
import { isEditableElement, userCanEdit } from '../../business/helpers/elementsData';
import {EDITABLE_ATTRIBUTES, EDITABLE_TYPES} from '../constants';
import { filter, sortBy, size } from "lodash";
import {checkNested, getNested, roundToFive} from "../../business/helpers/utilities";


export const editableDataSelector = (state) => currentTemplateDataSelector(state).editableData;
export const editableElementDataSelector = (state, props) => editableDataSelector(state)[props.id];
export const editableIdKeySelector = (state, props) => editableElementDataSelector(state, props).idKey;
export const isVisibleElementSelector = (state, props) => {
    const editableElementData = editableElementDataSelector(state, props);

    return editableElementData && editableElementData.visibility && editableElementData.visibility.isVisible;
};
export const getEditableDataSelector = (state, props) => {
    const nodeData = nodeDataSelector(state, props);
    const el = props.ref.current;

    if (!el) {
        return {};
    }

    return {
        [EDITABLE_TYPES.image]: userCanEdit(nodeData, EDITABLE_TYPES.image) && getEditableImageValue(el, props.isNonBackgroundImage),
    };
};
export const editableIdSelector = createSelector(nodesDataSelector, (nodesData) => {
    let idArr = [];
    Object.values(nodesData).forEach((nodeData) => isEditableElement(nodeData) && idArr.push(nodeData.id));

    return idArr;
});
export const createEditableStyleSelector = () => {
    const currentNodeDataSelector = createNodeDataWithThemeSelector();

    return createSelector(currentNodeDataSelector, editableElementDataSelector, (nodeData, editableData) => {
        const nodeStyle = nodeData && nodeData.attributes.style || {};
        let editableStyle = {};

        if (editableData && editableData.image) {
        // if (editableData && editableData.image && !editableData.nonBackgroundImage === true) {
            const {image: {src, backgroundPosition, backgroundSize}} = editableData;

            editableStyle = {
                ...editableStyle,
                backgroundImage: src && src.indexOf('data:image') !== -1 ? '' : `url(${src})`,
                backgroundPosition: backgroundPosition && `${backgroundPosition.x * 100}% ${backgroundPosition.y * 100}%`,
                backgroundSize: `${(backgroundSize || 1) * 100}% auto`,
            };
        }

        return {
            ...nodeStyle,
            ...editableStyle,
        };
    });
};

/**
 * Gets all the siblings that are affected by the same thematic font size.
 * - The purpose of this is because when one of the siblings overflows its container and gets auto-scaled, we need all
 *   of its font size siblings to *also* resize, otherwise the design will look weird. (e.g. if one score gets smaller,
 *   it would look weird if the other score also didn't get smaller.)
 *
 * @param state
 * @param props
 * @returns {OutputSelector<unknown, unknown, (res1: unknown, res2: *) => unknown>}
 */
export const createFontSizeSiblingsSelector = (state, props) => {
    const currentNodeDataSelector = createNodeDataWithThemeSelector();

    return createSelector(currentNodeDataSelector, editableElementDataSelector, (nodeData, editableData) => {
        if (!editableData || !editableData.text || !nodeData || !nodeData.attributes || !nodeData.attributes.hasOwnProperty(EDITABLE_ATTRIBUTES.fontSize)) {
            return {};
        }

        const allEditableData = editableDataSelector(state);

        let toReturn = {
            nodeData: [],
            editableData: [],
            smallestNoOverflowFontScale: null,
            combinedTextData: null,
        };

        const fontSize = nodeData.attributes[EDITABLE_ATTRIBUTES.fontSize];
        const allNodeData = nodesDataSelector(state);
        const nodesWithSameFontSize = filter(allNodeData, nodeData => {
            return checkNested(nodeData, 'attributes', EDITABLE_ATTRIBUTES.fontSize) ? getNested(nodeData, 'attributes', EDITABLE_ATTRIBUTES.fontSize) === fontSize : false
        })
        toReturn.nodeData = nodesWithSameFontSize;

        let editableDataNodes = [];
        if(nodesWithSameFontSize && nodesWithSameFontSize.length){
            for(let i = 0; i < nodesWithSameFontSize.length; i++){
                const editableDataNode = allEditableData[nodesWithSameFontSize[i].id];
                if(editableDataNode){
                    editableDataNodes.push(editableDataNode)
                }
            }
        }
        toReturn.editableData = size(editableDataNodes) ? sortBy(editableDataNodes, ['id']) : [];

        //* Find out which of their overflow scales is the smallest so that all sibling elements can scale down to the scale of the smallest one
        let smallestNoOverflowFontScale = null;
        if(toReturn.editableData && toReturn.editableData.length){
            for(let i = 0; i < toReturn.editableData.length; i++){
                // const thisOverflowScale = checkNested(toReturn.editableData[i], 'text', 'no_overflow_font_scale') ? getNested(toReturn.editableData[i], 'text', 'no_overflow_font_scale') : undefined;
                const thisOverflowScale = checkNested(toReturn.editableData[i], 'text', 'adjustments', 'overflowScale') ? getNested(toReturn.editableData[i], 'text', 'adjustments', 'overflowScale') : undefined;
                if(typeof thisOverflowScale !== 'undefined'){
                    if(!smallestNoOverflowFontScale || Number(thisOverflowScale) < Number(smallestNoOverflowFontScale)){
                        smallestNoOverflowFontScale = roundToFive(Number(thisOverflowScale));
                    }
                }
            }
        }
        toReturn.smallestNoOverflowFontScale = smallestNoOverflowFontScale;

        //* Merge the text strings of all elements to so that we can get metrics based on their combined text
        // (this makes sure their metrics are the same in the event of things like one string having descenders and the other not)
        let combinedTextData = '';
        toReturn.editableData.forEach(item => {
            if(checkNested(item, 'text', 'value') && item.text.value){
                combinedTextData = `${combinedTextData}${item.text.value}`
            }
        })
        toReturn.combinedTextData = combinedTextData;

        return toReturn;
    });
};
