import { connect } from 'react-redux';

import Scalable from './component';

import { templateTypeSelector } from "../../selectors/template";

const mapStateToProps = (state) => ({
    templateType: templateTypeSelector(state),
});

export default connect(mapStateToProps)(Scalable);
