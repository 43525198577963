import { connect } from 'react-redux';

import ThemeForm from './component';

import { themeResetRequested, themeSaveRequested } from "../../../actions/interfaceActions";
import { themeFormPendingSelector } from "../../../selectors/theme";
import { colorNamesSelector, fontNamesSelector } from "../../../selectors/hasItems";

const mapStateToProps = (state) => ({
    pending: themeFormPendingSelector(state),
    colorNames: colorNamesSelector(state),
    fontNames: fontNamesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    handleResetTheme: () => dispatch(themeResetRequested()),
    handleSaveTheme: () => dispatch(themeSaveRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeForm);
