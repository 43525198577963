import { connect } from 'react-redux';

import ThemeForm from './component';

import {themeColorSwitchRequested, themeResetRequested} from "../../../actions/interfaceActions";
import { themeFormPendingSelector } from "../../../selectors/theme";
import {colorNamesSelector, fontNamesSelector, fontSizeNamesSelector} from "../../../selectors/hasItems";

const mapStateToProps = (state) => ({
    pending: themeFormPendingSelector(state),
    colorNames: colorNamesSelector(state),
    fontNames: fontNamesSelector(state),
    fontSizeNames: fontSizeNamesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    handleResetTheme: () => dispatch(themeResetRequested()),
    handleSwitchThemeColors: () => dispatch(themeColorSwitchRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeForm);
