import { UPDATE_ENTITIES, DELETE_ENTITY } from "../actions/stateModifierActions";
import merge from 'deepmerge';
import {forEach} from "lodash";

export default (state = {}, action)=>{
    switch (action.type) {
        case UPDATE_ENTITIES: {
            let newState = state;
            forEach(action.payload, (data, id) => {
                newState = {
                    ...newState,
                    [id]: data
                }
            });
            return newState;
            /* 7-29-21 -- TESTING COMMENTING THIS OUT
            Why: Previously, we were grabbing ALL of the user's uploads at once, so it made sense to merge new stuff with
            what was already there. But now, we're querying just for what we need (since the big-ass queries were breaking
            the app), so instead of merging them, we replace. The reason why is for things like search queries returning
            filtered results -- if we merge them, we'd have to do front-end filtering in addition to back-end filtering.
            This solution *does* create some inefficiencies though if we use pagination because if someone goes back a page,
            we are re-querying the backend. But it's quicker to launch this way, so going with it for now.
             */
            // return merge(state, action.payload);
        }
        case DELETE_ENTITY: {
            const {type, id} = action.payload;
            return {
                ...state,
                [type]: {
                    ...state[type],
                    [id]: {
                        ...state[type][id],
                        removed: true,
                    },
                },
            };
        }
        default: {
            return state;
        }
    }
};
