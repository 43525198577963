import React from 'react';
import PropTypes from 'prop-types';

import ColorPicker from "../../../../../../color_picker/js/color_picker";

import './styles.scss';

const maybeActiveClass = (swatch, currentValue) => {
  return swatch === currentValue ? 'active' : '';
};

const ColorFormField = (props) => {
    const { name, color, handleColorChange, label } = props;
    const displayLabel = label ? label : name;
    const isContrast = displayLabel && displayLabel.includes('ontrast');

    return(
        <div className="color-form-field mb-2">
            <div className="">{displayLabel}</div>
            {isContrast ? (
                <div className="d-inline-block">
                    <div className={`swatch bg-white d-inline-block mr-1 ml-2 ${maybeActiveClass('#FFFFFF', color)}`} onClick={() => handleColorChange({hex:'#FFFFFF'})} />
                    <div className={`swatch bg-black d-inline-block ${maybeActiveClass('#000000', color)}`} onClick={() => handleColorChange({hex: '#000000'})} />
                </div>
            ) : (
                <ColorPicker
                    controlledValue={color}
                    name={name}
                    onChange={handleColorChange}/>
            )}

        </div>
    );
};

ColorFormField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    color: PropTypes.string,
    handleColorChange: PropTypes.func.isRequired,
};

export default ColorFormField;
