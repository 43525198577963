import { render } from "react-dom";
import React from "react";
import ColorPicker from './color_picker';

const renderPack = ()=>{
  const inputs =  document.getElementsByClassName('color-picker-input');

  if(inputs.length) {
    [...inputs].forEach((input)=> {
      const newContainer = document.createElement("div");
      newContainer.classList.add("d-inline-block");
      newContainer.classList.add("align-middle");
      input.parentNode.replaceChild(newContainer, input);

      render(
        <ColorPicker value={input.value} name={input.getAttribute('name')}/>,
        newContainer
      );
    });
  }
};

document.addEventListener('turbolinks:load', renderPack);