import React from 'react';
import PropTypes from 'prop-types';

import humanize from '../../../helpers/humanize';

export default class ImageFormField extends React.Component {

    static propTypes = {
        dataCy: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        library: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        onUnFocus: PropTypes.func.isRequired,
        onCropClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        src: PropTypes.string,
        nonBackgroundImage: PropTypes.bool
    };

    handleMouseEnter = () => this.props.onFocus();

    handleMouseExit = () => this.props.onUnFocus();

    handleButtonClick = () => this.props.onClick();

    handleCropClick = () => this.props.onCropClick();

    renderCropButton() {
        const {disabled} = this.props;

        return (
            <button
                className="btn btn-outline-secondary ml-1"
                onClick={this.handleCropClick}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseExit}
                disabled={disabled}
            >
                Recrop
            </button>
        );
    }

    render() {
        const {name, src, disabled, dataCy, nonBackgroundImage} = this.props;
        const id = `${name}Field`;
        const isPlaceholder = src => !! src && src.length && src.indexOf('/placeholders/') !== -1;
        const croppable = !! src && src.length > 0 && !nonBackgroundImage && !isPlaceholder(src);

        return (
            <div className="form-group" data-cy={`${dataCy}-image`}>
                <div className="text-center text-sm-left">
                    <div className="d-flex justify-content-between align">
                        <div>
                            <button className="btn btn-outline-primary" id={id}
                                    onClick={this.handleButtonClick}
                                    onMouseEnter={this.handleMouseEnter}
                                    onMouseLeave={this.handleMouseExit}
                                    disabled={disabled}
                            >
                                Change Image
                                {/*{humanize(name)}*/}
                            </button>
                            {croppable && this.renderCropButton()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
