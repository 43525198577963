import { connect } from 'react-redux';

import ImageFormField from './component';

import * as ui from '../../../actions/interfaceActions';
import { editableSelected, editableFocused, editableUnfocused } from '../../../actions/templateActions';
import { editableElementDataSelector } from "../../../selectors/editableElement";

const mapStateToProps = (state, ownProps) => {
    const data = editableElementDataSelector(state, ownProps);
    const nonBackgroundImage = !! data && data.nonBackgroundImage === true;
    const image = data && data.image || {};

    return {
        src: image.src,
        library: image && image.library,
        userImageLibraryFolder: image && image.userImageLibraryFolder,
        nonBackgroundImage
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onClick: () => dispatch(editableSelected(ownProps.id)),
    onCropClick: () => dispatch(ui.imageFieldCropClicked(ownProps.id)),
    onFocus: () => dispatch(editableFocused(ownProps.id)),
    onUnFocus: () => dispatch(editableUnfocused(ownProps.id)),
    onValueChange: (value) => dispatch(ui.editableImageUpdated(ownProps.id, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageFormField);
