import { connect } from 'react-redux';

import Overlays from './component';

import { editableIdSelector } from '../../selectors/editableElement';

const mapStateToProps = (state) => ({
    editableId: editableIdSelector(state),
});

export default connect(mapStateToProps)(Overlays);
