import React, {Component} from 'react';
import { SketchPicker } from 'react-color';
import onClickOutside from "react-onclickoutside";
import * as PropTypes from "prop-types";

class Picker extends Component {

  static propTypes = {
    color: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  handleClickOutside(e) {
    const { onClose } = this.props;
    return onClose && onClose(e);
  }

  render() {
    const { color,  onChange} = this.props;

    return <SketchPicker disableAlpha={true} presetColors={[]} color={color} onChange={onChange}/>;
  }
}

const DecoratedPicker = onClickOutside(Picker);

class ColorPicker extends React.Component {

  static propTypes = {
    value: PropTypes.string,
    controlledValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  };

  state = {
    value: { hex: this.props.value },
    isEditing: false,
  };

  componentDidMount() {
    const { controlledValue } = this.props;

    return !controlledValue && this.handleChange(this.state.value);
  }

  derivedValue = () => {
    const { controlledValue } = this.props;
    const { value } = this.state;

    return controlledValue ? { hex: controlledValue } : value;
  };

  toggleEditing = (e) => {
    e.preventDefault();
    this.setState({
      isEditing: !this.state.isEditing
    });
  };

  handleChange = (value) => {
    const { onChange } = this.props;

    this.setState({value: value});
    return onChange && onChange(value);
  };

  render() {
    const { name } = this.props;
    const { isEditing } = this.state;
    const derivedValue = this.derivedValue();
    const hex = derivedValue && derivedValue.hex ? derivedValue.hex : '';

    return (
      <div style={{marginLeft: '20px'}}>
        <input type={'hidden'} name={name} value={hex} />
        <div style={{position: 'relative', display: 'inline-block'}}>
          <div onClick={this.toggleEditing} data-cy="color-picker" className="color-picker-display" style={{height: '20px', width: '20px', backgroundColor: (hex || 'white'), borderRadius: '50%', border: '2px solid #e3e3e3'}}/>
          {
            isEditing &&
            <div style={{position: 'absolute', right: '-20px', padding: '10px 0', zIndex: 9999}}>
              <DecoratedPicker onClose={this.toggleEditing} color={hex} onChange={this.handleChange}/>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default ColorPicker;
