export default (reducerType, reducer) => (state, action) => {
    const actionType = action && action.actionType;

    if (actionType === reducerType) {
        return reducer(state, action);
    }

    // initialize reducer
    if (!state) {
        return reducer(state, action);
    }

    return state;
};
