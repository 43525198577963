import React from 'react';
import PropTypes from 'prop-types';
import {startCase} from 'lodash';

import ColorFormField from '../ColorFormField/container';
import FontFormField from '../FontFormField/container';
import FontSizeFormField from "../FontSizeFormField/container";

import {isPresent} from "../../../../business/helpers/utilities";

import './styles.scss';
import {getDisplayTextForThemeColorOrFont} from "../../../helpers/labels";
import humanize from "../../../helpers/humanize";


const fontFieldsOutput = (fontNames) => {
    const fontFields = isPresent(fontNames) && fontNames.map((name) =>
        <FontFormField key={name} name={name} label={getDisplayTextForThemeColorOrFont(name)} />);

    if(fontFields){
        return (
            <div>
                <h6>Fonts</h6>
                { fontFields }
            </div>
        )
    }
}

const fontSizeFieldsOutput = (fontSizeNames) => {

    const fontSizeFields = isPresent(fontSizeNames) && fontSizeNames.map((name) => {
        // const label = humanize(name);
        const label = startCase(name);
        return <FontSizeFormField key={name} name={name} label={label} />
    })


    if(fontSizeFields){
        return (
            <div>
                <h6>Font Sizes</h6>
                { fontSizeFields }
            </div>
        )
    }
}

const ThemeForm = (props) => {
    const {handleResetTheme, handleSwitchThemeColors, fontNames, fontSizeNames} = props;

    return (
        <div data-cy="theme-form">
            <div className="sidebar-header">
                <h5>Theme Settings</h5>
                <hr />
            </div>

            { fontFieldsOutput(fontNames) }
            { fontSizeFieldsOutput(fontSizeNames)}
            <div>
                <hr />
                <h6>Want to switch the colors?</h6>
                <p>Click the button below to reverse the primary and secondary colors.</p>
                <button
                    className="btn btn-secondary w-100 d-block my-3 text-wrap"
                    type="button"
                    onClick={handleSwitchThemeColors}
                >
                    Flip Flop Theme Colors
                </button>

                <hr />
                <h6>Don&rsquo;t like what you did?</h6>
                <p>Click the button below to reset this template&rsquo;s theme to your account&rsquo;s default theme settings.</p>
                <button
                    className="btn btn-primary w-100 d-block my-3 text-wrap"
                    type="button"
                    onClick={handleResetTheme}
                >
                    Reset Template To Account Theme Settings
                </button>
            </div>
        </div>
    );
};

ThemeForm.propTypes = {
    pending: PropTypes.bool.isRequired,
    colorNames: PropTypes.arrayOf(PropTypes.string),
    fontNames: PropTypes.arrayOf(PropTypes.string),
    handleResetTheme: PropTypes.func.isRequired,
    handleSwitchThemeColors: PropTypes.func.isRequired,
};

export default ThemeForm;
