import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import EditableElementsForm from '../EditableElementsForm/container';
import ThemeForm from '../ThemeForm/container';
import AdvancedForm from '../AdvancedForm/container';

export default class TemplateForm extends React.Component {

    static propTypes = {
        hasTheme: PropTypes.bool.isRequired,
        hasEditable: PropTypes.bool.isRequired,
    };

    getContent() {
        const {activeTab} = this.props;
        const {hasTheme, hasEditable} = this.props;

        if (hasTheme && hasEditable) {
            switch(activeTab){
                case 'content':
                    return <EditableElementsForm/>;
                case 'theme':
                    return <ThemeForm/>;
                case 'advanced':
                    return <AdvancedForm/>;
                default:
                    return '';

            }
        }

        if (hasTheme) {
            return <ThemeForm/>;
        }

        if (hasEditable) {
            return <EditableElementsForm/>;
        }

        return <div>There is no editable content</div>;
    }

    _sidebarTab(slug, label, iconName) {
        const {activeTab, setActiveTab} = this.props;
        return (
            <div
                className={classNames('sidebar-link px-1 py-3', {active: activeTab === slug})}
                onClick={setActiveTab(slug)}
                data-cy={`${slug}-form-btn`}
            >
                <div className="inner flex-column align-items-center justify-content-center text-center">
                    <span className="icon d-block"><i className={`fal fa-${iconName}`} /></span>
                    <span className="text d-block mt-2">{label}</span>
                </div>
                <div className="background"></div>
            </div>
        );
    }

    render() {
        const {hasTheme, hasEditable} = this.props;

        return (
            <div className="full-height">
                <div className="sidebar-nav">
                    {hasTheme && hasEditable &&
                    <div className="items">
                        {this._sidebarTab('content', 'Content', 'text')}
                        {this._sidebarTab('theme', 'Theme', 'palette')}
                        {this._sidebarTab('advanced', 'Advanced', 'sliders-v-square')}
                    </div>
                    }
                </div>
                <div className="sidebar-content scrollable">
                    <div className="editor-form p-3" data-cy="editor-form">
                        {this.getContent()}
                    </div>
                </div>
            </div>
        );
    }
}
