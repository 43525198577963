import { connect } from 'react-redux';

import Overlay from './component';

import { highlightedSelector } from "../../selectors/overlay";
import { templateTypeSelector, rootIdKeySelector } from "../../selectors/template";
import { editableIdKeySelector } from "../../selectors/editableElement";
import { editableSelected } from "../../actions/templateActions";
import {isLegacyTemplateSelector} from "../../selectors/staticInfo";


const mapStateToProps = () => (state, ownProps) => ({
    templateType: templateTypeSelector(state),
    highlighted: highlightedSelector(state, ownProps),
    //to make sure it wil rerender on root and editable init
    editableIdKey: editableIdKeySelector(state, ownProps),
    rootIdKey: rootIdKeySelector(state),
    isLegacyTemplate: isLegacyTemplateSelector(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onClick: () => dispatch(editableSelected(ownProps.id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overlay);
