import axios from 'axios';

import { AUTH_TOKEN } from '../../config';
import { isPresent } from "../../../../business/helpers/utilities";

export const createTemplateSnapshot = ({ templateId, templateData, staticInfo }) => {
    const headers = {};
    const data = new FormData();
    data.append('authenticity_token', AUTH_TOKEN);

    const { isPublicTemplate, userId } = staticInfo;

    if (isPresent(templateData)) {
        data.append('template_snapshot[template_id]', templateId);
        data.append('template_snapshot[template_code]', JSON.stringify(templateData));
        data.append('template_snapshot[user_id]', userId);

        if(isPublicTemplate && isPublicTemplate === true){
            data.append('template_snapshot[is_public_template]', 'true');
        }
        else {
            data.append('template_snapshot[is_public_template]', 'false');
        }
    }

    return axios.post(`/template_snapshots`, data, headers);
};

export const createImageDownload = (html) => {
    const data = new FormData();
    data.append('authenticity_token', AUTH_TOKEN);
    const templateHtml = html;
    if (templateHtml) { data.append('template_html', templateHtml); }
    const headers = {};

    return axios.post(`/template_snapshots/download`, data, headers);
};
