import { takeEvery, put } from 'redux-saga/effects';
import * as business from '../actions/businessActions';
import * as stateModifiers from '../actions/stateModifierActions';

export default function*() {
    yield takeEvery(business.UPDATE_ENTITIES_REQUESTED,         function*(action){
        yield put(stateModifiers.updateEntities(action.payload));
    });
    yield takeEvery(business.DELETE_ENTITY_REQUESTED,           function*(action){ yield put(stateModifiers.deleteEntity(action.payload)); });
}
