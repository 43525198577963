import { takeEvery, put, select } from 'redux-saga/es/effects';

import * as ui from '../actions/interfaceActions';
import * as templateUi from '../actions/templateActions';
import * as stateModifier from '../actions/stateModifierActions';
import * as business from '../../business/actions/businessActions';
import * as templateBusiness from '../../business/actions/templateActions';
import { editableElementDataSelector } from '../selectors/editableElement';
import { isPresent } from "../../business/helpers/utilities";
import { templateTypeSelector } from "../selectors/template";
import {TEXT_VALIGN_OVERRIDE_CHANGED} from "../actions/interfaceActions";
import * as autosave from "../actions/autoSaveActions";

export default function* () {
    yield takeEvery(templateUi.TOGGLE_TEMPLATE_TYPE_CLICKED, function* (action) {
        yield put(autosave.templateAutosave());
        yield put(business.toggleTemplateTypeRequested(action.payload.templateName));
        yield put(ui.resetSidebarRequested());
    });
    yield takeEvery(ui.EDITABLE_TEXT_UPDATED, function* (action) {
        const {id, value} = action.payload;
        yield put(business.updateEditableTextRequested(id, value));
    });
    yield takeEvery(ui.TEXT_VALIGN_OVERRIDE_CHANGED, function* (action) {
        const {id, value} = action.payload;
        yield put(business.updateTextValignOverrideRequested(id, value));
    });
    yield takeEvery(ui.TEXT_VALIGN_OVERRIDE_RESET, function* (action) {
        const {id} = action.payload;
        yield put(business.resetTextValignOverrideRequested(id));
    });
    yield takeEvery(ui.EDITABLE_IMAGE_UPDATED, function* (action) {
        const {id, src, position, size, nonBackgroundImage} = action.payload;
        yield put(business.updateEditableImageRequested(id, src, position, size, nonBackgroundImage));
    });
    yield takeEvery(ui.EDITOR_INITIALIZED, function* () { yield put(business.editorInitializedRequested()); });
    yield takeEvery(templateUi.TEMPLATE_ROOT_ELEMENT_INITIALIZED, function* (action) { yield put(templateBusiness.templateRootElementInitializedRequested(action.payload.type, action.payload.ref)); });
    yield takeEvery(templateUi.TEMPLATE_ROOT_ELEMENT_UPDATE, function* (action) { yield put(templateBusiness.templateRootElementUpdateRequested(action.payload.type)); });
    yield takeEvery(templateUi.TEMPLATE_ROOT_ELEMENT_REMOVE, function* (action) { yield put(templateBusiness.templateRootElementRemoveRequested(action.payload.type)); });
    yield takeEvery(templateUi.TEMPLATE_EDITABLE_ELEMENT_INITIALIZED, function* (action) { yield put(templateBusiness.templateEditableElementInitializedRequested(action.payload.type, action.payload.id, action.payload.ref, action.payload.isNonBackgroundImage)); });
    yield takeEvery(templateUi.TEMPLATE_EDITABLE_ELEMENT_REMOVE, function* (action) { yield put(templateBusiness.templateEditableElementRemoveRequested(action.payload.type, action.payload.id)); });
    yield takeEvery(templateUi.EDITABLE_SELECTED, function* (action) {
        const {payload: {id}} = action;
        const {text, image, visibility} = yield select((state) => editableElementDataSelector(state, {id}));

        if (isPresent(visibility) && visibility.isVisible === false) { return; }

            yield put(stateModifier.unhighlightEditable());

        if (text) {
            yield put(ui.resetSidebarRequested()); // 1/23 Whenever we click to a text field from an image library, would need to be reset
            // TODO even with the reset code above, the text field wont get selected when going from a library
            yield put(stateModifier.focusTextField(id));
            return;
        }

        if (image) {
            if ('library' in image) {
                yield put(ui.requestCurrentImageLibrary(id, image.library, image.userImageLibraryFolder));
                return;
            }

            yield put(ui.imageFieldCropClicked(id));
            return;
        }

        console.error(`Editable Element Type not recognized.`);
    });
    yield takeEvery(templateUi.EDITABLE_UNSELECTED, function* () { yield put(stateModifier.unfocusTextField()); });
    yield takeEvery(templateUi.EDITABLE_FOCUSED, function* (action) {
        const type = yield select(templateTypeSelector);
        yield put(stateModifier.highlightEditable(action.payload.id));
    });
    yield takeEvery(templateUi.EDITABLE_UNFOCUSED, function* (action) {
        const type = yield select(templateTypeSelector);
        yield put(stateModifier.unhighlightEditable(action.payload.id));
    });
    yield takeEvery(templateUi.EDITABLE_TOGGLE_VISIBILITY, function* (action) { yield put(business.updateEditableVisibilityRequested(action.payload.id, action.payload.isVisible)); });
}
