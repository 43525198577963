import {arrToMap, createNormalizedNodeData, isPresent, zconsole} from "./utilities";
import { getEditableDataFromNodesData, mergeEditableId } from "./elementsData";
import { normalize } from "normalizr";
import { userFontList } from "../../services/mason/schema/userFont";
import {
    TEMPLATE_TYPES,
    INITIALIZED_TEMPLATE_DOM_TYPES_MAP,
    TEMPLATE_DOM_DATA_MAP,
    INITIALIZED_TEMPLATE_DOM_TYPES,
} from "../constants";
import { getUniqueId } from "./getId";
import { isEmpty, size } from 'lodash';

export const getDomTemplates = (data) => {
    let allDomArr = [];
    const typesArr = Object.values(TEMPLATE_TYPES);
    const parser = new DOMParser();

    typesArr.forEach((type) => {
        const templateKey = INITIALIZED_TEMPLATE_DOM_TYPES_MAP[type];
        const templateType = TEMPLATE_DOM_DATA_MAP[type];

        if (!isPresent(data[templateKey])) { return; }
        // if we have saved data already
        //TODO on update template need to reset saved data BE task
        if (data[templateType] && isPresent(data[templateKey]) && isPresent(data[templateType].nodeData)) { return; }

        const dom = parser.parseFromString(data[templateKey], "text/html");
        const reactTemplate = dom.getElementById('react-template');

        if (!dom || !reactTemplate) { return; }

        allDomArr.push({type: templateType, dom});
    });

    return allDomArr;
};

const getTemplateData = (data) => {
    let nodesDataList = [];
    const templates = getDomTemplates(data.business);

    templates.forEach(({type, dom}) => {
        const nodeData = createNormalizedNodeData(dom);

        if (!isPresent(nodeData)) { return; }

        nodesDataList.push({type, ...nodeData});
    });

    nodesDataList = mergeEditableId(nodesDataList);

    return nodesDataList.map((item) => {
        const editableDataList = getEditableDataFromNodesData(item.nodes);
        const editableDataMap = arrToMap(editableDataList);

        return {
            type: item.type,
            nodeData: item.nodes,
            editableData: editableDataMap,
            rootData: {
                ...Object.values(item.root)[0],
                idKey: getUniqueId(),
            },
        };
    });
};

const getThemeItem = (name) => (data, isDefaultTheme) => {
    const defaultTheme = data.business.defaultTheme;
    const compareName = name.toLowerCase();
    let result = {};

    for (const [itemName, itemValue] of Object.entries(defaultTheme)) {
        if (itemName.toLowerCase().indexOf(compareName) === -1) { continue; }

        result[itemName] = itemValue;

        // If this property is defined specifically for this template's theme, we should use it instead of the default theme.
        if(isDefaultTheme === false && data.business['theme'] && data.business.theme[itemName]){
            result[itemName] = data.business.theme[itemName]
        }
    }

    return result;
};

/**
 * Returns an object with the data that's different from the default theme.
 * This allows us to save ONLY the custom settings for this template that are actually customized.
 * @param themeData
 * @param defaultThemeData
 * @returns {{}}
 */
export const getCustomizedThemeDataVsDefault = (themeData, defaultThemeData) => {
    let newData = {};
    for (const [parentKey, parentObject] of Object.entries(themeData)) {
        if(!isEmpty(parentObject)){
            for (const [key, value] of Object.entries(parentObject)) {
                if(!isEmpty(defaultThemeData[parentKey]) && typeof defaultThemeData[parentKey][key] !== 'undefined'){
                    if(defaultThemeData[parentKey][key] !== value){
                        newData = {
                            ...newData,
                            [parentKey]: {
                                ...newData[parentKey],
                                [key]: value
                            }
                        }
                    }
                }
                else if(value){
                    newData = {
                        ...newData,
                        [parentKey]: {
                            ...newData[parentKey],
                            [key]: value
                        }
                    }
                }
            }
        }
    }

    return newData;
}

const getThemeColors = getThemeItem('color');
const getThemeFonts = getThemeItem('font');
const getThemeFontSizes = getThemeItem('fontSize');
const getTheme = (data, isDefaultTheme = true) => {
    const colors = getThemeColors(data, isDefaultTheme);
    const fonts = getThemeFonts(data, isDefaultTheme);
    const fontSizes = getThemeFontSizes(data, isDefaultTheme);

    return { colors, fonts, fontSizes };
};

//* Iterate through the set theme and default theme to merge the values for use as the theme.
const mergeThemeWithDefault = data => {
    const { theme, defaultTheme } = data.business;
    let result = { ...defaultTheme };
    for (const [itemName, itemValue] of Object.entries(theme)) {
        if(defaultTheme[itemName]){
            result[itemName] = {
                ...defaultTheme[itemName],
                ...itemValue
            };
        }
        else {
            result[itemName] = {
                ...itemValue
            }
        }
    }
    return result;
}

const getDefaultTemplateType = (storeData) => {
  for(const [templateType, domDataType] of Object.entries(TEMPLATE_DOM_DATA_MAP)) {
      if (!storeData.business[domDataType] || !isPresent(storeData.business[domDataType].nodeData)) { continue; }

      return templateType;
  }

  return '';
};

export const parseInitializeData = (data) => {
    // console.log('data before parsing was', JSON.parse(JSON.stringify(data)));
    let parsedData = {...data};
    const templatesData = getTemplateData(data);

    templatesData.forEach(({type, nodeData, editableData, rootData}) => {
        parsedData.business[type] = { nodeData, editableData, rootData };
    });

    parsedData.business.defaultTheme = getTheme(parsedData);
    parsedData.business.theme = mergeThemeWithDefault(parsedData);
    parsedData.business.templateType = getDefaultTemplateType(parsedData);
    parsedData.business.entities = normalize(data.business.fonts, userFontList).entities;

    Object.values(INITIALIZED_TEMPLATE_DOM_TYPES).forEach((type) => {
        delete parsedData.business[type];
    });
    delete parsedData.business.fonts;

    zconsole('parsed data', JSON.parse(JSON.stringify(parsedData))); // Force it to appropriately log given its current value

    return parsedData;
};

/**
 * Iterate through to default theme to see if anything's not null
 *
 * @param defaultThemeData
 * @returns {boolean}
 */
export const defaultThemeExists = defaultThemeData => {
    let toReturn = false;
    _.each(defaultThemeData, category => {
        if(_.isObject(category) && !_.isEmpty(category)){
            const nonNull = _.find(category, item => { return item !== null; });
            if(nonNull){
                toReturn = true;
                return false;
            }
        }
    });
    return toReturn;
}