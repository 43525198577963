export const TEMPLATE_TYPES = {
    rectangle: 'rectangle',
    square: 'square',
    vertical: 'vertical',
};

export const TEMPLATE_DOM_TYPES = {
    rectangle: 'rectangleDom',
    square: 'squareDom',
    vertical: 'verticalDom',
};

export const INITIALIZED_TEMPLATE_DOM_TYPES = {
    rectangle: 'rectangleTemplate',
    square: 'squareTemplate',
    vertical: 'verticalTemplate',
};

export const INITIALIZED_TEMPLATE_DOM_TYPES_MAP = {
    [TEMPLATE_TYPES.rectangle]: INITIALIZED_TEMPLATE_DOM_TYPES.rectangle,
    [TEMPLATE_TYPES.square]: INITIALIZED_TEMPLATE_DOM_TYPES.square,
    [TEMPLATE_TYPES.vertical]: INITIALIZED_TEMPLATE_DOM_TYPES.vertical,
};

export const TEMPLATE_DOM_TYPES_MAP = {
    [TEMPLATE_TYPES.rectangle]: TEMPLATE_DOM_TYPES.rectangle,
    [TEMPLATE_TYPES.square]: TEMPLATE_DOM_TYPES.square,
    [TEMPLATE_TYPES.vertical]: TEMPLATE_DOM_TYPES.vertical,
};

export const TEMPLATE_DOM_DATA_TYPES = {
    rectangle: 'rectangleTemplateData',
    square: 'squareTemplateData',
    vertical: 'verticalTemplateData',
};

export const TEMPLATE_DOM_DATA_MAP = {
    [TEMPLATE_TYPES.rectangle]: TEMPLATE_DOM_DATA_TYPES.rectangle,
    [TEMPLATE_TYPES.square]: TEMPLATE_DOM_DATA_TYPES.square,
    [TEMPLATE_TYPES.vertical]: TEMPLATE_DOM_DATA_TYPES.vertical,
};

export const TEMPLATE_AUTOSAVE_TIMER = 10000;
