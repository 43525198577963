import { takeEvery, put } from 'redux-saga/effects';

//TODO get specs about imports to impost it like '* as ...' or '{ action }'
import * as endpoints from './endpoints';
import * as mason_template_snapshot from './actions';
import * as business from '../../../../business/actions/businessActions';

//TODO get back if needed
// const extractErrors = (response) => response.data.errors;

export default function* () {
    yield takeEvery(mason_template_snapshot.CREATE_TEMPLATE_SNAPSHOT_REQUESTED, function* (action) {
        try {
            const response = yield endpoints.createTemplateSnapshot(action.payload);
            yield put(mason_template_snapshot.createTemplateSnapshotRequestSucceeded(response));
        } catch (error) {
            console.log('template snapshot error: ', error);
            yield put(mason_template_snapshot.createTemplateSnapshotRequestFailed(error));
        }
    });
    yield takeEvery(mason_template_snapshot.CREATE_TEMPLATE_SNAPSHOT_REQUEST_SUCCEEDED, function* (action) { yield put(business.createTemplateSnapshotSucceeded(action.payload.response)); });
    yield takeEvery(mason_template_snapshot.CREATE_TEMPLATE_SNAPSHOT_REQUEST_FAILED, function* (action) { yield put(business.createTemplateSnapshotFailed(action.payload.error)); });
}
