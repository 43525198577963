import React from 'react';
import PropTypes from 'prop-types';

import humanize from "../../../helpers/humanize";
import ElementVisibilityToggle from "../ElementVisibilityToggle/container";

export const VisibilityFormFieldComponent = (props) => {
    const {name, id, dataCy} = props;

    return (
        <div className="form-group" data-cy={`${dataCy}-visibility`}>
            <div>
                <div className="d-flex mb-2 align-items-center">
                    <div className="mr-2">
                        {/*<small>{humanize(name)}</small>*/}
                        <small>Show/Hide:</small>
                    </div>
                    <div>
                        <ElementVisibilityToggle id={id} />
                    </div>
                </div>
            </div>
        </div>
    );
};

VisibilityFormFieldComponent.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    dataCy: PropTypes.string.isRequired,
    onFocus: PropTypes.func,
    onUnFocus: PropTypes.func,
};

export default VisibilityFormFieldComponent;
