import { createSelector } from 'reselect';
import { camelCase, forEach } from 'lodash';

import { EDITABLE_ATTRIBUTES } from "../constants";
import { TEMPLATE_DOM_DATA_MAP, TEMPLATE_DOM_DATA_TYPES, TEMPLATE_TYPES } from "../../business/constants";
import {isPresent, parseStyleProperties, zconsole} from "../../business/helpers/utilities";
import {
    themeFontsSelector,
    themeActiveColorsSelector,
    themeActiveFontsIdSelector,
    themeActiveFontSizesSelector
} from "./theme";
import { updateStyleInNode } from "./utilities";

export const templateDataSelector = (state, type) => {
    return state.business[TEMPLATE_DOM_DATA_MAP[type]] || {};
}
export const currentTemplateDataSelector = (state) => templateDataSelector(state, templateTypeSelector(state));
export const templateNameSelector = (state) => state.business.templateName;
export const templateTypeSelector = (state) => state.business.templateType;
export const templateIdSelector = (state) => state.business.templateId;
export const staticInfoSelector = (state) => state.business.staticInfo;
export const nodesDataSelector = (state) => currentTemplateDataSelector(state).nodeData;
export const nodeDataSelector = (state, props) => nodesDataSelector(state)[props.id];
export const rootDataSelector = (state, props) => {
    const type = props && props.templateType || templateTypeSelector(state);

    return state.business[TEMPLATE_DOM_DATA_MAP[type]].rootData;
};
export const rootIdKeySelector = (state) => rootDataSelector(state).idKey;
export const hasDomDataSelector = (state) => isPresent(nodesDataSelector(state));
export const createNodeDataWithThemeSelector = () => createSelector(
    nodeDataSelector,
    themeActiveColorsSelector,
    themeFontsSelector,
    themeActiveFontsIdSelector,
    themeActiveFontSizesSelector,
    (node, activeThemeColors, themeFonts, activeThemeFonts, activeThemeFontSizes) => {
        if (!isPresent(node)) {
            return {};
        }

        let currNode = {...node};
        const {attributes} = currNode;

        if (activeThemeColors) {
            // console.log('there are activeThemeColors and they are: ', activeThemeColors);
            //* Loop through the active theme colors and look for a matching customizable property on this element that matches it.
            for (const [colorName, colorValue] of Object.entries(activeThemeColors)) {


                const colorStyle = {};

                //* Used for overriding multiple things on one element:
                const colorOverridesAttr = attributes && attributes[EDITABLE_ATTRIBUTES.colorOverrides];

                //* If the colorOverrides one is specified, use that instead of the legacy ones
                if(colorOverridesAttr && colorOverridesAttr.length){
                    let colorOverrides = false;
                    try {
                        colorOverrides = JSON.parse(colorOverridesAttr);
                    } catch (e) {
                        colorOverrides = false;
                    }
                    if(colorOverrides){
                        // TODO I notice that this fires a whoooooolllee lot when checking a color in here and might be a good opportunity for code optimization

                        // console.log('colorOverrides', colorOverrides);
                        for (const [thisColorName, thisColorValue] of Object.entries(colorOverrides)) {
                            if (colorName !== thisColorName || !thisColorValue || !Array.isArray(thisColorValue)) {
                                continue;
                            }

                            // const recoloredCssProperties = parseStyleProperties(thisColorValue);
                            thisColorValue.forEach((propName) => colorStyle[propName] = colorValue);
                        }
                    }
                }

                //* If there's nos setting for color overrides, use the old style key/value ones
                else {
                    //* (Legacy) essentially a key/value pair for overriding just one attribute on an element
                    const colorAttr = attributes && camelCase(attributes[EDITABLE_ATTRIBUTES.color]);
                    const colorPropertyAttr = attributes && attributes[EDITABLE_ATTRIBUTES.colorProperty];
                    if (colorName !== colorAttr || !colorPropertyAttr) {
                        continue;
                    }

                    // Get an array of the css properties that we need to style
                    const recoloredCssProperties = parseStyleProperties(colorPropertyAttr);
                    //TODO (From Dima/Laurie) - use immutable js

                    recoloredCssProperties.forEach((propName) => colorStyle[propName] = colorValue);
                }

                currNode = updateStyleInNode(currNode, colorStyle);
            }

            // Pulled from https://dev.to/qausim/convert-html-inline-styles-to-a-style-object-for-react-components-2cbi
            const getStyleObjectFromString = str => {
                const style = {};
                str.split(";").forEach(el => {
                    const [property, value] = el.split(":");
                    if (!property) return;

                    const formattedProperty = camelCase(property.trim());
                    style[formattedProperty] = value.trim();
                });

                return style;
            };

            //* Supercede & merge with custom inline styles
            const customInlineStyleAttr = attributes && attributes[EDITABLE_ATTRIBUTES.customInlineStyle];
            if(customInlineStyleAttr && customInlineStyleAttr.length){
                let strReplacedInlineStyleAttr = customInlineStyleAttr;

                function hexToRgb(hex) {
                    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                    return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null;
                    // return result ? {
                    //     r: parseInt(result[1], 16),
                    //     g: parseInt(result[2], 16),
                    //     b: parseInt(result[3], 16)
                    // } : null;
                }

                let activeThemeColorsRGB = [];
                forEach(activeThemeColors, (value, key) => {
                    if(value){
                        activeThemeColorsRGB = {
                            ...activeThemeColorsRGB,
                            [`${key}Rgb`]: hexToRgb(value)
                        }
                    }
                });

                for (const [colorName, colorValue] of Object.entries(activeThemeColors)) {
                    strReplacedInlineStyleAttr = strReplacedInlineStyleAttr.replaceAll('${' + colorName + '}', colorValue);
                }

                for (const [colorName, colorValue] of Object.entries(activeThemeColorsRGB)) {
                    strReplacedInlineStyleAttr = strReplacedInlineStyleAttr.replaceAll('${' + colorName + '}', colorValue);
                }
                currNode = updateStyleInNode(currNode, getStyleObjectFromString(strReplacedInlineStyleAttr));
            }
        }



        if (themeFonts && activeThemeFonts) {
            for (const [fontLabel, fontId] of Object.entries(activeThemeFonts)) {
                const fontData = themeFonts[fontId];

                if (!fontData) {
                    continue;
                }

                const {attributes} = currNode;
                const fontAttr = attributes && camelCase(attributes[EDITABLE_ATTRIBUTES.font]);

                if (fontLabel !== fontAttr) {
                    continue;
                }

                // if(attributes && attributes['data-name'] === 'team-one-name') {
                //     console.log('font data is', fontData);
                // }

                const fontStyle = {
                    fontFamily: fontData.name,
                    fontGlobalOffset: typeof fontData.global_offset !== 'undefined' ? fontData.global_offset : undefined,
                    fontGlobalScale: typeof fontData.global_scale !== 'undefined' ? fontData.global_scale : undefined
                };

                //TODO use immutable js
                currNode = updateStyleInNode(currNode, fontStyle);
            }
        }

        if(activeThemeFontSizes && typeof activeThemeFontSizes === 'object'){
            for (let [fontSizeLabel, fontSize] of Object.entries(activeThemeFontSizes)) {
                fontSize = typeof fontSize !== 'undefined' && fontSize ? fontSize : 0;
                const {attributes} = currNode;
                const fontSizeAttr = attributes && camelCase(attributes[EDITABLE_ATTRIBUTES.fontSize]);

                if (fontSizeLabel !== fontSizeAttr) {
                    continue;
                }

                const fontStyle = {
                    fontSizeScale: fontSize,
                };

                //TODO use immutable js
                currNode = updateStyleInNode(currNode, fontStyle);
            }
        }

        return currNode;
    });


export const allTemplateDataSelector = (state) => {
    let data = {};

    Object.values(TEMPLATE_TYPES).forEach((type) => {
        const templateData = templateDataSelector(state, type);

        if (!isPresent(templateData) || !isPresent(templateData.nodeData)) { return; }

        data[type] = templateDataSelector(state, type);
    });

    return data;
};

export const currentTemplateTypeSelector = (state) => state.business.templateType;
export const currentTemplateSelector = (state) => state.business[TEMPLATE_DOM_DATA_TYPES[state.business.templateType]];
export const rectangleTemplateSelector = (state) => state.business[TEMPLATE_DOM_DATA_TYPES.rectangle];
export const squareTemplateSelector = (state) => state.business[TEMPLATE_DOM_DATA_TYPES.square];
export const verticalTemplateSelector = (state) => state.business[TEMPLATE_DOM_DATA_TYPES.vertical];
export const hasRectangleTemplateSelector = (state) => isPresent(rectangleTemplateSelector(state).nodeData);
export const hasSquareTemplateSelector = (state) => isPresent(squareTemplateSelector(state).nodeData);
export const hasVerticalTemplateSelector = (state) => isPresent(verticalTemplateSelector(state).nodeData);
// it is disgustingly hardcode due to the performance
export const allAvailableTemplateTypesSelector = createSelector(hasRectangleTemplateSelector, hasSquareTemplateSelector, hasVerticalTemplateSelector, (rectangleDom, squareDom, verticalDom) => {
    let list = [];

    if (rectangleDom) {
        list.push(TEMPLATE_TYPES.rectangle);
    }

    if (squareDom) {
        list.push(TEMPLATE_TYPES.square);
    }

    if (verticalDom) {
        list.push(TEMPLATE_TYPES.vertical);
    }

    return list;
});
