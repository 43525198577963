import { connect } from 'react-redux';
import UploadForm from './component';
import * as ui from '../../actions/interfaceActions';
import {previousSidebarPageSelector} from "../../selectors/sidebar";

const mapStateToProps = (state) => {

    //* NOTE: The below code only works if this upload form is nested as an item in the sidebar stack after the library. If we were to have it in a modal or something, this would need to change.
    const parentSidebarPage = previousSidebarPageSelector(state);
    const user_image_library_folder = parentSidebarPage && typeof parentSidebarPage.contentProps.userImageLibraryFolder !== 'undefined' ? parentSidebarPage.contentProps.userImageLibraryFolder : null;

    return {
        pending: !!state.ui.forms.userUpload,
        user_image_library_folder
    }
};

const mapDispatchToProps = (dispatch) => ({
    onSubmit: ({name, file, user_image_library_folder}) => dispatch(ui.userUploadFormSubmitted({name, file, user_image_library_folder})),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadForm);
