export const START_DOWNLOAD = 'BUSINESS:STATE_MODIFIER:START_DOWNLOAD';
export const startDownload = () => ({
    type: START_DOWNLOAD,
});

export const STOP_DOWNLOAD = 'BUSINESS:STATE_MODIFIER:STOP_DOWNLOAD';
export const stopDownload = () => ({
    type: STOP_DOWNLOAD,
});

export const TOGGLE_TEMPLATE_TYPE = 'BUSINESS:STATE_MODIFIER:TOGGLE_TEMPLATE_TYPE';
export const toggleTemplateType = (templateName) => ({
    type: TOGGLE_TEMPLATE_TYPE,
    payload: { templateName },
});

export const UPDATE_ENTITIES = 'BUSINESS:STATE_MODIFIER:UPDATE_ENTITIES';
export const updateEntities = (entities) => {
    return {
        type: UPDATE_ENTITIES,
        payload: entities
    }
};

export const DELETE_ENTITY = 'BUSINESS:STATE_MODIFIER:DELETE_ENTITY';
export const deleteEntity = (entity) => {
    return {
        type: DELETE_ENTITY,
        payload: entity
    }
};

export const UPDATE_TEMPLATE_EDITABLE_IMAGE = 'BUSINESS:STATE_MODIFIER:UPDATE_TEMPLATE_EDITABLE_IMAGE';
export const updateTemplateEditableImage = (id, src, actionType, nonBackgroundImage) => {
    return {
        type: UPDATE_TEMPLATE_EDITABLE_IMAGE,
        payload: { id, src, nonBackgroundImage },
        actionType,
    }
};

export const UPDATE_TEMPLATE_EDITABLE_IMAGE_CROP = 'BUSINESS:STATE_MODIFIER:UPDATE_TEMPLATE_EDITABLE_IMAGE_CROP';
export const updateTemplateEditableImageCrop = (id, position, size, actionType) => {
    return {
        type: UPDATE_TEMPLATE_EDITABLE_IMAGE_CROP,
        payload: { id, position, size },
        actionType,
    }
};

export const UPDATE_TEMPLATE_EDITABLE_TEXT = 'BUSINESS:STATE_MODIFIER:UPDATE_TEMPLATE_EDITABLE_TEXT';
export const updateTemplateEditableText = (id, value, actionType) => ({
    type: UPDATE_TEMPLATE_EDITABLE_TEXT,
    payload: { id, value },
    actionType,
});

export const UPDATE_TEXT_VALIGN_OVERRIDE = 'BUSINESS:STATE_MODIFIER:UPDATE_TEXT_VALIGN_OVERRIDE';
export const updateTextValignOverride = (id, value, actionType) => ({
    type: UPDATE_TEXT_VALIGN_OVERRIDE,
    payload: { id, value },
    actionType,
});

export const UPDATE_TEXT_FUNDAMENTAL_FONT_SCALE = 'BUSINESS:STATE_MODIFIER:UPDATE_TEXT_FUNDAMENTAL_FONT_SCALE';
export const updateTextFundamentalFontScale = (id, value, actionType) => ({
    type: UPDATE_TEXT_FUNDAMENTAL_FONT_SCALE,
    payload: { id, value },
    actionType,
});

export const UPDATE_TEXT_ADJUSTMENTS_WITHOUT_OVERFLOW_FONT_SCALE = 'BUSINESS:STATE_MODIFIER:UPDATE_TEXT_ADJUSTMENTS_WITHOUT_OVERFLOW_FONT_SCALE';
export const updateTextAdjustmentsWithoutOverflowFontScale = (id, value, actionType) => ({
    type: UPDATE_TEXT_ADJUSTMENTS_WITHOUT_OVERFLOW_FONT_SCALE,
    payload: { id, value },
    actionType,
});

export const UPDATE_TEXT_NO_OVERFLOW_FONT_SCALE = 'BUSINESS:STATE_MODIFIER:UPDATE_TEXT_NO_OVERFLOW_FONT_SCALE';
export const updateTextNoOverflowFontScale = (id, value, actionType) => ({
    type: UPDATE_TEXT_NO_OVERFLOW_FONT_SCALE,
    payload: { id, value },
    actionType,
});

export const UPDATE_TEXT_METRICS = 'BUSINESS:STATE_MODIFIER:UPDATE_TEXT_METRICS';
export const updateTextMetrics = (id, value, actionType) => ({
    type: UPDATE_TEXT_METRICS,
    payload: { id, value },
    actionType,
});

export const UPDATE_TEMPLATE_EDITABLE_VISIBILITY = 'BUSINESS:STATE_MODIFIER:UPDATE_TEMPLATE_EDITABLE_VISIBILITY';
export const updateEditableVisibility = (id, isVisible, actionType) => ({
    type: UPDATE_TEMPLATE_EDITABLE_VISIBILITY,
    payload: { id, isVisible },
    actionType,
});

export const UPDATE_ROOT_DATA = 'BUSINESS:STATE_MODIFIER:UPDATE_ROOT_DATA';
export const updateRootData = (rootData, actionType) => ({
    type: UPDATE_ROOT_DATA,
    payload: { rootData },
    actionType,
});

export const UPDATE_ROOT_DATA_KEY = 'BUSINESS:STATE_MODIFIER:UPDATE_ROOT_DATA_KEY';
export const updateRootDataKey = (actionType) => ({
    type: UPDATE_ROOT_DATA,
    actionType,
});

export const UPDATE_EDITABLE_DATA = 'BUSINESS:STATE_MODIFIER:UPDATE_EDITABLE_DATA';
export const updateEditableData = (id, editableDataItem, actionType) => ({
    type: UPDATE_EDITABLE_DATA,
    payload: { id, editableDataItem },
    actionType,
});

export const UPDATE_EDITABLE_ELEMENT_POSITION = 'BUSINESS:STATE_MODIFIER:UPDATE_EDITABLE_ELEMENT_POSITION';
export const updateEditableElementPosition = (id, position, actionType) => ({
    type: UPDATE_EDITABLE_ELEMENT_POSITION,
    payload: { id, position },
    actionType,
});

export const UPDATE_THEME_COLOR = 'BUSINESS:STATE_MODIFIER:UPDATE_THEME_COLOR';
export const updateThemeColor = (themeColorName, value) => ({
    type: UPDATE_THEME_COLOR,
    payload: { themeColorName, value },
});

export const UPDATE_THEME_FONT = 'BUSINESS:STATE_MODIFIER:UPDATE_THEME_FONT';
export const updateThemeFont = (themeFontName, value) => ({
    type: UPDATE_THEME_FONT,
    payload: { themeFontName, value },
});

export const UPDATE_THEME_FONT_SIZE = 'BUSINESS:STATE_MODIFIER:UPDATE_THEME_FONT_SIZE';
export const updateThemeFontSize = (themeFontSizeName, value, actionType) => ({
    type: UPDATE_THEME_FONT_SIZE,
    payload: { themeFontSizeName, value },
    actionType
});

export const SWITCH_THEME_COLORS = 'BUSINESS:STATE_MODIFIER:SWITCH_THEME_COLORS';
export const switchThemeColors = (newColors) => ({
    type: SWITCH_THEME_COLORS,
    payload: { newColors },
});

// Used when saving a theme so that this template's current theme is not different from the default theme.
export const UPDATE_DEFAULT_THEME = 'BUSINESS:STATE_MODIFIER:UPDATE_DEFAULT_THEME';
export const updateDefaultTheme = (newTheme) => ({
    type: UPDATE_DEFAULT_THEME,
    payload: { newTheme },
});
