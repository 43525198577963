/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
require('jquery')
import 'bootstrap';
import '../application/scss/index.scss';
import '../application/js/index';
import '../image_editor/scss/index.scss';
import '../image_editor/js/index';
import '../color_picker/scss/index.scss';
import '../color_picker/js/index';
import '../user_font_configurator/scss/index.scss';
import '../user_font_configurator/js/index';
import '../user_color_configurator/scss/index.scss';
import '../user_color_configurator/js/index';