export const UPDATE_TEMPLATE_THEME_REQUESTED = 'SERVICE:MASON:TEMPLATE_THEME:UPDATE_REQUESTED';
export const updateTemplateThemeRequested = (templateId, theme, staticInfo) => ({
    type: UPDATE_TEMPLATE_THEME_REQUESTED,
    payload: { templateId, theme, staticInfo },
});

export const UPDATE_TEMPLATE_THEME_REQUEST_SUCCEEDED = 'SERVICE:MASON:TEMPLATE_THEME:UPDATE_REQUEST_SUCCEEDED';
export const updateTemplateThemeRequestSucceeded = (response) => ({
    type: UPDATE_TEMPLATE_THEME_REQUEST_SUCCEEDED,
    payload: { data: response.data },
});

export const UPDATE_TEMPLATE_THEME_REQUEST_FAILED = 'SERVICE:MASON:TEMPLATE_THEME:UPDATE_REQUEST_FAILED';
export const updateTemplateThemeRequestFailed = (error) => ({
    type: UPDATE_TEMPLATE_THEME_REQUEST_FAILED,
    payload: { error },
});
