import {editableElementDataSelector} from "./editableElement";
import {checkNested} from "../../business/helpers/utilities";

export const focusedFieldSelector = (state) => state.ui.formTextField.focused;
export const isFocusedSelector = (state, props) => {
    return focusedFieldSelector(state) === props.id;
};

export const valignValueSelector = (state,props) => {
    const thisElement = editableElementDataSelector(state,props);
    if(checkNested(thisElement, 'text', 'vertical_align_override')){
        return Number(thisElement.text.vertical_align_override);
    }
    return 0;
}
