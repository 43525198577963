import { connect } from 'react-redux';
import ImageLibrary from './component';
import * as ui from '../../actions/interfaceActions';
import libraryImages, {userLibraryImageCount} from '../../selectors/libraryImages';

const mapStateToProps = (state, ownProps) => ({
    images: libraryImages(state, ownProps.libraryCode),
    imageCount: userLibraryImageCount(state, ownProps.libraryCode)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onLibraryMount: (code, userImageLibraryFolder, page, per_page) => dispatch(ui.getLibraryImages(code, userImageLibraryFolder, page, per_page)),
    queryLibraryImages: (code, userImageLibraryFolder, page, per_page, search_term) => dispatch(ui.getLibraryImages(code, userImageLibraryFolder, page, per_page, search_term)),
    onNewUploadButtonClicked: () => dispatch(ui.newUploadButtonClicked(ownProps.id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageLibrary);
