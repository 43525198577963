import React from 'react';
import PropTypes from 'prop-types';

export default class TextArea extends React.Component {

    static propTypes = {
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        controlledFocus: PropTypes.bool,
        value: PropTypes.string,
    };

    static defaultProps = {
        onChange() {},
        onFocus() {},
        onBlur() {}
    };

    state = {
        isFocused: false,
        currentValue: this.props.value
    };

    input = React.createRef();

    handleChange = e => {
        this.setState({currentValue: e.target.value});
        this.props.onChange(e);
    };

    handleFocus = e => {
        this.setState({isFocused: true});
        this.props.onFocus(e);
    };

    handleBlur = e => {
        this.setState({isFocused: false});
        this.props.onBlur(e);
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.isFocused) {
            return;
        }

        this.setState({currentValue: nextProps.value});
    }

    componentDidUpdate(prevProps) {
        const {controlledFocus} = this.props;

        if (!controlledFocus || !this.input) {
           return;
        }

        this.input.current.focus();
    }

    filteredProps() {
        const filteredProps = {...this.props};
        delete filteredProps.controlledFocus;
        return filteredProps;
    }

    render() {
        const currentFilteredProps = this.filteredProps();

        return <textarea
            {...currentFilteredProps}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            value={this.state.currentValue}
            ref={this.input}
            rows={6}
        />;
    }
}
