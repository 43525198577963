export const CREATE_TEMPLATE_SNAPSHOT_REQUESTED = 'SERVICE:MASON:TEMPLATE_SNAPSHOT:CREATE_REQUESTED';
export const createTemplateSnapshotRequested = (templateId, templateData, staticInfo) => ({
    type: CREATE_TEMPLATE_SNAPSHOT_REQUESTED,
    payload: { templateId, templateData, staticInfo },
});

export const CREATE_TEMPLATE_SNAPSHOT_REQUEST_SUCCEEDED = 'SERVICE:MASON:TEMPLATE_SNAPSHOT:CREATE_REQUEST_SUCCEEDED';
export const createTemplateSnapshotRequestSucceeded = (response) => ({
    type: CREATE_TEMPLATE_SNAPSHOT_REQUEST_SUCCEEDED,
    payload: { response },
});

export const CREATE_TEMPLATE_SNAPSHOT_REQUEST_FAILED = 'SERVICE:MASON:TEMPLATE_SNAPSHOT:CREATE_REQUEST_FAILED';
export const createTemplateSnapshotRequestFailed = (error) => ({
    type: CREATE_TEMPLATE_SNAPSHOT_REQUEST_FAILED,
    payload: { error },
});
