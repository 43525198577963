import { connect } from 'react-redux';

import TemplateForm from './component';

import { hasThemeElementsSelector, hasEditableElementsSelector } from "../../../selectors/hasItems";

const mapStateToProps = (state) => ({
    hasTheme: hasThemeElementsSelector(state),
    hasEditable: hasEditableElementsSelector(state),
});

export default connect(mapStateToProps)(TemplateForm);
