import React from 'react';
import PropTypes from 'prop-types';

import ColorFormField from '../ColorFormField/container';
import FontFormField from '../FontFormField/container';

import { isPresent } from "../../../../business/helpers/utilities";

import './styles.scss';
import {getDisplayTextForThemeColorOrFont} from "../../../helpers/labels";

//TODO enable to cancel saving

const ThemeForm = (props) => {
    const {handleResetTheme, handleSaveTheme, pending, colorNames, fontNames} = props;

    const colorFields = isPresent(colorNames) && colorNames.map((name) => {
        return <ColorFormField key={name} name={name} label={getDisplayTextForThemeColorOrFont(name)} />;
    });

    const confirmOverRideTheme = () => {
        if(window.confirm('Are you sure to override your account-wide theme with the settings from this template? This will change the theme for ALL of your other templates that are not individually customized.')){
            handleSaveTheme();
        }
    }

    return (
        <div data-cy="advanced-form">
            <div className="sidebar-header">
                <h5>Advanced Settings</h5>
                <p>We sure hope you know what you&rsquo;re doing!</p>
                <hr />
            </div>
            { colorFields }
            <div>
                <hr />
                <h6>Don&rsquo;t like what you did?</h6>
                <p>Click the button below to reset this template&rsquo;s theme to your account&rsquo;s default theme settings.</p>
                <button
                    className="btn btn-secondary w-100 d-block my-3 text-wrap"
                    type="button"
                    onClick={handleResetTheme}
                >
                    Reset Template To Account Theme Settings
                </button>


                <hr />
                <h6>Want to save these settings as the new default?</h6>
                <p>Click the button below to <strong><em>overwrite</em></strong> your account&rsquo;s default theme settings with this template&rsquo;s theme.</p>
                <button
                    className="btn btn-primary w-100 d-block text-wrap"
                    type="button"
                    onClick={confirmOverRideTheme}
                    disabled={pending}
                >
                    <span className={!pending ? 'd-none': ''}>Saving... <i className="fa fa-refresh spinner-icon"/></span>
                    <span className={pending ? 'd-none': ''}>Override Account-Wide Theme Settings with This Template&apos;s Settings</span>
                </button>
            </div>
        </div>
    );
};

ThemeForm.propTypes = {
    pending: PropTypes.bool.isRequired,
    colorNames: PropTypes.arrayOf(PropTypes.string),
    fontNames: PropTypes.arrayOf(PropTypes.string),
    handleResetTheme: PropTypes.func.isRequired,
    handleSaveTheme: PropTypes.func.isRequired,
};

export default ThemeForm;
