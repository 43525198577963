import { connect } from 'react-redux';

import TemplateFormField from './component';

import { editableElementDataSelector } from "../../../selectors/editableElement";

const mapStateToProps = (state, ownProps) => ({
    editableElementData: editableElementDataSelector(state, ownProps),
});

export default connect(mapStateToProps)(TemplateFormField);
