import React from 'react';
import PropTypes from 'prop-types';
import TemplateConstructor from '../TemplateConstructor/container';
import {isPresent, isSingleDomElement} from "../../../../business/helpers/utilities";

export default class TemplateElement extends React.Component {
    static propTypes = {
        nodeData: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            attributes: PropTypes.object,
            children: PropTypes.arrayOf(PropTypes.string),
        }),
    };

    element = React.createRef();

    render(){
        const {nodeData: {attributes, name, children}} = this.props;
        let style = attributes && typeof attributes.style !== 'undefined' && attributes.style ? {...attributes.style} : {};
        const CustomTagName = `${name}`;
        const childNodes = isPresent(children) && children.map((id) => <TemplateConstructor key={id} id={id} parentRef={this.element}/>);

        if (isSingleDomElement(name)) {
            return (<CustomTagName {...attributes} style={style} ref={this.element} />);
        }

        return (
            <CustomTagName {...attributes} style={style} ref={this.element}>
                {childNodes}
            </CustomTagName>
        );
    }
}