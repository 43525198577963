import { connect } from 'react-redux';

import TextFromField from './component';

import {
    editableTextUpdated,
    textValignOverrideChanged,
    textValignOverrideReset
} from "../../../actions/interfaceActions";
import { editableFocused, editableUnfocused, editableSelected, editableUnselected } from "../../../actions/templateActions";
import {isFocusedSelector, valignValueSelector} from "../../../selectors/formTextField";
import {isLegacyTemplateSelector} from "../../../selectors/staticInfo";
import {nodeDataSelector} from "../../../selectors/template";

const mapStateToProps = (state, ownProps) => ({
    isFocused: isFocusedSelector(state, ownProps),
    valignValue: valignValueSelector(state, ownProps),
    isLegacyTemplate: isLegacyTemplateSelector(state),
    nodeData: nodeDataSelector(state, ownProps)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onFocus: () => dispatch(editableFocused(ownProps.id)),
    onUnFocus: () => dispatch(editableUnfocused(ownProps.id)),
    onSelect: () => dispatch(editableSelected(ownProps.id)),
    onUnSelect: () => dispatch(editableUnselected(ownProps.id)),
    onValueChange: (value) => dispatch(editableTextUpdated(ownProps.id, value)),
    onVAlignSliderChange: value => dispatch(textValignOverrideChanged(ownProps.id, value)),
    onVAlignSliderReset: () => dispatch(textValignOverrideReset(ownProps.id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TextFromField);
