import {
    STATE_MODIFIER_TEMPLATE_STATE_DIRTY,
    STATE_MODIFIER_TEMPLATE_STATE_SAVING,
    STATE_MODIFIER_TEMPLATE_STATE_SAVING_SUCCESS,
    STATE_MODIFIER_TEMPLATE_STATE_SAVING_FAIL,
} from '../actions/autoSaveActions';

const INITIAL_STATE = {
    stateDirty: false,
    saving: false,
    hasError: false,
    errors: [],
};

export default (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case STATE_MODIFIER_TEMPLATE_STATE_DIRTY: {
            return {
                ...state,
                stateDirty: true,
            };
        }
        case STATE_MODIFIER_TEMPLATE_STATE_SAVING: {
            return {
                ...state,
                saving: true,
                hasError: false,
            };
        }
        case STATE_MODIFIER_TEMPLATE_STATE_SAVING_SUCCESS: {
            return {
                ...state,
                saving: false,
                stateDirty: false,
            };
        }
        case STATE_MODIFIER_TEMPLATE_STATE_SAVING_FAIL: {
            return {
                ...state,
                saving: false,
                hasError: true,
                errors: [...state.errors, payload.error],
            };
        }
        default: {
            return state;
        }
    }
};
