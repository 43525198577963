import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";



const Sidebar = (props) => {
    const {isSidebarRoot, requestBack, title, children, contentData} = props;

    //* Different sidebar pages need different padding due to the nature of their content.
    const _getPadding = () => {
        const { contentName } = contentData;
        switch (contentName){
            case 'TemplateForm':
                return 'p-0';
            default:
                return 'p-3';
        }
    }

    return (
        <div className="row">
            <div className="col-sm-12 p-0 scrollable">
                <div className={classNames('scrollable inner', _getPadding() )}>
                    <div className="container px-0">
                        {!isSidebarRoot &&
                        <div className="text-left back-button mb-2">
                        <span className="float-none button-item d-inline-flex pr-2 align-items-center" aria-label="Close" onClick={requestBack}>
                            <span aria-hidden="true" className="icon">
                                <i className="fal fa-angle-double-left"/>
                            </span>
                            <span className="inner-text">Back</span>
                        </span>
                        </div>}
                        {title ? `<h6>${title}</h6>` : ''}
                    </div>
                    <div className="container px-0 full-height">
                        {children}
                    </div>
                </div>
            </div>

        </div>

    );
};

Sidebar.propTypes = {
    title: PropTypes.string,
    isSidebarRoot: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    requestBack: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
};

export default Sidebar;
