// import 'babel-polyfill';
import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import { render } from 'react-dom';
import UserFontConfigurator from "./widgets/UserFontConfigurator";

const renderPack = () => {
    const dataNode = document.getElementById('component-user-font-configurator');
    const containerNode = document.getElementById('user-font-configurator-container');
    const globalOffsetField = document.getElementById('user_font_global_offset');
    const globalScaleField = document.getElementById('user_font_global_scale');

    if (!dataNode || !containerNode || !globalOffsetField || !globalScaleField) {return; }

    const initializeData = JSON.parse(dataNode.getAttribute('data'));

    if(
        initializeData &&
        typeof initializeData.font_data !== 'undefined' &&
        initializeData.font_data &&
        typeof initializeData.name !== 'undefined' &&
        initializeData.name
    ){
        render(
            <UserFontConfigurator
                globalOffsetField={globalOffsetField}
                globalScaleField={globalScaleField}
                fontName={initializeData.name}
                fontData={initializeData.font_data}
            />
        ,containerNode);
    }
};

document.addEventListener('turbolinks:load', renderPack);

