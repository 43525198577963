export const EDITABLE_FOCUSED = 'INTERFACE:EDITABLE_FOCUSED';
export const editableFocused = (id) => ({
    type: EDITABLE_FOCUSED,
    payload: { id },
});

export const EDITABLE_UNFOCUSED = 'INTERFACE:EDITABLE_UNFOCUSED';
export const editableUnfocused = (id) => ({
    type: EDITABLE_UNFOCUSED,
    payload: { id },
});

export const EDITABLE_SELECTED = 'INTERFACE:EDITABLE_SELECTED';
export const editableSelected = (id) => ({
    type: EDITABLE_SELECTED,
    payload: { id },
});

export const EDITABLE_UNSELECTED = 'INTERFACE:EDITABLE_UNSELECTED';
export const editableUnselected = (id) => ({
    type: EDITABLE_UNSELECTED,
    payload: { id },
});

export const EDITABLE_TOGGLE_VISIBILITY = 'INTERFACE:EDITABLE_TOGGLE_VISIBILITY';
export const editableToggleVisibility = (id, isVisible) => ({
    type: EDITABLE_TOGGLE_VISIBILITY,
    payload: { id, isVisible },
});

export const TEMPLATE_ROOT_ELEMENT_INITIALIZED = 'INTERFACE:TEMPLATE_ROOT_ELEMENT_INITIALIZED';
export const templateRootElementInitialized = (type, ref) => ({
    type: TEMPLATE_ROOT_ELEMENT_INITIALIZED,
    payload: { type, ref },
});

export const TEMPLATE_ROOT_ELEMENT_UPDATE = 'INTERFACE:TEMPLATE_ROOT_ELEMENT_UPDATE';
export const templateRootElementUpdate = (type) => ({
    type: TEMPLATE_ROOT_ELEMENT_UPDATE,
    payload: { type },
});

export const TEMPLATE_ROOT_ELEMENT_REMOVE = 'INTERFACE:TEMPLATE_ROOT_ELEMENT_REMOVE';
export const templateRootElementRemove = (type) => ({
    type: TEMPLATE_ROOT_ELEMENT_UPDATE,
    payload: { type },
});

export const TEMPLATE_EDITABLE_ELEMENT_INITIALIZED = 'INTERFACE:TEMPLATE_EDITABLE_ELEMENT_INITIALIZED';
export const templateEditableElementInitialized = (type, id, ref, isNonBackgroundImage) => ({
    type: TEMPLATE_EDITABLE_ELEMENT_INITIALIZED,
    payload: { type, id, ref, isNonBackgroundImage },
});

export const TEMPLATE_EDITABLE_ELEMENT_REMOVE = 'INTERFACE:TEMPLATE_EDITABLE_ELEMENT_REMOVE';
export const templateEditableElementRemove = (type, id) => ({
    type: TEMPLATE_EDITABLE_ELEMENT_REMOVE,
    payload: { type, id },
});

export const TOGGLE_TEMPLATE_TYPE_CLICKED = 'INTERFACE:TOGGLE_TEMPLATE_TYPE_CLICKED';
export const toggleTemplateTypeClicked = (templateName) => ({
    type: TOGGLE_TEMPLATE_TYPE_CLICKED,
    payload: { templateName },
});
