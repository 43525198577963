import { createSelector } from "reselect";
import { camelCase } from 'lodash';

import { nodesDataSelector, rootDataSelector } from "./template";
import { hasField } from "../../business/helpers/utilities";
import { EDITABLE_ATTRIBUTES } from "../constants";

/**
 * Used for the font selector and the legacy color selector (the key/value pair style color selector that's no longer used as of 12/11/20)
 * @param itemName
 * @returns {OutputSelector<unknown, this, (res1: *, res2: {idKey}) => this>}
 */
export const createItemsValueSelector = (itemName) => createSelector(nodesDataSelector, rootDataSelector, (nodesData, rootNodeData) => {
    let itemsNameArr = [];
    const pushValue = (value) => {
        const formatedVal = camelCase(value);

        return itemsNameArr.indexOf(formatedVal) === -1 && itemsNameArr.push(formatedVal);
    };

    if (hasField(rootNodeData.attributes, itemName)) { pushValue(rootNodeData.attributes[itemName]) }

    for (const nodeData of Object.values(nodesData)) {
        if (hasField(nodeData.attributes, itemName)) { pushValue(nodeData.attributes[itemName]) }
    }

    return itemsNameArr.sort();
});

/**
 * Parses multiple color overrides into the color selectors
 * @param itemName
 * @returns {OutputSelector<unknown, this, (res1: *, res2: {idKey}) => this>}
 */
export const createColorsValueSelector = (itemName) => createSelector(nodesDataSelector, rootDataSelector, (nodesData, rootNodeData) => {
    let itemsNameArr = [];

    // Always include the primary and secondary and their contrasts
    itemsNameArr.push('brandColor1');
    itemsNameArr.push('brandColor1Contrast');
    itemsNameArr.push('brandColor2');
    itemsNameArr.push('brandColor2Contrast');

    const pushValue = (value) => {
        let colorOverrides = false;
        try {
            colorOverrides = JSON.parse(value);
        } catch (e) {
            colorOverrides = false;
        }
        if(colorOverrides){
            for (const [thisColorName, thisColorValue] of Object.entries(colorOverrides)) {
                const formatedVal = camelCase(thisColorName);
                if(itemsNameArr.indexOf(formatedVal) === -1){
                    itemsNameArr.push(formatedVal);
                }
            }
        }
    };

    if (hasField(rootNodeData.attributes, itemName)) {
        pushValue(rootNodeData.attributes[itemName]);
    }

    for (const nodeData of Object.values(nodesData)) {
        if (hasField(nodeData.attributes, itemName)) {
            pushValue(nodeData.attributes[itemName]);
        }
    }

    return itemsNameArr.sort();
});

export const createHasItemsSelector = (itemName) => createSelector(nodesDataSelector, rootDataSelector, (nodesData, rootNodeData) => {
    if (hasField(rootNodeData.attributes, itemName)) { return true;}

    for (const nodeData of Object.values(nodesData)) {
        if (hasField(nodeData.attributes, itemName)) { return true; }
    }

    return false;
});
export const fontNamesSelector = createItemsValueSelector(EDITABLE_ATTRIBUTES.font);
export const fontSizeNamesSelector = createItemsValueSelector(EDITABLE_ATTRIBUTES.fontSize);
// export const colorNamesSelector = createItemsValueSelector(EDITABLE_ATTRIBUTES.color); // Legacy; used with the key/value pair style. Deprecated in favor of the multiple "colorOverrides" style.
export const colorNamesSelector = createColorsValueSelector(EDITABLE_ATTRIBUTES.colorOverrides);


export const hasFontThemeElementsSelector = (state) => fontNamesSelector(state).length > 0;
export const hasColorThemeElementsSelector = (state) => colorNamesSelector(state).length > 0;
export const hasThemeElementsSelector = (state) => hasFontThemeElementsSelector(state) || hasColorThemeElementsSelector(state);
export const newHasEditableSelector = createHasItemsSelector(EDITABLE_ATTRIBUTES.editable);
export const oldHasEditableSelector = createHasItemsSelector('data-editable');
export const hasEditableElementsSelector = (state) => newHasEditableSelector(state) || oldHasEditableSelector(state);
