import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import Raven from "raven-js";
import createRavenMiddleware from "raven-for-redux";
import deepMerge from 'deepmerge';
import { mapValues } from 'lodash';

import sentryDsn from './sentryDsn.js.erb';
import rootReducer from '../reducer';
import rootListener from '../listeners';
import {zconsole} from "../../business/helpers/utilities";

const configureStore = (preloadedState)=> {

    const stateSanitizer = state => {
        const prunedFonts = mapValues(state.business.entities.userFont, (font) => ({...font, font_data: "[PRUNED]"}));

        const modifications =   {
            business: {
                entities: { ...state.business.entities, userFont: prunedFonts },
            }
        };
        return deepMerge(state, modifications);
    };

    // Redux Devtools
    const composeEnhancers = (
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        stateSanitizer: stateSanitizer,
    })) || compose;

    // Redux Saga
    const sagaMiddleware = createSagaMiddleware();

    let store;


    // Raven Error Tracking
    Raven.config(sentryDsn).install();
    const ravenMiddleware = createRavenMiddleware(Raven, {
        stateTransformer: stateSanitizer
    });

    // Init
    store = createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(
        ravenMiddleware,
        sagaMiddleware,
    )));

    sagaMiddleware.run(rootListener);
    return store;
};

export default configureStore;
