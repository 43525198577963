document.addEventListener('turbolinks:load', (event)=>{
    if(!document.body.classList.contains('subscription-form-new')){
        const form = document.getElementById('payment-form');

        if(form === null){ return null; }

        const stripe_public_key = document.querySelector("meta[name='stripe_public_key']").getAttribute('content');
        const clientSecret = document.querySelector("input[name='client_secret']").value;
        // eslint-disable-next-line
        const stripe =  Stripe(stripe_public_key);
        const elements = stripe.elements();

        const elementTypes = ['cardNumber', 'cardExpiry', 'cardCvc'];
        let createdElements = [];

        const renderElem = (elementType)=>{
            const element = elements.create(elementType);
            element.mount(`#${elementType}-element`);
            createdElements.push(element);

            element.addEventListener('change', (event)=>{
                const errorElement = document.getElementById(`${elementType}-errors`);
                const displayError = document.getElementById(elementType + '-errors');

                if(event.error){
                    errorElement.textContent = event.error.message;
                }else{
                    displayError.textContent = '';
                }
            });
        };

        const appendInput = (name, value)=>{
            const hiddenInput = document.createElement('input');
            hiddenInput.setAttribute('type', 'hidden');
            hiddenInput.setAttribute('name', name);
            hiddenInput.setAttribute('value', value);
            form.appendChild(hiddenInput);
        };

        const disableSubmitButton = () => {
            const button = document.getElementById('stripe-submit');
            button.setAttribute("disabled", "true");
        };

        const enableSubmitButton = () => {
            const button = document.getElementById('stripe-submit');
            button.removeAttribute("disabled", "false");
        };

        const handleSourceCreated = (result)=>{
            if (result.error) {
                enableSubmitButton();
                console.error('CARD ERROR: ', result.error.message);
            } else {
                appendInput('stripe_token_id', result.token.id);
                form.submit();
            }
        };

        const handleFormSubmit = (event)=>{
            event.preventDefault();
            disableSubmitButton();
            var card = createdElements[0]
            stripe
                .createToken(card)
                .then(handleSourceCreated);
        };

        elementTypes.forEach(renderElem);
        form.addEventListener('submit', handleFormSubmit);
    }
});
