import FontFaceObserver from 'fontfaceobserver';
import {checkNested, getNested, isPresent, roundToFive, roundToTwo} from "./utilities";

// When debugMode is true, the canvases will not be removed from the dom after measuring.
const debugMode = false;
const removeCalculationCanvases = !debugMode;

export const getTextMetrics = async(domItem, testtextoverride = false) => {
    const styles = getComputedStyle(domItem);
    let fontFamily = styles.getPropertyValue("font-family");
    fontFamily = fontFamily.split(',')[0];
    fontFamily = fontFamily.replaceAll("'", "").replaceAll('"', '');
    const fontSize = styles.getPropertyValue("font-size");
    let testtext = testtextoverride ? testtextoverride : domItem.textContent;
    let testTextExists = true;
    if(!testtext || testtext === ''){
        testtext = 'Hxy';
        testTextExists = false;
    }

    // 6/17 -- I decided to solve this by passing in the shared sibling text (so like the text of sibling 1 + sibling 2)
    // so that they both will have the same metrics returned.

    // 6/16 -- I'm hard-setting the test text because we don't want different elements with the same family but different
    // text (e.g. one with descenders and one without) to calculate different core metrics, as it messes up all of their
    // relative scaling stuff.
    // testtext = 'Hxy/ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890?.,()';

    const fontSizePx = Number(fontSize.split('px')[0]);

    //* Generate a hash from the font name for the canvas ID
    const canvasId = Math.abs(fontFamily.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0));

    var font = new FontFaceObserver(fontFamily);

    try {
        // To change the timeout, can use something like await font.load(null, 5000);
        await font.load();
    }
    catch (e) {
        console.log('font loading error: ', e);
    }

    //* Initialize the test canvas so that we can measure stuff
    const testCanvasWidth = 400;
    const testCanvasHeight = 200;
    const testCanvasPadding = 10;
    // const canvasDrawingTextFontSize = 1000;
    const canvasDrawingTextFontSize = fontSizePx;
    const testCanvas = document.createElement('canvas');
    testCanvas.id = (`cvs-${canvasId}-${Math.random().toString(36).substring(7)}`);
    testCanvas.className = `test-canvas ${canvasId}`;
    testCanvas.width = testCanvasWidth;
    testCanvas.height = testCanvasHeight;
    var testCanvasCtx = testCanvas.getContext("2d");
    testCanvas.style.font = `${canvasDrawingTextFontSize}px ${fontFamily}`;
    testCanvasCtx.font = [`${canvasDrawingTextFontSize}px`, fontFamily].join(' ');
    testCanvasCtx.clearRect(0, 0, testCanvasWidth, testCanvasHeight);
    testCanvasCtx.fontFamily = fontFamily;
    testCanvasCtx.fillStyle = "#fff";
    testCanvasCtx.fillRect(0,0,testCanvas.width, testCanvas.height);
    testCanvasCtx.fillStyle = "#333333";
    testCanvasCtx.fillText(testtext, testCanvasPadding, testCanvasHeight);

    document.body.appendChild(testCanvas);

    //* Get Core Measurements
    // var xHeight = testCanvasCtx.measureText("x").height;
    // var capHeight = testCanvasCtx.measureText("H").height;
    var measuredTextMetrics = testCanvasCtx.measureText(testtext);

    //* Make the measurements usable (cast to numbers to allow for nulls)
    let metrics = {};
    metrics.text = testtext;
    metrics.measured = {
        actualBoundingBoxAscent: roundToFive(measuredTextMetrics.actualBoundingBoxAscent),
        actualBoundingBoxDescent: roundToFive(measuredTextMetrics.actualBoundingBoxDescent),
        actualBoundingBoxAscentPlusDescent: roundToFive(measuredTextMetrics.actualBoundingBoxAscent + measuredTextMetrics.actualBoundingBoxDescent),
        actualBoundingBoxLeft: roundToFive(measuredTextMetrics.actualBoundingBoxLeft),
        actualBoundingBoxRight: roundToFive(measuredTextMetrics.actualBoundingBoxRight),
        fontBoundingBoxAscent: roundToFive(measuredTextMetrics.fontBoundingBoxAscent),
        fontBoundingBoxDescent: roundToFive(measuredTextMetrics.fontBoundingBoxDescent),
        width: roundToFive(measuredTextMetrics.width),

    };

    metrics.info = {
        fontSize,
        fontSizePx: fontSizePx ? Number(fontSizePx) : null,
        fontFamily,
        testtext
    }

    const fontSizeMultiplicand = fontSizePx / canvasDrawingTextFontSize;

    const {
        actualBoundingBoxAscent,
        actualBoundingBoxDescent,
        fontBoundingBoxAscent,
        fontBoundingBoxDescent,
    } = metrics.measured;
    metrics.calculated = {
        gapAboveText: roundToFive((fontBoundingBoxAscent - actualBoundingBoxAscent) * fontSizeMultiplicand),
        gapBelowText: roundToFive(fontBoundingBoxDescent - actualBoundingBoxDescent * fontSizeMultiplicand),
        textHeight: roundToFive(actualBoundingBoxAscent * fontSizeMultiplicand),
        totalHeight: roundToFive((fontBoundingBoxAscent + fontBoundingBoxDescent) * fontSizeMultiplicand),
    };
    const {
        gapBelowText, gapAboveText, textHeight, totalHeight
    } = metrics.calculated;

    metrics.calculated.gapBelowTextPercent = roundToFive(gapBelowText / totalHeight);
    metrics.calculated.gapAboveTextPercent = roundToFive(gapAboveText / totalHeight);
    metrics.calculated.gapTopBottomRatio = roundToFive(gapAboveText / gapBelowText);
    metrics.calculated.textHeightPercent = roundToFive(textHeight / totalHeight);
    metrics.calculated.baselineMarginTop = gapBelowText - gapAboveText;

    if(removeCalculationCanvases === true){
        testCanvas.remove(); // cleanup
    }
    return metrics;
};

export const getTextAdjustments = async(textDomNode, scalerDomNode, parentDomNode, metrics) => {
    // const thisNodeName = parentDomNode.firstChild.getAttribute('data-name'); // for debugging
    let toReturn = {
        textNode: {},
        textContainerNode: {},
        fundamentalScale: null,
        overflowScale: null
    }

    const textStyles = getComputedStyle(textDomNode);
    const containerStyles = getComputedStyle(parentDomNode);

    // const fontFamily = textStyles.getPropertyValue("font-family");
    const fontSize = textStyles.getPropertyValue("font-size");
    // let testtext = textDomNode.textContent; // 6/14 - Commenting out in favor of grabbing from metrics to allow the manual override
    let testtext = metrics.info.testtext;
    let testTextExists = true;
    if(!testtext || testtext === ''){
        testtext = 'Hxy';
        testTextExists = false;
    }

    //* For getting width including padding and such
    // const containerWidth = scalerDomNode.offsetWidth;
    // const containerHeight = scalerDomNode.offsetHeight;

    //* Inner width without padding
    let containerHeight = parentDomNode.clientHeight;  // height with padding
    let containerWidth = parentDomNode.clientWidth;   // width with padding
    containerHeight -= parseFloat(containerStyles.paddingTop) + parseFloat(containerStyles.paddingBottom);
    containerWidth -= parseFloat(containerStyles.paddingLeft) + parseFloat(containerStyles.paddingRight);

    // const topBottomOffset = ((metrics.calculated.gapAboveText - metrics.calculated.gapBelowText) / 2) * -1;
    // const gapTotal = metrics.calculated.gapAboveText + metrics.calculated.gapBelowText;
    // const heightDifference = scalerDomNode.clientHeight - metrics.measured.actualBoundingBoxAscent;
    // const amountEmpty = heightDifference - gapTotal;
    // const marginTop = ((scalerDomNode.clientHeight - metrics.measured.actualBoundingBoxAscent) / 2);
    // const scaler = scalerDomNode;
    // const textHeight = metrics.measured.actualBoundingBoxAscentPlusDescent;
    // const descender = metrics.measured.actualBoundingBoxDescent;
    const fundamentalScale = roundToTwo(containerHeight / (metrics.measured.actualBoundingBoxAscentPlusDescent));

    // Check for text going outside the container width
    const currentWidth = textDomNode.offsetWidth;
    const overflowScale = testtext.length ? containerWidth / ( currentWidth * fundamentalScale ) : fundamentalScale;

    const fontSizePx = Number(fontSize.split('px')[0]);
    const desiredCapitalHeight = containerHeight;
    const desiredLineHeight = 1;
    // const thisFontSettings = _.find(allAvailableFonts, ['value', fontFamily]); // TODO 5/10/21 - get this working if there will be a global setting of font settings
    // let manualFontOffsetOverride = thisFontSettings.topOverrideRatio; // A percentage of the set font size to override
    let manualFontOffsetOverride = 0; // A percentage of the set font size to override
    const overrideMarginTop = desiredCapitalHeight * manualFontOffsetOverride;

    // const fm_emSquare = 1; // IDK how to determine this
    const fm_capitalHeight = metrics.measured.actualBoundingBoxAscent / fontSizePx;
    const fm_descender = metrics.measured.fontBoundingBoxDescent / fontSizePx;
    const fm_ascender = metrics.measured.fontBoundingBoxAscent / fontSizePx;
    // const fm_linegap = 0; // IDK how to determine this

    /* compute needed values */
    // const lineheightNormal = fm_ascender + fm_descender + fm_linegap;
    const distanceBottom = fm_descender;
    const distanceTop = fm_ascender - fm_capitalHeight;
    const computedFontSize = desiredCapitalHeight / fm_capitalHeight;

    //* Original
    const valign = ((distanceBottom - distanceTop) * computedFontSize);
    const computedLineheight = (desiredLineHeight * desiredCapitalHeight) - valign;

    toReturn.textNode.verticalAlign = `${roundToTwo(valign * -1)}px`;
    toReturn.textNode.verticalAlignPx = roundToTwo(valign * -1);
    toReturn.textContainerNode.fontSize = `${roundToTwo(computedFontSize)}px`;
    toReturn.textContainerNode.fontSizePx = roundToTwo(computedFontSize);
    toReturn.textContainerNode.lineHeight = `${roundToTwo(computedLineheight)}px`;
    toReturn.textContainerNode.lineHeightPx = roundToTwo(computedLineheight);
    toReturn.textContainerNode.marginTop = `${roundToTwo(overrideMarginTop)}px`;
    toReturn.textContainerNode.marginTopPx = roundToTwo(overrideMarginTop);
    toReturn.fundamentalScale = fundamentalScale;
    toReturn.overflowScale = overflowScale;
    
    return toReturn;
}

/**
 * 6/30/21 Not currently used. Keeping it in case we stop using the react-textfit library, as it might be helpful to
 * reference later. (Depending on the font sizing options we want to give the user later, we may not be able to use that
 * library long-term)
 *
 * @param textDomNode
 * @param scalerDomNode
 * @param parentDomNode
 * @param fontSizeOverride
 * @param multilineInitialFontSizePx
 * @returns {number|*}
 */
export const getMultilineTextOverflowFontSizePx = (textDomNode, scalerDomNode, parentDomNode, fontSizeOverride, multilineInitialFontSizePx) => {
    const containerStyles = getComputedStyle(parentDomNode);
    const containerFontSize = containerStyles.getPropertyValue("font-size");
    let fontSizePx;
    if(fontSizeOverride){
        fontSizePx = fontSizeOverride;
    }
    else {

        fontSizePx = Number(containerFontSize.split('px')[0]);
    }

    //* Inner width without padding
    let containerHeight = parentDomNode.clientHeight;  // height with padding
    let containerWidth = parentDomNode.clientWidth;   // width with padding
    containerHeight -= parseFloat(containerStyles.paddingTop) + parseFloat(containerStyles.paddingBottom);
    containerWidth -= parseFloat(containerStyles.paddingLeft) + parseFloat(containerStyles.paddingRight);

    // Check for text going outside the container width
    const currentWidth = textDomNode.offsetWidth;
    const currentHeight = textDomNode.offsetHeight;

    const scaleUpDeciderDistance = 20;
    const fontAdjustmentIncrement = 4;

    // Reduce the font size if the text is overflowing
    if(currentHeight > containerHeight || currentWidth > containerWidth){
        // console.log('it is overflowing')
        return fontSizePx - fontAdjustmentIncrement;
    }
    // Scale up the font size if it's too small
    else if(currentHeight < containerHeight - scaleUpDeciderDistance){
        // console.log('it needs to get bigger')
        if(fontSizePx + fontAdjustmentIncrement < multilineInitialFontSizePx){
            return fontSizePx + fontAdjustmentIncrement;
        }
        else {
            return multilineInitialFontSizePx;
        }
    }

    return fontSizePx;
}

/**
 * 6/30/21 Not currently used. Keeping it in case we stop using the react-textfit library, as it might be helpful to
 * reference later. (Depending on the font sizing options we want to give the user later, we may not be able to use that
 * library long-term)
 * @param textDomNode
 * @param parentDomNode
 * @returns {boolean}
 */
export const isMultilineTextOverflowingContainer = (textDomNode, parentDomNode) => {
    const containerStyles = getComputedStyle(parentDomNode);
    let containerHeight = parentDomNode.clientHeight;  // height with padding
    let containerWidth = parentDomNode.clientWidth;   // width with padding
    containerHeight -= parseFloat(containerStyles.paddingTop) + parseFloat(containerStyles.paddingBottom);
    containerWidth -= parseFloat(containerStyles.paddingLeft) + parseFloat(containerStyles.paddingRight);
    const currentHeight = textDomNode.offsetHeight;
    const currentWidth = textDomNode.offsetWidth;
    return currentHeight > containerHeight || currentWidth > containerWidth;
}


//* If this is editable text OR static text that can have its font changed, use the scaler.
export const isATextNode = attributes => {
    return isPresent(attributes['data-editable']) && attributes['data-editable'] === 'text' || isPresent(attributes['data-editable-font']);
}

export const isMultilineText = attributes => {
    return isPresent(attributes['data-editable']) && attributes['data-editable'] === 'text' &&
        isPresent(attributes['data-multiline']) && attributes['data-multiline'] === 'true';
}

export const isDefaultFontFamily = props => {
    return typeof getNested(props, 'styles', 'fontFamily') === 'undefined';
}

//* If there are no metrics, or the metrics are for a different font than the current one, we should recalculate
export const shouldCalculateTextMetrics = (currentMetrics, attributes) => {
    const fontInMetrics = typeof currentMetrics !== 'undefined' && checkNested(currentMetrics, 'info', 'fontFamily') ? getNested(currentMetrics, 'info', 'fontFamily') : null;
    const fontInAttributes = typeof attributes !== 'undefined' && checkNested(attributes, 'style', 'fontFamily') ? getNested(attributes, 'style', 'fontFamily') : null;
    return !fontInMetrics || fontInMetrics !== fontInAttributes;
}