import { EDITABLE_ATTRIBUTES } from "../constants";
import { size } from 'lodash';

//convert from '95% 100%' to {x: 0.95, y: 1}
export const positionToObject = (cssString = '') => cssString.length ?
    {
        x: parseFloat(cssString.split(" ")[0].replace(/[%]/g, "")) / 100.0,
        y: parseFloat(cssString.split(" ")[1].replace(/[%]/g, "")) / 100.0
    } :
    {};

export const sizeToFloat = (cssString = '') => cssString.length ? parseFloat(cssString.replace(/[%]/g, "")) / 100.0 : null;

//convert 'url('http://www.example.com/image.png') to 'http://www.example.com/image.png'
export const srcToString = (cssString = '') => cssString.slice(4, -1).replace(/['"]/g, "");

export const getVisibilityValue = (visibility) => ({
    isVisible: visibility === 'true' || visibility === '',
});

export const getEditableImageValue = (element, isNonBackgroundImage) => {
    if(isNonBackgroundImage === true){
        if(size(element.children) && element.children[0]){
            return {
                nonBackgroundImage: true,
                library: element.getAttribute(EDITABLE_ATTRIBUTES.library) || element.getAttribute('data-library'), //TODO template compatibility, remove later
                src: element.children[0].src,
                userImageLibraryFolder: element.getAttribute(EDITABLE_ATTRIBUTES.userImageLibraryFolder)
            };
        }
    }
    else {
        const {backgroundImage, backgroundPosition, backgroundSize, width, height} = getComputedStyle(element);

        return {
            width: parseInt(width, 10),
            height: parseInt(height, 10),
            library: element.getAttribute(EDITABLE_ATTRIBUTES.library) || element.getAttribute('data-library'), //TODO template compatibility, remove later
            src: srcToString(backgroundImage),
            backgroundPosition: positionToObject(backgroundPosition),
            backgroundSize: sizeToFloat(backgroundSize),
            userImageLibraryFolder: element.getAttribute(EDITABLE_ATTRIBUTES.userImageLibraryFolder)
        };
    }
};

//TODO use immutable js
export const updateStyleInNode = (nodeData, newStyles) => {
    return {
        ...nodeData,
        attributes: {
            ...nodeData.attributes,
            style: nodeData.attributes.style ? {
                    ...nodeData.attributes.style,
                    ...newStyles,
                } :
                {...newStyles},
        },
    }
};


