import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class ToggleButton extends React.Component {

    static propTypes = {
        onToggleClick: PropTypes.func.isRequired,
        templateType: PropTypes.string.isRequired,
        templates: PropTypes.arrayOf(PropTypes.string).isRequired,
    };

    isActive = (templateType) => this.props.templateType === templateType;

    handleClick = (templateName) => () => this.props.onToggleClick(templateName);

    renderTemplateToggle = (templateName) => {
      return (
          <button
              key={templateName}
              className={classNames('btn btn-outline-primary', { 'active': this.isActive(templateName)})}
              onClick={this.handleClick(templateName)}>
              {templateName}
          </button>
        );
    };

    render() {
        const { templates } = this.props;
        const buttons = templates.map((type) => this.renderTemplateToggle(type));

        return(
            <div>
                <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                  { buttons }
                </div>
            </div>
        );
    }
}
