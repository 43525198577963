import { FOCUS_TEXT_FIELD, UNFOCUS_TEXT_FIELD } from "../actions/stateModifierActions";

export const INITIAL_STATE = {
    focused: null,
};

export default (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case FOCUS_TEXT_FIELD: {
            return {
                ...state,
                focused: payload.id,
            };
        }
        case UNFOCUS_TEXT_FIELD: {
            return {
                ...state,
                focused: null,
            };
        }
        default: {
            return state;
        }
    }
};
