import { connect } from 'react-redux';

import FontFormField from './component';

import { themeFontUpdated } from "../../../actions/interfaceActions";
import { themeActiveFontIdSelector, themeFontsArraySelector } from "../../../selectors/theme";

const mapStateToProps = (state, ownProps) => {
    return {
        activeFontId: themeActiveFontIdSelector(state, ownProps),
        fonts: themeFontsArraySelector(state),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    handleFontChange: (e) => dispatch(themeFontUpdated(ownProps.name, parseInt(e.target.value))),
});

export default connect(mapStateToProps, mapDispatchToProps)(FontFormField);
