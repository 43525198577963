import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

const ElementVisibilityToggle = props => {
    const {toggle, isVisible, onFocus, onUnFocus} = props;
    const toggleVisibilityHandler = () => toggle(!isVisible);

    return (
        <div
            className="toggle-element-visibility"
            onClick={toggleVisibilityHandler}
            onMouseEnter={onFocus}
            onMouseLeave={onUnFocus}
            key={isVisible}
        >
            <i className={classNames('fa',
                {
                    'fa-eye': isVisible,
                    'fa-eye-slash': !isVisible,
                })}
            />
        </div>
    );
};

ElementVisibilityToggle.propTypes = {
    toggle: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    onFocus: PropTypes.func.isRequired,
    onUnFocus: PropTypes.func.isRequired,
};

export default ElementVisibilityToggle;
