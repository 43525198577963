import React from 'react';
import PropTypes from 'prop-types';

export default function ContrastColorElement(props) {
    const {value, label, active, onClick, compareValue} = props;
    return (
        <div className={`contrast-color-element-container col-sm-6 text-center ${!compareValue ? 'hidden' : ''}`} onClick={() => { onClick(value) }}>
            <div className={`contrast-color-element d-inline-flex align-items-center ${active ? 'active' : ''}`}>
                <div className="content p-5 text-center" style={{ color: compareValue }}>
                    <h4 style={{ color: compareValue }}>{label}</h4>
                    {active ? <h6 style={{ color: compareValue }}>(Currently Active)</h6> : ''}
                </div>
                <div className="background-overlay" style={{backgroundColor: value}} />
                <div className="background" />
            </div>

            <div className={`contrast-color-element d-inline-flex align-items-center ${active ? 'active' : ''}`}>
                <div className="content p-5 text-center" style={{ color: value }}>
                    <h4 style={{ color: value }}>{label}</h4>
                    {active ? <h6 style={{ color: value }}>(Currently Active)</h6> : ''}
                </div>
                <div className="background-overlay" style={{backgroundColor: compareValue}} />
                <div className="background" />
            </div>

            <input type="radio" className="mt-2" defaultChecked={active} />
        </div>
    );
}

ContrastColorElement.propTypes = {
    styles: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    compareValue: PropTypes.string,
    active: PropTypes.bool
}