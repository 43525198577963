import { connect } from 'react-redux';

import FlashElement from './component';

import { removeFLashElement } from "../../actions/flashActions";

const mapDispatchToProps = (dispatch, ownProps) => ({
    deleteElement: () => dispatch(removeFLashElement(ownProps.id)),
});

export default connect(null, mapDispatchToProps)(FlashElement);
