import { connect } from 'react-redux';

import Flash from './component';

import { flashSelector } from "../../selectors/flash";

const mapStateToProps = (state) => ({
    flash: flashSelector(state),
});

export default connect(mapStateToProps)(Flash);
