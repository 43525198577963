import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class DeleteButton extends React.Component {

    static propTypes = {
        onConfirm: PropTypes.func.isRequired,
    };

    state = {
        confirm: false,
    };

    handleClick = (e) => {
        e.stopPropagation();

        return this.state.confirm ? this.handleConfirm() : this.handleDelete();
    };

    handleDelete = () => this.setState({ confirm: true });

    handleConfirm = () => this.props.onConfirm();

    render() {
        const {confirm} = this.state;

        return(
            <div>
                <span className={classNames('delete-button', { confirm })} onClick={this.handleClick}>
                    <div className="icon">
                        <div className="fa fa-trash-o" />
                        <div className="fa fa-question" />
                        <div className="fa fa-check" />
                    </div>
                    <div className="text">
                        <span className="small">{ confirm ? 'You sure?' : 'Delete' }</span>
                    </div>
                </span>
            </div>
        );
    }
}
