import React from 'react';
import PropTypes from 'prop-types';
import { Textfit } from 'react-textfit';
import TemplateConstructor from "../TemplateConstructor/container";
import {EDITABLE_ATTRIBUTES, EDITABLE_TYPES} from "../../../constants";
import {checkNested, getNested, isPresent, roundToTwo, zconsole} from "../../../../business/helpers/utilities";
import {
    getTextAdjustments,
    getTextMetrics,
    isATextNode, isDefaultFontFamily, isMultilineText
} from "../../../../business/helpers/normalizeFonts";

export default class TemplateEditableElement extends React.Component {
    static propTypes = {
        styles: PropTypes.object,
        isLegacyTemplate: PropTypes.bool.isRequired,
        isPublicTemplate: PropTypes.bool.isRequired,
        templateId: PropTypes.number.isRequired,
        elementInitialized: PropTypes.func.isRequired,
        elementRemove: PropTypes.func.isRequired,
        onMouseEnter: PropTypes.func.isRequired,
        onMouseLeave: PropTypes.func.isRequired,
        nodeData: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            attributes: PropTypes.object,
            children: PropTypes.arrayOf(PropTypes.string),
        }),
        editableData: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string.isRequired,
            position: PropTypes.object,
            [EDITABLE_TYPES.image]: PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.bool,
            ]),
            [EDITABLE_ATTRIBUTES.nonBackgroundImage]: PropTypes.bool,
            [EDITABLE_TYPES.text]: PropTypes.oneOfType([
                PropTypes.bool,
                PropTypes.object,
            ]),
            [EDITABLE_TYPES.visibility]: PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.bool,
            ]),
        }),
    };

    element = React.createRef();
    textNodeElement = null; // Will be a ref for the inner text node

    INITIAL_STATE = {
        isMounted: false,
        currentlyCalculatingAdjustments: false,
        shouldCalculateAdjustments: false,
        shouldCalculateAdjustmentsAfterRender: false,
        shouldCalculateMultilineAdjustments: false,
        multilineCurrentlyCalculatingAdjustments: false,
        multilineFontSizeOverride: null,
        multilineInitialFontSizePx: null
    }

    state = {
        ...this.INITIAL_STATE
    };

    async componentDidMount() {
        const { nodeData: {attributes}} = this.props;
        const parentDomNode = this._getParentDomNodeRef();
        if(isMultilineText(attributes)){
            const containerStyles = getComputedStyle(parentDomNode);
            const containerFontSize = containerStyles.getPropertyValue("font-size");
            // console.log('this eleme', this.element.current)
            // console.log('parentDomNode', parentDomNode);
            // console.log('containerStyles', containerStyles);
            // const containerHeight = containerStyles.getPropertyValue("height");
            // const containerMaxHeight = containerStyles.getPropertyValue("max-height");
            // if(containerHeight){
            //     console.log('containerHeight', containerHeight)
            // }
            // else {
            //     console.log('no containerHeight')
            // }
            // console.log('containerMaxHeight', containerMaxHeight);
            const fontSizePx = Number(containerFontSize.split('px')[0]);
            this.setState({
                isMounted: true,
                shouldCalculateAdjustmentsAfterRender: true,
                multilineInitialFontSizePx: fontSizePx
            });
        }
        else {
            this.setState({
                isMounted: true,
                shouldCalculateAdjustmentsAfterRender: true
            });
        }
        this.props.elementInitialized(this.element, this.props.editableData.nonBackgroundImage === true);
    }

    async componentDidUpdate(prevProps, prevState) {
        const { nodeData: {attributes}, styles, editableData, isLegacyTemplate} = this.props;
        const { isMounted, shouldCalculateAdjustments, currentlyCalculatingAdjustments, shouldCalculateAdjustmentsAfterRender, multilineCurrentlyCalculatingAdjustments, multilineFontSizeOverride, multilineInitialFontSizePx, shouldCalculateMultilineAdjustments } = this.state;

        const parentDomNode = this._getParentDomNodeRef();

        if(isMounted === true && isATextNode(attributes) && !isLegacyTemplate){
            if(shouldCalculateAdjustments === true && currentlyCalculatingAdjustments === false){
                this.setState({
                    currentlyCalculatingAdjustments: true,
                    shouldCalculateAdjustments: false,
                    shouldCalculateAdjustmentsAfterRender: false
                });
                const sharedSiblingText = checkNested(this.props, 'textSiblings', 'combinedTextData') ? getNested(this.props, 'textSiblings', 'combinedTextData') : null;
                const metrics = await getTextMetrics(this.element.current, sharedSiblingText);
                const adjustments = await getTextAdjustments(this.textNodeElement, this.element.current, parentDomNode, metrics);
                this.props.updateEditableTextData(metrics, adjustments);
                this.setState({ currentlyCalculatingAdjustments: false });
            }
            else if(currentlyCalculatingAdjustments === false && shouldCalculateAdjustmentsAfterRender === false) {
                const fontFamilyIsDifferent = this._isFontFamilyDifferent(prevProps, prevState);
                const thereAreNoMetricsOrAdjustments = this._thereAreNoMetricsOrAdjustments();
                const textContentHasChanged = this._textContentHasChanged(prevProps, prevState);
                if(fontFamilyIsDifferent || thereAreNoMetricsOrAdjustments || textContentHasChanged){
                    this.setState({ shouldCalculateAdjustmentsAfterRender: true });
                }
            }

            if(shouldCalculateAdjustmentsAfterRender === true && currentlyCalculatingAdjustments === false){
                this.setState({ shouldCalculateAdjustments: true });
            }
        }
    }

    componentWillUnmount() {
        this.setState({ isMounted: false });
        this.props.elementRemove();
    }
    
    _getParentDomNodeRef(){
        const { parentRef } = this.props;

        let parentDomNode;
        if(parentRef && parentRef.current){
            parentDomNode = parentRef.current
        }
        else {
            parentDomNode = this.element.current;
            // console.error('No parent found in _getParentDomNodeRef for ', this.props.nodeData.attributes['data-name']);
            // console.error('with props', this.props);
            // TODO Throw an error -- suggests that the template was not coded properly.
        }
        return parentDomNode;
    }

    //* Make sure the new font family is actually different
    _isFontFamilyDifferent(prevProps, prevState){
        let fontFamilyIsDifferent = false;
        const newFontFamily = checkNested(this.props, 'styles', 'fontFamily') ? getNested(this.props, 'styles', 'fontFamily') : null;
        if(newFontFamily || (isDefaultFontFamily(this.props) && !isDefaultFontFamily(prevProps) )){
            const oldFontFamily = checkNested(prevProps, 'styles', 'fontFamily') ? getNested(prevProps, 'styles', 'fontFamily') : null;
            if(!oldFontFamily || newFontFamily !== oldFontFamily){
                const fontGlobalOffset = checkNested(this.props, 'nodeData', 'attributes', 'style', 'fontGlobalOffset') ? getNested(this.props, 'nodeData', 'attributes', 'style', 'fontGlobalOffset') : 0;
                this.props.resetValignValue(fontGlobalOffset);
                fontFamilyIsDifferent = true;
            }
        }
        return fontFamilyIsDifferent;
    }

    //* If there aren't any current metrics, or adjustments haven't yet been calculated, we should recalculate
    _thereAreNoMetricsOrAdjustments(){
        const currentMetrics = checkNested(this.props.editableData, 'text', 'metrics') ? getNested(this.props.editableData, 'text', 'metrics') : null;
        const fundamentalFontScale = checkNested(this.props.editableData, 'text', 'adjustments', 'fundamentalScale') ? getNested(this.props.editableData, 'text', 'adjustments', 'fundamentalScale') : undefined;
        const noOverflowFontScale = checkNested(this.props.editableData, 'text', 'adjustments', 'overflowScale') ? getNested(this.props.editableData, 'text', 'adjustments', 'overflowScale') : undefined;
        return !currentMetrics || typeof fundamentalFontScale === 'undefined' || typeof noOverflowFontScale === 'undefined';
    }

    //* Check if the text has changed
    _textContentHasChanged(prevProps){
        const newSharedSiblingText = checkNested(this.props, 'textSiblings', 'combinedTextData') ? getNested(this.props, 'textSiblings', 'combinedTextData') : null;
        const oldSharedSiblingText = checkNested(prevProps, 'textSiblings', 'combinedTextData') ? getNested(prevProps, 'textSiblings', 'combinedTextData') : null;
        if(newSharedSiblingText || oldSharedSiblingText){
            if(
                (
                    typeof oldSharedSiblingText !== 'undefined' &&
                    typeof newSharedSiblingText !== 'undefined' &&
                    oldSharedSiblingText &&
                    newSharedSiblingText &&
                    oldSharedSiblingText !== newSharedSiblingText
                )
                || typeof oldSharedSiblingText === 'undefined' && typeof newSharedSiblingText !== 'undefined'
                || !oldSharedSiblingText && newSharedSiblingText
            ){
                return true;
            }
        }
        return false;
    }

    getContainerStyles(isTextNode){
        const { nodeData: {attributes}, editableData, styles, isLegacyTemplate, isPublicTemplate, templateId } = this.props;
        const {visibility, text, nonBackgroundImage, nonBackgroundImageData} = editableData || {};
        let containerStyle = styles || {};

        if(isMultilineText(attributes)){
            containerStyle.height = "100%";
            containerStyle.width = "100%";
            containerStyle.whiteSpace = "normal";
            return containerStyle;
        }

        //* For foreground images, we don't need all the background image css
        if(nonBackgroundImage === true){
            containerStyle = {}
        }
        // For background images, set size to cover
        else if(editableData.image && !editableData.image.backgroundSize && editableData.nonBackgroundImage === false) {
            containerStyle.backgroundSize = "cover";
        }

        // For placeholder bgs, set the background url to include the important attributes
        if(editableData.nonBackgroundImage === false && containerStyle.backgroundImage && containerStyle.backgroundImage.includes('placeholder')){
            containerStyle.backgroundImage = `${containerStyle.backgroundImage.slice(0, containerStyle.backgroundImage.length-1)}?template_id=${templateId}&template_type=${isPublicTemplate ? 'public' : 'bespoke'})`;
        }

        if (visibility && !visibility.isVisible) {
            containerStyle = {
                ...containerStyle,
                // visibility: 'hidden',
                display: 'none',
            };
        } else {
            containerStyle = {
                ...containerStyle,
                // visibility: '',
                display: '',
            };
        }

        if(isTextNode && !isLegacyTemplate){
            const existingMarginTop = styles.marginTop ? styles.marginTop : 0;
            if(isDefaultFontFamily(this.props)) {
                containerStyle.marginTop = undefined;
            }
            else {
                containerStyle.marginTop = checkNested(this.props, 'editableData', 'text', 'adjustments', 'textContainerNode', 'marginTop') && !isNaN(this.props.editableData.text.adjustments.textContainerNode.marginTop) ? roundToTwo(this.props.editableData.text.adjustments.textContainerNode.marginTop + existingMarginTop) : 0 ;
                if(checkNested(this.props, 'editableData', 'text', 'adjustments', 'textContainerNode', 'fontSizePx')){
                    let fontSizeToUse = this.props.editableData.text.adjustments.textContainerNode.fontSizePx;
                    if(!isNaN(fontSizeToUse) && fontSizeToUse){
                        containerStyle.fontSize = `${fontSizeToUse}px`;
                    }
                }
            }
        }

        return containerStyle;
    }

    getScalerStyles(){
        const { nodeData: {attributes}, editableData, styles, parentRef } = this.props;
        let scalerStyle = { transform: 'scale(1)', transformOrigin: 'center center' }

        // For left/right aligned things, we need to add a transform origin
        if(parentRef && parentRef.current){
            const parentDomNode = parentRef.current
            const containerStyles = getComputedStyle(parentDomNode);
            if(containerStyles){
                const justifyContent = containerStyles.justifyContent;
                if(justifyContent === 'flex-end'){
                    scalerStyle.transformOrigin = 'right center';
                }
                else if(justifyContent === 'flex-start'){
                    scalerStyle.transformOrigin = 'left center';
                }
            }
        }

        // Scale should always be 1 for multiline
        if(isMultilineText(attributes)){
            return scalerStyle;
        }

        let fundamentalFontScale = checkNested(editableData, 'text', 'adjustments', 'fundamentalScale') ? getNested(editableData, 'text', 'adjustments', 'fundamentalScale') : 1;
        const themeFontSizeAdjust = styles && typeof styles.fontSizeScale !== 'undefined' ? styles.fontSizeScale : 0; // 0 means it's at the default.
        const smallestSiblingOverflowFontScale = checkNested(this.props, 'textSiblings', 'smallestNoOverflowFontScale') ? getNested(this.props, 'textSiblings', 'smallestNoOverflowFontScale') : 1;
        const metricsScale = smallestSiblingOverflowFontScale * fundamentalFontScale;
        const thematicScale = 1 + Number(themeFontSizeAdjust);
        const newScale = metricsScale < thematicScale ? metricsScale : thematicScale;
        if(!isNaN(newScale) && Number(newScale) > 0){
            scalerStyle.transform = `scale(${newScale})`;
        }

        return scalerStyle;
    }

    getTextStyles(){
        const { nodeData: {attributes}, editableData } = this.props;

        // if(isDefaultFontFamily(this.props)){
        //     return { verticalAlign: undefined }
        // }

        if(isMultilineText(attributes)){
            return {
                verticalAlign: undefined,
                lineHeight: 1.2 // todo maybe one day let the user choose the line height, since different fonts look more squished/spaced
            }
        }

        let textNodeStyles = {
            verticalAlign: undefined,
        };

        if(checkNested(editableData, 'text', 'vertical_align_override')){
            const { text: {vertical_align_override}} = editableData;
            const textHeight = getNested(editableData, 'text', 'metrics', 'calculated', 'textHeight');
            const userTextoverride = typeof vertical_align_override !== "undefined" ? Number(vertical_align_override) * textHeight : 0;

            let finalTextOverride;
            if(isDefaultFontFamily(this.props)){
                finalTextOverride = roundToTwo(userTextoverride);
            }
            else {
                const nativeTextOverride = checkNested(editableData, 'text', 'adjustments', 'textNode', 'verticalAlignPx') ? editableData.text.adjustments.textNode.verticalAlignPx : 0;
                finalTextOverride = roundToTwo(nativeTextOverride + userTextoverride);
            }

            textNodeStyles.verticalAlign = `${finalTextOverride}px`;
        }

        return textNodeStyles;
    }

    render() {
        const {nodeData: {name, attributes, children}, editableData, onMouseEnter, onMouseLeave, isLegacyTemplate, isPublicTemplate, templateId, styles } = this.props;
        const {text, nonBackgroundImage, visibility} = editableData || {};
        const isVisible = !visibility || (visibility && visibility.isVisible);

        let isTextNode = isATextNode(attributes); // For scaling text in normalization
        let containerStyle = {...this.getContainerStyles(isTextNode)};
        if(isTextNode){
            const setFontFamily = getNested(attributes, 'style', 'fontFamily');
            if(setFontFamily){
                containerStyle.fontFamily = setFontFamily;
            }
        }

        let CustomTagName = `${name}`;
        let childNodes = isPresent(children) && children.map((id) => {
            let editableText;

            if (isPresent(text) && text.id === id) {
                editableText = text.value;
            }

            if(isMultilineText(attributes)){
                editableText = editableText.split("\n").map(function(item, index) {
                    return (
                        <span key={index}>
                        {item}
                        <br/>
                        </span>
                    )
                });
            }

            return (<TemplateConstructor key={id} id={id} editableText={editableText} parentRef={this.element} />);
        });


        //* If this node has the nonBackgroundImage property, set child nodes to image tag
        if(nonBackgroundImage === true) {
            if (editableData && editableData.image && editableData.image.src) {
                const {image: {src}} = editableData;
                childNodes = <img key={`${editableData.id}-img`} id={`${editableData.id}-img`} src={`${src}?template_id=${templateId}&template_type=${isPublicTemplate ? 'public' : 'bespoke'}`}/>;
            }
            // There is a non bg image that is not set yet, so display a placeholder.
            else {
                if(editableData && editableData.image && editableData.image.userImageLibraryFolder){
                    // slice off the last (pluralization) character to match the route for the placeholer image
                    const imagePath = editableData.image.userImageLibraryFolder.substring(0, editableData.image.userImageLibraryFolder.length - 1);
                    childNodes = <img key={`${editableData.id}-img`} id={`${editableData.id}-img`} src={`/placeholders/${imagePath}?template_id=${templateId}&template_type=${isPublicTemplate ? 'public' : 'bespoke'}`} />;
                }

            }
        }
        let innerContent = childNodes;

        if(isTextNode && !isLegacyTemplate){
            const textNodeStyles = this.getTextStyles();
            const scalerStyles = this.getScalerStyles();

            innerContent = (
                <div className='scaler' style={scalerStyles}>
                    <span className="valigner" style={textNodeStyles} ref={elem => this.textNodeElement = elem}>
                        {childNodes}
                    </span>
                </div>
            );
        }

        if(isMultilineText(attributes)){
            const themeFontSizeAdjust = styles && typeof styles.fontSizeScale !== 'undefined' ? styles.fontSizeScale : 0;
            const thematicScale = 1 + Number(themeFontSizeAdjust);
            const maxSize = thematicScale * this.state.multilineInitialFontSizePx;
            return (
                <CustomTagName
                    {...attributes}
                    style={containerStyle}
                    ref={this.element}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    data-mason-is-visible={isVisible}
                >
                    <Textfit
                        mode="multi"
                        max={maxSize}
                        style={{ width: '100%', height: '100%'}}
                        className="textfit-multiline-container centered-flex"
                    >
                        {innerContent}
                    </Textfit>
                </CustomTagName>
            );
        }


        if(editableData.image && !editableData.image.backgroundSize && nonBackgroundImage === false) {
            zconsole('found one: ', editableData)

            return (
                <CustomTagName
                    {...attributes}
                    style={containerStyle}
                    ref={this.element}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    data-mason-is-visible={isVisible}
                >
                    {innerContent}
                </CustomTagName>
            );
        }

        return (
            <CustomTagName
                {...attributes}
                style={containerStyle}
                ref={this.element}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                data-mason-is-visible={isVisible}
            >
                {innerContent}
            </CustomTagName>
        );
    }
}
