import React from 'react';
import PropTypes from 'prop-types';

import { isPresent, getOverlayPosition } from "../../../business/helpers/utilities";

import './style.scss';

export default class Overlay extends React.Component {

    static propTypes = {
        id: PropTypes.string.isRequired,
        templateType: PropTypes.string.isRequired,
        highlighted: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
        //to make sure it wil rerender on root and editable init
        editableIdKey: PropTypes.string,
        rootIdKey: PropTypes.string,
    };

    state = {
        hovered: false,
    };

    isActive() {
        return this.props.highlighted || this.state.hovered;
    }

    handleMouseEnter = () => this.setState({hovered: true});

    handleMouseLeave = () => this.setState({hovered: false});

    handleClick = () => this.props.onClick();

    render() {
        const {id, templateType, editableIdKey, rootIdKey, isLegacyTemplate} = this.props;
        const opacity = this.isActive() ? 1 : 0;
        const position = getOverlayPosition(id, templateType, isLegacyTemplate);

        if(position.rotationAngle){
            position.transform = `rotate(${position.rotationAngle}deg)`;
        }

        if (!isPresent(position)) {
            return null;
        }

        return (
            <div
                key={editableIdKey + rootIdKey}
                className="highlight"
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                style={{
                    ...position,
                    opacity,
                }}
            >
                <div className="highlight-icon" onClick={this.handleClick}>
                    <i className="fa fa-pencil"/>
                </div>
            </div>
        );
    }
}
