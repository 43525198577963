import { connect } from 'react-redux';

import FontSizeFormField from './component';

import {themeFontScaleReset, themeFontScaleUpdated} from "../../../actions/interfaceActions";
import {themeActiveFontSizeSelector} from "../../../selectors/theme";

const mapStateToProps = (state, ownProps) => ({
    fontSizeValue: themeActiveFontSizeSelector(state, ownProps),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    handleFontSizeChange: (e) => dispatch(themeFontScaleUpdated(ownProps.name, parseFloat(e.target.value))),
    handleFontSizeReset: () => dispatch(themeFontScaleReset(ownProps.name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FontSizeFormField);
