import axios from 'axios';

import { AUTH_TOKEN } from '../../config';

export const updateTemplateTheme = ({templateId, theme, staticInfo}) => {
    const { isPublicTemplate, userId } = staticInfo;

    // console.log('updateTemplateTheme hit with theme: ', theme);

    const data = new FormData();
    data.append('authenticity_token', AUTH_TOKEN);
    if(templateId) { data.append('template_id', templateId); }
    if(theme) {
        data.append('template_theme[brand_color_1]', theme.brandColor1);
        data.append('template_theme[brand_color_2]', theme.brandColor2);
        data.append('template_theme[brand_color_3]', theme.brandColor3);
        data.append('template_theme[brand_color_1_contrast]', theme.brandColor1Contrast);
        data.append('template_theme[brand_color_2_contrast]', theme.brandColor2Contrast);
        data.append('template_theme[brand_color_accent]', theme.brandColorAccent);
        data.append('template_theme[brand_font_1]', theme.brandFont1);
        data.append('template_theme[brand_font_2]', theme.brandFont2);
    }

    if(isPublicTemplate && isPublicTemplate === true){
        data.append('template_theme[is_public_template]', 'true');
    }
    else {
        data.append('template_theme[is_public_template]', 'false');
    }

    const headers = {};

    return axios.put(`/template_themes`, data, headers);
};