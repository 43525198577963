import React from 'react';
import PropTypes from 'prop-types';
import LibraryImage from '../LibraryImage/component';
import _ from 'lodash';
import './styles.scss';

export default class ImageList extends React.Component {

    static propTypes = {
        allowUploads: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        onImageSelect: PropTypes.func.isRequired,
        onImageDelete: PropTypes.func,
        onNewClick: PropTypes.func.isRequired,
        onSearchFormSubmit: PropTypes.func.isRequired,
        images: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                image: PropTypes.object.isRequired
            })
        ).isRequired,
        subfolders: PropTypes.objectOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                slug: PropTypes.string.isRequired
            })
        ),
        deletable: PropTypes.bool.isRequired,
        userImageLibraryFolder: PropTypes.string,
        imageCount: PropTypes.number,
        page: PropTypes.number.isRequired,
        perPage: PropTypes.number.isRequired
    };

    initialState = {
        searchTerm: '',
        subfolder: ''
    };

    state = this.initialState;

    componentWillReceiveProps(nextProps) {
        //* Reinitialize state when switching to a different image folder
        if( nextProps.userImageLibraryFolder && this.props.userImageLibraryFolder !== nextProps.userImageLibraryFolder){
            this.setState(this.initialState);
        }
    }

    handleSearchChange = (e) => this.setState({searchTerm: e.target.value});

    handleSearchFormSubmit = (e) => {
        e.preventDefault();
        this.props.onSearchFormSubmit(this.state.searchTerm);
    }

    handleFilterChange = e => {
        this.setState({ subfolder: e.target.value });
    }

    /**
     * 7/29/21 -- Commenting out a lot of this function out for now because we're searching in the back-end
     */
    filteredImages = () => this.props.images.filter((image) => {
        const { subfolder } = this.state;
        let hasThisSubfolder = true;
        if(subfolder){
            const { user_image_library_subfolders } = image;
            hasThisSubfolder = _.size(user_image_library_subfolders) && _.find(user_image_library_subfolders, { 'slug': subfolder})
        }
        return hasThisSubfolder;


        // const name = image.name.toLowerCase();
        // const searchTerm = this.state.searchTerm.toLowerCase();
        // return name.search(searchTerm) !== -1 && hasThisSubfolder;
    });

    renderImages = () => this.filteredImages().filter((image) => !image.removed)
        .sort((a, b) => b.id - a.id)
        .map(
            (image, i) => <LibraryImage key={image.id}
                                     id={image.id}
                                     dataCy={i}
                                     name={image.name}
                                     url={image.image.med.url}
                                     onSelect={this.props.onImageSelect}
                                     onDelete={this.handleDelete}
                                     deletable={this.props.deletable}
            />
        );

    renderNewButton = () => this.props.allowUploads ?
        <button className="btn btn-primary px-3" onClick={this.props.onNewClick}>Add New</button> :
        null;

    renderFiltersDropdown = () => {
        if(_.size(this.props.subfolders)){
            const optionItems = _.map(this.props.subfolders, subfolder => {
                return <option key={subfolder.slug} value={subfolder.slug}>{subfolder.name}</option>;
            });
            return (
                <div className="row">
                    <div className="col form-inline justify-content-between mt-3">
                        <label id="image-library-filters-label" htmlFor="image-library-filters">Filter Images:</label>
                        <select id="image-library-filters" className="form-control col ml-1" onChange={this.handleFilterChange}>
                            <option value="">Show all</option>
                            {optionItems}
                        </select>
                    </div>
                </div>
            );
        }
        return null;
    }

    handleReset = () => {
        this.setState({searchTerm: ''});
        this.props.onSearchFormSubmit('');
    }

    handleDelete = (imageId) => this.props.onImageDelete(imageId);

    render() {
        const { userImageLibraryFolder, loading } = this.props;
        const libraryLabel = userImageLibraryFolder && userImageLibraryFolder ? <div className="row"><div className="col"><h6 className="sidebar-header uploads-header">Your Uploads in {userImageLibraryFolder}</h6></div></div> : null;
        return (
            <div>
                {libraryLabel}
                <div className="search-container">
                    <fieldset className="form-group">
                        <div className="d-flex">
                            <div className="flex-grow mr-2">
                                <div style={{position: 'relative'}}>
                                    <form id="image-search-form" className="input-group" onSubmit={this.handleSearchFormSubmit}>
                                        <input
                                            type="text"
                                            id="search-input"
                                            className="form-control px-3"
                                            placeholder="Search" onChange={this.handleSearchChange}
                                            value={this.state.searchTerm}
                                        />
                                        {!loading && this.state.searchTerm ? (
                                            <div className="search-cancel d-flex align-items-center">
                                                <div className="icon-container d-flex align-items-center" onClick={this.handleReset}>
                                                    <i className="fal fa-times" />
                                                </div>
                                            </div>
                                        ) : ''}
                                        <div>
                                           <button type="submit" className="submit btn btn-secondary" disabled={loading}><div className="fal fa-search" /></button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                            <div className="col-auto text-right">
                                {this.renderNewButton()}
                            </div>
                        </div>
                        {this.renderFiltersDropdown()}
                    </fieldset>
                </div>

                <div className="row row-narrow image-library-list">
                    {!loading ? (
                        this.filteredImages().length ? this.renderImages() : <div className="col text-center"><p style={{ textTransform: 'none' }}>No images found.</p></div>
                    ) : ''}
                </div>
            </div>
        );
    }
}
