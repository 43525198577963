import { connect } from 'react-redux';

import EditableElementsForm from './component';
import { editableIdSelector } from '../../../selectors/editableElement';

const mapStateToProps = (state) => ({
    editableElementsId: editableIdSelector(state),
});

export default connect(mapStateToProps)(EditableElementsForm);
