import { takeEvery, put } from 'redux-saga/es/effects';

import * as mason_user_upload from '../../services/mason/resource/userUpload/actions';
import * as business from '../actions/businessActions';
import * as ui from '../../interface/actions/interfaceActions';

export default function* () {
    yield takeEvery(business.USER_UPLOADS_REQUESTED, function* (action) {
        yield put(mason_user_upload.userUploadsRequested(action.payload));
    });

    yield takeEvery(business.USER_UPLOADS_REQUEST_SUCCEEDED, function* (action) {
        yield put(business.updateEntitiesRequested(action.payload));
    });

    yield takeEvery(business.USER_UPLOADS_REQUEST_FAILED, function* (action) {
        yield put(ui.getLibraryImagesRequestFailed(action.payload));
    });

    yield takeEvery(business.CREATE_USER_UPLOAD_REQUESTED, function* (action) {
        yield put(mason_user_upload.createUserUploadRequested(action.payload));
    });

    yield takeEvery(business.CREATE_USER_UPLOAD_SUCCEEDED, function* (action) {
        yield put(business.updateEntitiesRequested(action.payload));

        const { libraryImage } = action.payload;
        const imageId = Object.keys(libraryImage)[0];
        yield put(ui.userUploadFormSucceeded(imageId));
    });
    yield takeEvery(business.CREATE_USER_UPLOAD_FAILED, function* (action) {
        yield put(ui.userUploadFormFailed(action.payload));
    });

    yield takeEvery(business.DELETE_USER_UPLOAD_REQUESTED, function* (action) {
        yield put(mason_user_upload.deleteUserUploadRequested(action.payload));
    });
    yield takeEvery(business.DELETE_USER_UPLOAD_SUCCEEDED, function* (action) {
        const entity = {id: action.payload, type: 'libraryImage'};
        yield put(business.deleteEntityRequested(entity));
    });
    yield takeEvery(business.DELETE_USER_UPLOAD_FAILED, function* (action) { });
}
