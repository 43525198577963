import React from 'react';
import PropTypes from 'prop-types';

const TemplateText = props => props.text;

TemplateText.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]).isRequired,
    // text: PropTypes.string.isRequired,
};

export default  TemplateText;
