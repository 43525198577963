import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const DownloadButton = (props) => {
  const {downloading, handleDownloadButtonClicked} = props;

  return (
      <button className={classnames('btn btn-secondary text-nowrap', {disabled: downloading})} onClick={handleDownloadButtonClicked}>
          <i className="fa fa-cloud-download"/> Download
      </button>
  );
};

DownloadButton.propTypes = {
    downloading: PropTypes.bool.isRequired,
    handleDownloadButtonClicked: PropTypes.func.isRequired,
};

export default DownloadButton;
