import React from 'react';
import PropTypes from 'prop-types';

import TemplateRootElement from '../TemplateRootElement/container';
import TemplateStyleComponent from '../TemplateStyleComponent/container';

const Template = (props) => {
    const {hasDomData, templateType, isLegacyTemplate} = props;

    return (
        <div style={{all: 'initial'}} className={`template-component ${isLegacyTemplate ? 'legacy-template' : ''}`}>
            <TemplateStyleComponent />
            {hasDomData && <TemplateRootElement key={templateType}/>}
        </div>
    );
};

Template.propTypes = {
    hasDomData: PropTypes.bool.isRequired,
    templateType: PropTypes.string.isRequired,
};

export default Template;
