import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import {camelCase, size} from 'lodash';
import './styles.scss';
import Range from "../Range/component";
import {camelCaseToWords} from "../../helpers/labels";

export default class RangeStylizedControl extends React.Component {

    static propTypes = {
        onChange: PropTypes.func.isRequired,
        onReset: PropTypes.func.isRequired,
        value: PropTypes.number.isRequired,
        min: PropTypes.number,
        max: PropTypes.number,
        label: PropTypes.string,
        rangeLabels: PropTypes.array,
        id: PropTypes.string,
        name: PropTypes.string
    };

    static defaultProps = {
        min: -1,
        max: 1,
        step: 0.01,
        label: '',
        rangeLabels: []
    };

    input = React.createRef();

    handleChange = e => {
        this.props.onChange(e);
    };

    resetRange = e => {
        this.props.onReset(e);
    };

    render() {
        const { value, label, min, max, step, rangeLabels, id } = this.props;
        return (
            <div className="range-stylized-control mt-1">
                <div className="d-flex justify-content-between align-items-center">
                    <span>{ label }</span>
                    <span className="pseudo-link" onClick={this.resetRange}><i className="fal fa-undo mr-1" />Reset</span>
                </div>

                <Range
                    className={classNames('form-control')}
                    value={value}
                    onChange={this.handleChange}
                    min={min}
                    max={max}
                    step={step}
                    id={id}
                    name={name}
                />

                <div className="alignment-lines d-flex justify-content-between">
                    {size(rangeLabels) && rangeLabels.map((label) => (
                        <div className="alignment-line" key={camelCase(label)}>
                            <span>{label}</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
