import { takeEvery, put } from 'redux-saga/es/effects';

import * as interfaceAutoSaveActions from '../actions/autoSaveActions';

//TODO not sure where it should be? in the business or interface listeners?
export default function* () {
    yield takeEvery(interfaceAutoSaveActions.TEMPLATE_STATE_DIRTY, function*() { yield put(interfaceAutoSaveActions.stateModifierTemplateStateDirty()); });
    yield takeEvery(interfaceAutoSaveActions.TEMPLATE_STATE_SAVING, function*(){ yield put(interfaceAutoSaveActions.stateModifierTemplateStateSaving()); });
    yield takeEvery(interfaceAutoSaveActions.TEMPLATE_STATE_SAVING_SUCCESS,   function*(){ yield put(interfaceAutoSaveActions.stateModifierTemplateStateSavingSuccess()); });
    yield takeEvery(interfaceAutoSaveActions.TEMPLATE_STATE_SAVING_FAIL,   function*(action){ yield put(interfaceAutoSaveActions.stateModifierTemplateStateSavingFail(action.payload.error)); });
}
