import React from 'react';
import PropTypes from 'prop-types';
import {checkNested, getNested, roundToTwo} from "../../../image_editor/js/business/helpers/utilities";
import { getTextAdjustments, getTextMetrics } from "../../../image_editor/js/business/helpers/normalizeFonts";

export default class FontElement extends React.Component {

    static propTypes = {
        styles: PropTypes.object,
        globalOffsetValue: PropTypes.number.isRequired,
        globalScaleValue: PropTypes.number.isRequired,
    };

    static defaultProps = {
        height: 'auto'
    }

    element = React.createRef();
    textNodeElement = null; // Will be a ref for the inner text node
    parentRef = null; // Will be a ref for the parent node

    state = {
        isMounted: false,
        currentlyCalculatingAdjustments: false,
        adjustments: {},
        metrics: null
    };

    async componentDidMount() {
        this.setState({
            isMounted: true,
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        const currentMetrics = checkNested(this.state, 'metrics') ? getNested(this.state, 'metrics') : null;
        const fundamentalFontScale = checkNested(this.state, 'adjustments', 'fundamentalScale') ? getNested(this.state, 'adjustments', 'fundamentalScale') : undefined;
        const noOverflowFontScale = checkNested(this.state, 'adjustments', 'overflowScale') ? getNested(this.state, 'adjustments', 'overflowScale') : undefined;

        if(this.state.isMounted === true){
            if(this.state.currentlyCalculatingAdjustments === false){
                if(!currentMetrics || typeof fundamentalFontScale === 'undefined' || typeof noOverflowFontScale === 'undefined'){
                    this.setState({ currentlyCalculatingAdjustments: true });
                    const textOverride = false;
                    // const textOverride = 'Hxy 1234567890 abcdefghijklmnopqrstuvwxyz ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                    const metrics = await getTextMetrics(this.element.current, textOverride);
                    const adjustments = await getTextAdjustments(this.textNodeElement, this.element.current, this.parentRef, metrics);

                    this.setState({
                        metrics,
                        adjustments,
                        currentlyCalculatingAdjustments: false
                    });
                }
            }
        }
    }

    getContainerStyles(){
        const { styles } = this.props;
        // let containerStyle = typeof styles !== 'undefined' && styles || {};
        //
        // const existingMarginTop = styles.marginTop ? styles.marginTop : 0;
        if(this.state.isMounted === true) {
            let containerStyle = {...styles};
            const existingMarginTop = 0;
            containerStyle.marginTop = checkNested(this.state, 'adjustments', 'textContainerNode', 'marginTop') && !isNaN(this.state.adjustments.textContainerNode.marginTop) ? roundToTwo(this.state.adjustments.textContainerNode.marginTop + existingMarginTop) : 0;
            if (checkNested(this.state, 'adjustments', 'textContainerNode', 'fontSize')) {
                containerStyle.fontSize = this.state.adjustments.textContainerNode.fontSize;
            }

            return containerStyle;
        }
        return {};
    }

    getScalerStyles(){
        const { styles, globalScaleValue } = this.props;
        // const fundamentalFontScale = checkNested(this.state, 'adjustments', 'fundamentalScale') ? getNested(this.state, 'adjustments', 'fundamentalScale') : 1;
        const fundamentalFontScale = 1;
        const overflowScale = checkNested(this.state, 'adjustments', 'overflowScale') ? getNested(this.state, 'adjustments', 'overflowScale') : 1;

        let scalerStyle = { transform: 'scale(1)' }

        // const elementScaleToUse = overflowScale < fundamentalFontScale ? overflowScale : fundamentalFontScale;
        // const elementScaleToUse = 1;
        // const newScale = Number(elementScaleToUse) + Number(globalScaleValue);
        const newScale = 1 + Number(globalScaleValue);
        if(!isNaN(newScale) && Number(newScale) > 0){
            scalerStyle = { transform: `scale(${newScale})` }
        }

        return scalerStyle;
    }

    getTextStyles(){
        const { globalOffsetValue } = this.props;
        let textNodeStyles = {};
        if(this.state.isMounted === true){
            const nativeTextOverride = checkNested(this.state, 'adjustments', 'textNode', 'verticalAlignPx') ? this.state.adjustments.textNode.verticalAlignPx : 0;
            const textHeight = getNested(this.state, 'metrics', 'calculated', 'textHeight');
            const userTextoverride = typeof globalOffsetValue !== "undefined" ? Number(globalOffsetValue) * textHeight : 0;
            const finalTextOverride = roundToTwo(nativeTextOverride + userTextoverride);
            textNodeStyles.verticalAlign = `${finalTextOverride}px`;
        }

        return textNodeStyles;
    }

    render() {
        return (
            <div className="inner-content centered-flex" ref={elem => this.parentRef = elem} style={{height: this.props.height}}>
                <div className="text-item" style={{fontSize: this.props.height, ...this.getContainerStyles()}} ref={this.element}>
                    <div className='scaler' style={this.getScalerStyles()}>
                        <span className="valigner" style={this.getTextStyles()} ref={elem => this.textNodeElement = elem}>
                            {this.props.children}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
