import { UPDATE_DEFAULT_THEME } from "../actions/stateModifierActions";

export const INITIAL_STATE = {
  colors: {},
  fonts: {},
  fontSizes: {},
};

export default (state = INITIAL_STATE, action)=>{
  const {type, payload} = action;

  switch (type) {
    case UPDATE_DEFAULT_THEME:
      return {
        ...state,
        ...payload.newTheme
      };
    default:
      return state;
  }
};