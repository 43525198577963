import React from 'react';
import PropTypes from 'prop-types';

import Overlay from '../Overlay/container';

import './style.scss';

const Overlays = (props) => {
    const {editableId} = props;
    const highlights = editableId && editableId.map((id) => <Overlay key={id} id={id}/>);

    return (
        <div className="highlight-wrapper">
            {highlights}
        </div>
    );
};

Overlays.propTypes = {
    editableId: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Overlays;
