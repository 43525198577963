import { all } from 'redux-saga/effects';
import imageLibraryListener from './resource/imageLibrary/listener';
import userUploadListener from './resource/userUpload/listener';
import templateSnapshotListener from './resource/templateSnapshot/listener';
import templateThemeListener from './resource/templateTheme/listener';

export default function*(action) {
    yield all([
        imageLibraryListener(),
        userUploadListener(),
        templateSnapshotListener(),
        templateThemeListener(),
    ]);
}
