import { takeEvery, put } from 'redux-saga/es/effects';

import * as ui from '../actions/interfaceActions';
import * as stateModifier from '../actions/stateModifierActions';

export default function* () {
    yield takeEvery(ui.BACK_BUTTON_CLICKED, function* () { yield put(ui.pageBackRequested()); });
    yield takeEvery(ui.PAGE_FORWARD_REQUESTED, function* (action) {
        yield put(stateModifier.pageForward(action.payload.content, action.payload.contentProps));
    });
    yield takeEvery(ui.PAGE_BACK_REQUESTED, function* () {
        yield put(stateModifier.unhighlightAllEditables());
        yield put(stateModifier.pageBack());
    });
    yield takeEvery(ui.RESET_SIDEBAR_REQUESTED, function* () {
        yield put(stateModifier.unhighlightAllEditables());
        yield put(stateModifier.frontPage());
    });
}
