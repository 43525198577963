import { delay } from 'redux-saga';
import { take, takeEvery, put, call, race } from 'redux-saga/es/effects';

import * as ui from '../actions/interfaceActions';
import * as stateModifier from '../actions/stateModifierActions';
import { setFlash, clearFlash, REMOVE_FLASH_ELEMENT } from '../actions/flashActions';
import { getUniqueId } from '../../business/helpers/getId';
import { FLASH } from '../constants';

export function* showError(error) {
    const id = yield call(getUniqueId);
    const flashData = {
        id,
        flashType: FLASH.types.alert,
        message: typeof error === 'string' ? error : error.message,
    };
    yield put(stateModifier.hideSpinner());
    yield put(setFlash(flashData));
    yield race({
        timeout: delay(FLASH.timeout),
        close: call(function* () {
            while (true) {
                const {payload} = yield take(REMOVE_FLASH_ELEMENT);

                if (payload.id === id) { return; }
            }
        }),
    });
    yield put(clearFlash(id));
}

export default function* () {
    yield takeEvery(ui.ERRORS_RECEIVED, function* (action) {
        const error = action.payload.error;

        if (Array.isArray(error)) {
            for (const currError of error) {
                yield call(showError, currError);
            }
        } else {
            yield call(showError, error);
        }
    });
}
