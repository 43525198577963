import React from 'react';
import PropTypes from 'prop-types';
import domToImage from 'dom-to-image';
import FileSaver from 'file-saver';
import base64ToBlob from "b64-to-blob";
import checkBrowser from "check-browser";

import { createImageDownload as downloadRemotely } from '../../../services/mason/resource/templateSnapshot/endpoints';

export default class Downloadable extends React.Component {

    static propTypes = {
        downloading: PropTypes.bool,
        filename: PropTypes.string.isRequired,
        handleDownloadCompleted: PropTypes.func.isRequired,
        children: PropTypes.object,
    };

    download = React.createRef();

    componentDidUpdate() {
        if (this.props.downloading) {
            this.handleDownload();
        }
    }

    downloadLocally(scale) {
        let props = {};

        if (scale) {
            props = {
                width: this.download.current.clientWidth * scale,
                height: this.download.current.clientHeight * scale,
                style: {
                    'transform': `scale(${scale})`,
                    'transform-origin': 'top left'
                }
            };
        }

        domToImage.toBlob(this.download.current, props).then(this.downloadAsImage);
    }

    downloadRemotely() {
        downloadRemotely(this.download.current.outerHTML).then(this.handleRemoteDownloadResponse);
    }

    handleRemoteDownloadResponse = (e) => this.downloadAsImage(base64ToBlob(e.data.base64, 'image/png'));

    handleLocalDownloadResponse = (blob) => this.downloadAsImage(blob);

    downloadAsImage = (blob) => {
        FileSaver.saveAs(blob, (this.props.filename || 'image.png'));
        this.props.handleDownloadCompleted();
    };

    localDownloadSupported() {
        const minimumBrowsers = {
            chrome: 1,
            firefox: 2,
            opera: 2
        };

        return checkBrowser(minimumBrowsers);
    }

    handleDownload() {
        return this.localDownloadSupported() ? this.downloadLocally() : this.downloadRemotely();
    }

    render() {
        const {children} = this.props;

        return (
            <div>{React.cloneElement(children, { ref: this.download })}</div>
        );
    }
}
