import React from 'react';
import PropTypes from "prop-types";
import FontElement from "./FontElement";

export default class FontDemoScreen extends React.Component {
    static propTypes = {
        globalOffsetValue: PropTypes.number.isRequired,
        globalScaleValue: PropTypes.number.isRequired,
        fontName: PropTypes.string.isRequired
    }

    // state = {
    //     globalOffsetValue: 0,
    //     globalScaleValue: 0,
    // }

    _getDefaultStyles = () => {
        return {
            margin:20,
            fontFamily: this.props.fontName
        }
    }

    render() {
        const { globalOffsetValue, globalScaleValue } = this.props;
        const styles = this._getDefaultStyles();
        return(
            <div className="font-configurator-demo">
                <div className="outer">
                    <FontElement
                        height={160}
                        styles={{ fontFamily: this.props.fontName }}
                        globalOffsetValue={globalOffsetValue}
                        globalScaleValue={globalScaleValue}
                    >
                        Hxy
                    </FontElement>
                    <FontElement
                        height={55}
                        styles={{ fontFamily: this.props.fontName }}
                        globalOffsetValue={globalOffsetValue}
                        globalScaleValue={globalScaleValue}
                    >
                        Hxy 12345 groups quit ZdEnyso
                    </FontElement>
                    <FontElement
                        height={40}
                        styles={{ fontFamily: this.props.fontName }}
                        globalOffsetValue={globalOffsetValue}
                        globalScaleValue={globalScaleValue}
                    >
                        NTS: Quickly View The 6,789 mixed Rugby Jacket zippers.
                    </FontElement>
                    <FontElement
                        height={50}
                        styles={{ fontFamily: this.props.fontName }}
                        globalOffsetValue={globalOffsetValue}
                        globalScaleValue={globalScaleValue}
                    >
                        Eat frijoles on 14.30.2025
                    </FontElement>

                </div>
            </div>
        );
    }
}