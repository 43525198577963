import { UPDATE_ROOT_DATA } from "../actions/stateModifierActions";

import { getUniqueId } from "../helpers/getId";

//root template element additional data
const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case UPDATE_ROOT_DATA: {
            const rootData = payload.rootData ?
                {...payload.rootData, idKey: getUniqueId()} :
                {idKey: getUniqueId()};

            return {
                ...state,
                ...rootData,
            };
        }
        default:
            return state;
    }
};
