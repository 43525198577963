import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './style.scss';
import {zconsole} from "../../../business/helpers/utilities";

export default class Scalable extends React.Component {

    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]).isRequired,
        templateType: PropTypes.string.isRequired,
    };

    // TODO move state to the store
    state = {
        initialized: false,
        initialWrapperWidth: null,
        initialWrapperHeight: null,
        initialWrappedHeight: null,
        wrapperHeight: null,
        wrapperWidth: null,
        wrappedHeight: null,
        wrappedWidth: null,
    };

    wrapper = React.createRef();

    wrapped = React.createRef();

    componentDidMount() {
        this.updateDimensions();
        // TODO 11/15/21 -- I think maybe with the window.load event listener I don't need the timeout hack anymore? (Cuz i think it wasn't working anyway)
        // setTimeout(this.updateDimensions, 1000); // TODO manage timeout hack
        window.addEventListener("resize", this.updateDimensions);
        window.addEventListener('load', this.updateDimensions);
    }

    componentDidUpdate(prevProps, prevState) {
        let shouldBreak = true;

        if(!_.isEqual(this.state, prevState)){
            shouldBreak = false;
        }

        if (prevProps.templateType !== this.props.templateType) {
            shouldBreak = false;
        }

        if(shouldBreak === true){
            return;
        }

        setTimeout(this.updateDimensions, 100); // Without the timeout it doesn't actually replace it.
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        const wrapper = this.wrapper.current;
        const wrapped = this.wrapped.current;

        //* Initial Value Setting
        if(this.state.initialized === false){
            this.setState({
                initialized: true,
                initialWrapperWidth: wrapper.clientWidth,
                initialWrapperHeight: wrapper.clientHeight,
                initialWrappedWidth: wrapped.clientWidth,
                initialWrappedHeight: wrapped.clientHeight,
            });
        }
        //* Other Value Updating
        else {
            this.setState({
                wrapperHeight: wrapper.clientHeight,
                wrapperWidth: wrapper.clientWidth,
                wrappedHeight: wrapped.clientHeight,
                wrappedWidth: wrapped.clientWidth,
            });
        }
    };

    scale = () => {
        const { wrappedWidth, initialized, wrappedHeight, wrapperHeight, wrapperWidth} = this.state;

        if (!initialized || !wrapperHeight || !wrapperWidth ||  !wrappedHeight || !wrappedWidth ) {
            return 1;
        }

        //* Scale to stretch to the container height by default
        let scale = wrapperHeight / wrappedHeight; //* New style to get it to full height
        const scaledWrappedWidth = scale * wrappedWidth;

        //* The scaled width was wider than the container's width so we need to scale by width instead of height
        if(wrapperWidth < scaledWrappedWidth){
            scale = wrapperWidth / wrappedWidth;
        }

        return scale;
    };

    children = () => {
        const {children} = this.props;
        const { initialized} = this.state;

        if (!initialized) {
            return <div className="awaiting-initialization" />;
        }

        return children;
    }

    render() {
        const scale = this.scale();

        return (
            <div
                className="scalable-wrapper"
                ref={this.wrapper}
            >
                <div
                    className="wrapped-element"
                    style={{transform: `translate(0%, 0%) scale(${scale})`}}
                    ref={this.wrapped}
                >
                    {this.children()}
                </div>
            </div>
        );
    }
}
