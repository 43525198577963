import {take, takeEvery, takeLatest, put, race} from 'redux-saga/es/effects';

import * as ui from '../actions/interfaceActions';
import * as templateUi from '../actions/templateActions';
import * as stateModifier from '../actions/stateModifierActions';
import * as business from '../../business/actions/businessActions';
import * as autosave from '../actions/autoSaveActions';
import { SIDEBAR_CONTENT_CODES, FORM_NAMES } from "../constants";

export default function* () {
    yield takeEvery(ui.GET_LIBRARY_IMAGES_REQUEST_FAILED,         function*(action){
        // yield put(stateModifier.updateFormState(FORM_NAMES.userUpload, false));
        yield put(ui.errorsReceived(action.payload));
    });
    yield takeEvery(ui.USER_UPLOAD_FORM_SUBMITTED,      function*(action){
        yield put(stateModifier.updateFormState(FORM_NAMES.userUpload, true));
        yield put(business.createUserUploadRequested(action.payload));
    });
    yield takeEvery(ui.USER_UPLOAD_FORM_FAILED,         function*(action){
        yield put(stateModifier.updateFormState(FORM_NAMES.userUpload, false));
        yield put(ui.errorsReceived(action.payload));
    });
    yield takeEvery(ui.USER_UPLOAD_DELETE_CLICKED,      function*(action){ yield put(business.deleteUserUploadRequested(action.payload)); });
    yield takeLatest(ui.NEW_UPLOAD_BUTTON_CLICKED,      function*(action) {
        yield put(ui.pageForwardRequested(SIDEBAR_CONTENT_CODES.uploadForm));

        const { id } = action.payload;
        const { uploaded } = yield race({
            back: take(ui.PAGE_BACK_REQUESTED),
            reset: take(ui.RESET_SIDEBAR_REQUESTED),
            forward: take(ui.PAGE_FORWARD_REQUESTED),
            templateToggle: take(templateUi.TOGGLE_TEMPLATE_TYPE_CLICKED),
            uploaded: take(ui.USER_UPLOAD_FORM_SUCCEEDED),
        });

        if (!uploaded) { return; }

        yield put(stateModifier.updateFormState(FORM_NAMES.userUpload, false)); // TODO this is what's causing the error
        const { libraryImageId } = uploaded.payload;
        yield put(ui.autoCropRequested(id, libraryImageId));
        yield put(ui.resetSidebarRequested());
        yield put(autosave.templateAutosave());
    });
}
