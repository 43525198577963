import { delay } from "redux-saga";
import { take, takeEvery, takeLatest, put, select, race } from 'redux-saga/es/effects';

import { TEMPLATE_AUTOSAVE_TIMER } from "../constants";
import * as businessAutoSaveActions from "../actions/autoSaveActions";
import * as interfaceAutoSaveActions from '../../interface/actions/autoSaveActions';
import * as mason_template_snapshot from '../../services/mason/resource/templateSnapshot/actions';
import * as business from '../actions/businessActions';
import * as ui from '../../interface/actions/interfaceActions';
import * as stateModifiers from '../actions/stateModifierActions';
import {
    templateIdSelector,
    allTemplateDataSelector,
    staticInfoSelector,
} from "../../interface/selectors/template";
import {defaultThemeSelector, themeSelector} from "../../interface/selectors/theme";
import {defaultThemeExists, getCustomizedThemeDataVsDefault} from "../helpers/parseInitializeData";

let globalDelay = null;

export default function* () {

    yield takeEvery([
        // stateModifiers.UPDATE_ROOT_ELEMENT_POSITION,
        stateModifiers.UPDATE_EDITABLE_ELEMENT_POSITION,
        stateModifiers.UPDATE_TEMPLATE_EDITABLE_TEXT,
        stateModifiers.UPDATE_TEMPLATE_EDITABLE_VISIBILITY,
        interfaceAutoSaveActions.TEMPLATE_AUTOSAVE,
    ], function*() { yield put(businessAutoSaveActions.templateAutosaveRequested()); });
    yield takeLatest(stateModifiers.UPDATE_TEMPLATE_EDITABLE_IMAGE, function* () {
        const { cancel } = yield race({
            save: take(ui.CROP_BUTTON_CLICKED),
            cancel: take(ui.CROP_CANCEL_BUTTON_CLICKED),
        });

        if (cancel) { return; }

        yield put(businessAutoSaveActions.templateAutosaveRequested());
    });
    yield takeLatest(businessAutoSaveActions.TEMPLATE_AUTOSAVE_REQUESTED, function*(action) {
        yield put(interfaceAutoSaveActions.templateStateDirty());

        if (!globalDelay) {
            globalDelay = Date.now();
        }

        if (!action.payload.immediately && globalDelay + TEMPLATE_AUTOSAVE_TIMER * 3 > Date.now()) {
            yield delay(TEMPLATE_AUTOSAVE_TIMER);
        }

        yield put(business.createTemplateSnapshotRequested());
        globalDelay = null;
    });

    yield takeEvery(business.UPDATE_DEFAULT_THEME_REQUESTED, function*(){
        const themeData = yield select(themeSelector);
        yield put(stateModifiers.updateDefaultTheme(themeData));
    });

    yield takeEvery(business.CREATE_TEMPLATE_SNAPSHOT_REQUESTED, function*(){
        const templateId = yield select(templateIdSelector);
        const templateData = yield select(allTemplateDataSelector);
        const staticInfo = yield select(staticInfoSelector);
        const themeData = yield select(themeSelector);
        const defaultThemeData = yield select(defaultThemeSelector);
        templateData['theme'] = yield getCustomizedThemeDataVsDefault(themeData, defaultThemeData);
        // console.log('customizedThemeDataVsDefault', customizedThemeDataVsDefault);

        //* For the code tester, we don't want to save a snapshot since it doesn't actually live in the DB
        if(!staticInfo.isCodeTester) {
            //* If there's not a default theme, we want to save the current template theme as the account's default theme.
            if(!defaultThemeExists(defaultThemeData)){
                yield put(business.saveTemplateThemeRequested());
            }

            yield put(interfaceAutoSaveActions.templateStateSaving());
            yield put(mason_template_snapshot.createTemplateSnapshotRequested(templateId, templateData, staticInfo));
        }
    });
    yield takeEvery(business.CREATE_TEMPLATE_SNAPSHOT_SUCCEEDED, function* () { yield put(interfaceAutoSaveActions.templateStateSavingSuccess()); });
    yield takeEvery(business.CREATE_TEMPLATE_SNAPSHOT_FAILED,   function*(action){
        const error = action.payload.error;
        yield put(ui.errorsReceived(error));
        yield put(interfaceAutoSaveActions.templateStateSavingFail(error));
    });
}
