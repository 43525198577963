import Dropzone from "dropzone";
Dropzone.autoDiscover = false;
document.addEventListener('turbolinks:load', (event)=>{
    jQuery( document ).ready(function( $ ) {
        const $alertContainer = $('.dropzone-alerts');
        const dropzone = $('.dropzone');
        let errorsQty = 0;
        if(dropzone.length){
            var myDropzone = new Dropzone("form.dropzone", {
                acceptedFiles: 'image/*',
                error: (file, error) => {
                    errorsQty++;
                    if($alertContainer.length){
                        console.error('error with file', file);
                        console.error('error', error);
                        $alertContainer.prepend(`<div class="alert alert-danger">Error with "${file.name}": ${error}</div>`);
                    }
                },
                queuecomplete: function (data, response) {
                    const filesWithErrors = this.getRejectedFiles();
                    if(!filesWithErrors.length && !errorsQty){
                        if($alertContainer.length){
                            $alertContainer.prepend(`<div class="alert alert-success">All images were successfully uploaded! Reloading the page...</div>`);
                        }
                        window.setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    }
                }
            });
        }
    });
});