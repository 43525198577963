// import 'babel-polyfill';
import "core-js/stable";
import "regenerator-runtime/runtime";

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import Root from '../interface/widgets/Root';

import initializeStore from './store/initializeStore';
import { parseInitializeData } from '../business/helpers/parseInitializeData';

const renderPack = () => {
    const dataNode = document.getElementById('component-image-editor');
    const containerNode = document.getElementById('image-editor-container');

    if (!dataNode || !containerNode) {return; }

    const initializeData = JSON.parse(dataNode.getAttribute('data'));
    const data = parseInitializeData(initializeData);
    const store = initializeStore(data);

    render(<Provider store={store}><Root/></Provider>, containerNode);
};

document.addEventListener('turbolinks:load', renderPack);
