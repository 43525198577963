import React from 'react';
import PropTypes from 'prop-types';

import FlashElement from '../FlashElement/container';

import { isPresent } from "../../../business/helpers/utilities";

const Flash = (props) => {
    const {flash} = props;
    const flashElements = isPresent(flash) && flash.map(({id, message, flashType}) =>
        <FlashElement key={id} id={id} flashType={flashType} message={message}/>
    );

    return (
        <div>
            {flashElements}
        </div>
    );
};

Flash.propTypes = {
    flash: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        flashType: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
    })).isRequired,
};

export default Flash;
