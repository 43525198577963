import React from 'react';
import PropTypes from "prop-types";
import ColorPicker from "../../../color_picker/js/color_picker";
import ContrastColorElement from "./ContrastColorElement";
import ContrastColorPickers from "./ContrastColorPickers";

export default class UserColorConfigurator extends React.Component {
    static propTypes = {
        primaryColorField: PropTypes.object.isRequired,
        primaryColorContrastField: PropTypes.object.isRequired,
        secondaryColorField: PropTypes.object.isRequired,
        secondaryColorContrastField: PropTypes.object.isRequired,
        primaryColorValue: PropTypes.string,
        primaryContrastValue: PropTypes.string,
        secondaryColorValue: PropTypes.string,
        secondaryContrastValue: PropTypes.string,
    }

    state = {
        primaryColorValue: null,
        primaryContrastValue: null,
        secondaryColorValue: null,
        secondaryContrastValue: null,
    }

    UNSAFE_componentWillMount() {
        const { primaryColorValue, primaryContrastValue, secondaryColorValue, secondaryContrastValue } = this.props;
        this.setState({
            primaryColorValue,
            primaryContrastValue,
            secondaryColorValue,
            secondaryContrastValue
        });
    }

    _primaryChanged({hex}){
        this.props.primaryColorField.value = hex;
        this.setState({ primaryColorValue: hex });
    }

    _secondaryChanged({hex}){
        this.props.secondaryColorField.value = hex;
        this.setState({ secondaryColorValue: hex });
    }

    _primaryContrastChanged(hex){
        this.props.primaryColorContrastField.value = hex;
        this.setState({ primaryContrastValue: hex });
    }

    _secondaryContrastChanged(hex){
        this.props.secondaryColorContrastField.value = hex;
        this.setState({ secondaryContrastValue: hex });
    }

    render() {
        const { primaryColorValue, primaryContrastValue, secondaryColorValue, secondaryContrastValue } = this.state;
        return(
            <div>
                <div className="row mb-5 large-color-picker">
                    <div className="col-sm-2">
                        <div className="color-picker-container d-inline-flex align-items-center">
                            Primary Color:
                            <ColorPicker
                                value={primaryColorValue}
                                onChange={(val) => this._primaryChanged(val)}
                                name="primaryColor"
                            />
                        </div>
                    </div>
                    <div className="col-sm-10">
                        <ContrastColorPickers
                            label="Primary Color"
                            value={primaryContrastValue}
                            compareValue={primaryColorValue}
                            onChange={(val) => this._primaryContrastChanged(val)}
                        />
                    </div>
                </div>

                <div className="row mb-4 large-color-picker">
                    <div className="col-sm-2">
                        <div className="color-picker-container d-inline-flex align-items-center">
                            Secondary Color:
                            <ColorPicker
                                value={secondaryColorValue}
                                onChange={(val) => this._secondaryChanged(val)}
                                name="secondaryColor"
                            />
                        </div>
                    </div>
                    <div className="col-sm-10">
                        <ContrastColorPickers
                            label="Secondary Color"
                            value={secondaryContrastValue}
                            compareValue={secondaryColorValue}
                            onChange={(val) => this._secondaryContrastChanged(val)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}