import { size } from 'lodash';
const imageIds = (state, libraryCode) =>{
    const ids = state.business.entities.imageLibrary && state.business.entities.imageLibrary[libraryCode] && state.business.entities.imageLibrary[libraryCode].library_images;
    return ids || [];
};

const libraryImages = (state, libraryCode) =>{
    const ids = imageIds(state, libraryCode);
    const imageList = ids.reduce((list, id)=>{
        const image = state.business.entities.libraryImage && state.business.entities.libraryImage[id];

        return image && !image.removed ? [...list, image] : list;
    }, []);
    return imageList;
};

export const userLibraryImageCount = (state, libraryCode) => {
    return size(state.business.entities) &&
        size(state.business.entities.imageLibrary) &&
        size(state.business.entities.imageLibrary[libraryCode]) &&
        state.business.entities.imageLibrary[libraryCode].count ?
            state.business.entities.imageLibrary[libraryCode].count : undefined;
}

export default (state, libraryCode) =>{
        return libraryImages(state, libraryCode);
};
