import { connect } from 'react-redux';

import TemplateStyleComponent from './component';

import { themeFontsArraySelector } from "../../../selectors/theme";

const mapStateToProps = (state) => ({
    themeFonts: themeFontsArraySelector(state),
});

export default connect(mapStateToProps)(TemplateStyleComponent);
