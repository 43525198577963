import { SHOW_SPINNER, HIDE_SPINNER } from "../actions/stateModifierActions";

const INITIAL_STATE = '';

export default (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case SHOW_SPINNER: {
            return payload;
        }
        case HIDE_SPINNER: {
            return INITIAL_STATE;
        }
        default: {
            return state;
        }
    }
};
