import {startCase} from 'lodash';
export const getDisplayTextForThemeColorOrFont = name => {
  switch (name) {
    case 'brandColor1':
      return "Primary Color";
    case 'brandColor1Contrast':
      return "Primary Color Contrast";

    case 'brandColor2':
      return "Secondary Color";
    case 'brandColor2Contrast':
      return "Secondary Color Contrast";

    case 'brandColor3':
      return "Tertiary Color";
    case 'brandColorAccent':
      return 'Accent Color';
    case 'brandFont1':
      return 'Primary Font';
    case 'brandFont2':
      return 'Secondary Font';
    default:
      return camelCaseToWords(name);
  }
};

export const camelCaseToWords = str => {
  return startCase(str);
  // const result = str.replace( /([A-Z])/g, " $1" );
  // return result.charAt(0).toUpperCase() + result.slice(1);
};
