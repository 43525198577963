import React from 'react';
import PropTypes from 'prop-types';
import ImageList from '../ImageList/component';
import _ from 'lodash';

export default class ImageLibrary extends React.Component {

    initial_state = {
        subfolders: {},
        images: [],
        page: 1,
        per_page: 16,
        search_term: undefined,
        loading: true
    }

    state = {
        ...this.initial_state
    }

    static propTypes = {
        id: PropTypes.string.isRequired,
        queryLibraryImages: PropTypes.func.isRequired,
        onSelectImage: PropTypes.func.isRequired,
        onDeleteImage: PropTypes.func,
        onNewUploadButtonClicked: PropTypes.func.isRequired,
        images: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                image: PropTypes.object.isRequired
            })
        ).isRequired,
        libraryCode: PropTypes.string.isRequired,
        userImageLibraryFolder: PropTypes.string,
        deletable: PropTypes.bool.isRequired,
        imageCount: PropTypes.number
    };

    componentDidMount() {
        const { page, per_page, search_term } = this.state;
        const { libraryCode, userImageLibraryFolder} = this.props;
        this.props.queryLibraryImages(libraryCode, userImageLibraryFolder, page, per_page, search_term);
    }

    //* 1/23 The componentdidmount method is not called when switching between 2 image libraries, so we need to do it here and compare the two
    componentWillReceiveProps(nextProps) {
        let { images, userImageLibraryFolder, imageCount } = nextProps;

        // console.log('image library receiving props');
        // console.log('imageCount', imageCount);
        // console.log('images', images);
        // console.log('userImageLibraryFolder', userImageLibraryFolder);

        if(
            this.props.libraryCode !== nextProps.libraryCode ||
            !this.props.userImageLibraryFolder && nextProps.userImageLibraryFolder ||
            this.props.userImageLibraryFolder !== nextProps.userImageLibraryFolder
        ){
            const { page, per_page, search_term } = this.state;
            this.props.queryLibraryImages(nextProps.libraryCode, nextProps.userImageLibraryFolder, page, per_page, search_term);
        }
        else {
            this.setState({ loading: false });
        }

        //* Filter images list for just these images if we're in a user image library folder
        if(userImageLibraryFolder){
            images = _.filter(images, image => {
                const { all_direct_and_indirect_parent_folders } = image;
                let toReturn = false;
                if(_.size(all_direct_and_indirect_parent_folders)){
                    _.forEach(all_direct_and_indirect_parent_folders, folder => {
                        if(folder.slug === userImageLibraryFolder){
                            toReturn = true;
                            return false;
                        }
                    });
                }
                return toReturn;
            });
        }

        //* Create the subfolder object
        let subfolders = {};
        _.forEach(images, image => {
            if(typeof image.user_image_library_subfolders !== "undefined" && _.size(image.user_image_library_subfolders)){
                _.forEach(image.user_image_library_subfolders, subfolder => {
                    if(typeof subfolder.slug !== "undefined"){
                        subfolders = {
                            ...subfolders,
                            [subfolder.slug]: subfolder
                        };
                    }
                });
            }
        });

        this.setState({
            images,
            subfolders
        });
    }

    handleImageClick = (imageSrc) => this.props.onSelectImage(imageSrc);

    handleNewUploadClick = () => this.props.onNewUploadButtonClicked();

    handleDeleteImage = (imageId) => this.props.onDeleteImage(imageId);

    handleLoadMore = () => {
        const { page, per_page, search_term } = this.state;
        const { libraryCode, userImageLibraryFolder} = this.props;
        this.setState({ loading: true });
        this.props.queryLibraryImages(libraryCode, userImageLibraryFolder, page + 1, per_page, search_term);
    };

    handlePrevPage = (e) => {
        e.preventDefault();
        const { page, per_page, search_term } = this.state;
        const { libraryCode, userImageLibraryFolder} = this.props;
        const newPage = page - 1;
        this.setState({
            images: [],
            loading: true,
            page: newPage
        });
        this.props.queryLibraryImages(libraryCode, userImageLibraryFolder, newPage, per_page, search_term);
    };

    handleNextPage = (e) => {
        e.preventDefault();
        const { page, per_page, search_term } = this.state;
        const { libraryCode, userImageLibraryFolder} = this.props;
        const newPage = page + 1;
        this.setState({
            images: [],
            loading: true,
            page: newPage
        });
        this.props.queryLibraryImages(libraryCode, userImageLibraryFolder, newPage, per_page, search_term);
    };

    handleSearchFormSubmit = (search_term) => {
        this.setState({
            search_term,
            images: this.initial_state.images,
            loading: true,
            page: 1
        });
        const { per_page } = this.state;
        const { libraryCode, userImageLibraryFolder} = this.props;
        // We always hard-set the page to 1 when the form is resubmitted
        this.props.queryLibraryImages(libraryCode, userImageLibraryFolder, 1, per_page, search_term);
    }

    render() {
        const {libraryCode, deletable, userImageLibraryFolder, imageCount} = this.props;
        const { images, subfolders, page, per_page, loading } = this.state;
        const lowNumber = ((page-1) * per_page) + 1;
        const highNumber = page * per_page <= imageCount ? page * per_page : imageCount;
        return (
            <div>
                <ImageList
                    images={images}
                    onImageSelect={this.handleImageClick}
                    onImageDelete={this.handleDeleteImage}
                    allowUploads={libraryCode === 'user' && userImageLibraryFolder ? true : false} // Don't simplify to `libraryCode === 'user' && userImageLibraryFolder`, as it will return a string.
                    onNewClick={this.handleNewUploadClick}
                    onSearchFormSubmit={this.handleSearchFormSubmit}
                    deletable={deletable}
                    subfolders={subfolders}
                    userImageLibraryFolder={userImageLibraryFolder}
                    imageCount={imageCount}
                    page={page}
                    perPage={per_page}
                    loading={loading}
                />
                <div className={`text-center mt-3 ${!this.state.loading ? 'd-none' : ''}`}>
                    <p><i className="fal fa-spinner fa-spin fa-5x" aria-hidden="true"></i></p>
                    <p>loading...</p>
                </div>
                {!this.state.loading ? (
                    <div className={`d-flex py-3 small align-items-center justify-content-between ${images.length  === imageCount || loading ? 'd-none' : ''}`}>
                        <div className="flex-grow pr-2">
                            <a href="#" className={`text-secondary ${page === 1 ? 'd-none' : ''}`} onClick={this.handlePrevPage}>
                                « Prev Page
                            </a>
                        </div>
                        <div className={`${!imageCount ? 'd-none' : ''}`}>
                            {lowNumber} - {highNumber} of {imageCount}
                        </div>
                        <div className="flex-grow text-right pl-2">
                            <a href="#" className={`text-secondary ${highNumber === imageCount ? 'd-none' : ''}`} onClick={this.handleNextPage} >
                                Next Page »
                            </a>
                        </div>
                    </div>
                ) : ''}

                {/*<div className={`text-center ${images.length  === imageCount ? 'd-none' : ''}`}>*/}
                {/*    <button*/}
                {/*        className="btn btn-primary"*/}
                {/*        onClick={this.handleLoadMore}*/}
                {/*    >*/}
                {/*        Load More*/}
                {/*    </button>*/}
                {/*</div>*/}
            </div>
        );
    }
}
