import { delay } from 'redux-saga';
import { all } from 'redux-saga/effects';
import servicesListener from '../../services/servicesListener';
import interfaceListener from '../../interface/listeners';
import businessListener from "../../business/listeners";
import Raven from "raven-js";

const base = function*() {
    try {
        yield all([servicesListener(), businessListener(), interfaceListener()]);
    }
    catch(error) {
        // Log error to console
        console.error(error);
        // Log error with Sentry
        Raven.captureException(error);
        // Delay for 0.5 second to stop cascade of infinite errors
        yield delay(500);
    }
};

export default function*() {
    yield all([
        base()
    ]);
}
