import { parseStyles, hasOnlySpaces } from "./utilities";
import { getUniqueId } from "./getId";

export class DomParser {

    createTree(node) {
        return this.parseNode(node);
    }

    parseNode(node) {
        // if node have nodeType it is probably DOM element
        if (!node || !('nodeType' in node)) {
            return [];
        }

        const {nodeType, nodeName, nodeValue, attributes, childNodes, textContent} = node;

        if (nodeType === 3 && (!nodeValue || hasOnlySpaces(nodeValue))) {
            return [];
        }

        // HTML Comments should just get ignored.
        if (nodeType === 8){
            return [];
        }

        return {
            type: nodeType,
            name: nodeName.toLowerCase(),
            value: nodeName === 'style' ? textContent && textContent.trim() : nodeValue && nodeValue.trim(),
            id: getUniqueId(),
            attributes: this.parseAttributes(attributes),
            children: this.parseLevel(childNodes),
        };
    }

    parseLevel(nodes) {
        if (!nodes || nodes.length === 0) {
            return [];
        }

        const levelData = [];

        for (const node of nodes) {
            const {nodeType, nodeValue} = node;

            if (nodeType === 3 && (!nodeValue || hasOnlySpaces(nodeValue))) {
                continue;
            }

            // HTML Comments should just get ignored.
            if (nodeType === 8){
                continue;
            }

            const nodeData = this.parseNode(node);

            levelData.push(nodeData);
        }

        return levelData;
    }

    parseAttributes(attributes) {
        if (!attributes) {
            return {};
        }

        return [...attributes].reduce((attrObj, attr) => {
            const {name, value} = attr;
            let key = name;
            let val = value;

            // react element use "className" instead of "class"
            if (name === 'class') {
                key = 'className';
            }

            if (name === 'style') {
                val = parseStyles(value);
            }

            return {
                ...attrObj,
                [key]: val,
            };
        }, {});
    }
}
