const ID_HASH = new Map();

export const getRandomId = () => +Date.now() + Math.random().toString(36).slice(2, 7);

export const getUniqueId = (maxCycles = 1e6) => {
    while (maxCycles--) {
        const id = getRandomId();

        if (ID_HASH.has(id)) {
            continue;
        }

        ID_HASH.set(id, new Date());

        return id;
    }

    return null;
};
