import { SHOW_MODAL, HIDE_MODAL } from "../actions/stateModifierActions";
import { SIDEBAR_CONTENT_CODES } from "../constants";

const initialState = {
  contentName: SIDEBAR_CONTENT_CODES.templateForm,
  contentProps: {}
};

export default (state = initialState, action)=>{
    switch (action.type) {
        case SHOW_MODAL: {
            const {contentName, contentProps} = action.payload;
            return {
                contentName: contentName,
                contentProps: contentProps
            };
        }
        case HIDE_MODAL: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
