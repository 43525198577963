import React from 'react';
import PropTypes from 'prop-types';
import ReactCropper from 'react-cropper';

import 'cropperjs/dist/cropper.css';
import cropToBackgroundPosition from '../../../lib/cropToBackgroundPosition';
import cropToBackgroundSize from '../../../lib/cropToBackgroundSize';
import backgroundPositionToCrop from '../../../lib/backgroundPositionToCrop';

//TODO manage unused functions

export default class Cropper extends React.Component {

    static propTypes = {
        frame: PropTypes.shape({height: PropTypes.number.isRequired, width: PropTypes.number.isRequired}).isRequired,
        id: PropTypes.string.isRequired,
        originalValue: PropTypes.object.isRequired,
        onCrop: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onCropChange: PropTypes.func.isRequired,
    };

    state = {
        cropArea: null,
        initialCrop: null,
        initialOriginalValue: this.props.originalValue,
        cropConfirmed: false,
    };

    cropper = React.createRef();

    componentDidMount() {
        const {originalValue: { src, height, width, backgroundPosition}} = this.props;

        // TODO: Currently we don't set an initial crop
        const promise = new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = e => reject(e);
            img.setAttribute('crossorigin', 'anonymous');
            img.src = src;
        });

        const bgPosition = {
            height,
            width,
            x: backgroundPosition.x,
            y: backgroundPosition.y,
        };

        promise.then((image) => {
            this.setState({
                initialCrop: backgroundPositionToCrop(image, bgPosition)
            });
        });
    }

    componentWillUnmount() {
        const {onCropChange} = this.props;
        const {cropConfirmed, initialOriginalValue: {backgroundPosition, backgroundSize}} = this.state;

        if (cropConfirmed) {
            return;
        }

        onCropChange(backgroundPosition, backgroundSize);
    }

    updateImage() {
        const imagData = this.imageData();
        const {cropArea} = this.state;
        const backgroundPosition = cropToBackgroundPosition(imagData, cropArea);
        const backgroundSize = cropToBackgroundSize(imagData, cropArea);

        this.props.onCropChange(
            this.positionCss(backgroundPosition.x, backgroundPosition.y),
            this.sizeCss(backgroundSize.height, backgroundSize.width)
        );
    }

    imageData() {
        const cropper = this.cropper && this.cropper.current.cropper;

        if (!cropper) {
            return 1;
        }

        const imagData = cropper.getImageData();

        const isRotated = imagData.rotate && (imagData.rotate === 90 || imagData.rotate !== 90);

        return {
            height: !isRotated ? imagData.naturalHeight : imagData.naturalWidth,
            width: !isRotated ? imagData.naturalWidth : imagData.naturalHeight
        };
    }

    positionCss(x, y) {
        return {x, y};
    }

    sizeCss(h, w) {
        return w;
    }

    srcCss(url) {
        return url;
    }

    handleCropChange = ({detail}) => {
        this.setState({
            cropArea: detail,
        });
        this.updateImage();
    };

    handleCropButtonClick = () => {
        this.setState({
            cropConfirmed: true,
        }, this.props.onCrop);
    };

    handleCancelButtonClick = () => this.props.onCancel();

    render() {
        const {originalValue, frame} = this.props;
        const {initialCrop} = this.state;

        //TODO: Currently we do not set the initial crop position
        //remove?
        if (initialCrop) {
            const cropData = {
                left: initialCrop.x * this.imageData.width,
                top: initialCrop.y * this.imageData.height,
                height: initialCrop.height * this.imageData.height,
                width: initialCrop.width * this.imageData.width
            };
        }

        return (
            <div className="cropper-container">
                <div className="row justify-content-between">
                    <div className="col text-left">
                        <button
                            className="btn btn-secondary mr-2 col mb-2"
                            onClick={this.handleCancelButtonClick}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="col text-right">
                        <button
                            className="btn btn-primary mr-2 col mb-2"
                            onClick={this.handleCropButtonClick}
                        >
                            Crop & Select
                        </button>
                    </div>
                </div>
                <ReactCropper
                    ref={this.cropper}
                    src={originalValue.src}
                    // Cropper.js options
                    aspectRatio={frame.width / frame.height}
                    viewMode={2}
                    guides={false}
                    dragMode='move'
                    preview='#previewer'
                    responsive={true}
                    highlight={false}
                    center={false}
                    crop={this.handleCropChange}
                    zoomable={true}
                    style={{height: 400, width: '100%'}}
                    autoCropArea={1}
                />
            </div>
        );
    }
}
