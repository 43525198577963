import React from 'react';
import PropTypes from 'prop-types';

import TextFormField from '../TextFormField/container';
import ImageFormField from '../ImageFormField/container';
import VisibilityFormField from '../VisibilityFormField/component';
import Spinner from '../../Spinner/component';

import { EDITABLE_TYPES } from "../../../constants";
import { isPresent } from "../../../../business/helpers/utilities";
import humanize from "../../../helpers/humanize";

const TemplateFormField = (props) => {
    const {editableElementData, dataCy} = props;

    if (!isPresent(editableElementData)) {
        return (<Spinner/>);
    }

    const {name, id, image, text, visibility} = editableElementData;
    const disabled = visibility && !visibility.isVisible;
    let fields = [];

    if (visibility) {
        fields.push(
            <VisibilityFormField
                key={EDITABLE_TYPES.visibility}
                name={name}
                id={id}
                dataCy={dataCy}
            />
        );
    }

    if (image) {
        fields.push(
            <ImageFormField
                key={EDITABLE_TYPES.image}
                name={name}
                id={id}
                disabled={disabled}
                dataCy={dataCy}
            />
        );
    }

    if (text) {
        const {value} = text;

        fields.push(
            <TextFormField
                key={EDITABLE_TYPES.text}
                name={name}
                value={value}
                id={id}
                disabled={disabled}
                dataCy={dataCy}
            />
        );
    }

    const toReturn = (
        <div>
            <h6>{humanize(name)}</h6>
            {fields}
            <hr />
        </div>
    )

    return (toReturn);
};

TemplateFormField.propTypes = {
    dataCy: PropTypes.string.isRequired,
    editableElementData: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        style: PropTypes.object,
        [EDITABLE_TYPES.image]: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.bool,
        ]),
        [EDITABLE_TYPES.text]: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.bool,
        ]),
        [EDITABLE_TYPES.visibility]: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.bool,
        ]),
    }),
};

export default TemplateFormField;
