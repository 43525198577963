export const TEMPLATE_ROOT_ELEMENT_INITIALIZED_REQUESTED = 'BUSINESS:TEMPLATE_ROOT_ELEMENT_INITIALIZED_REQUESTED';
export const templateRootElementInitializedRequested = (type, ref) => ({
    type: TEMPLATE_ROOT_ELEMENT_INITIALIZED_REQUESTED,
    payload: { type, ref },
});

export const TEMPLATE_ROOT_ELEMENT_UPDATE_REQUESTED = 'BUSINESS:TEMPLATE_ROOT_ELEMENT_UPDATE_REQUESTED';
export const templateRootElementUpdateRequested = (type) => ({
    type: TEMPLATE_ROOT_ELEMENT_UPDATE_REQUESTED,
    payload: { type },
});

export const TEMPLATE_ROOT_ELEMENT_REMOVE_REQUESTED = 'BUSINESS:TEMPLATE_ROOT_ELEMENT_REMOVE_REQUESTED';
export const templateRootElementRemoveRequested = (type) => ({
    type: TEMPLATE_ROOT_ELEMENT_REMOVE_REQUESTED,
    payload: { type },
});

export const TEMPLATE_EDITABLE_ELEMENT_INITIALIZED_REQUESTED = 'BUSINESS:TEMPLATE_EDITABLE_ELEMENT_INITIALIZED_REQUESTED';
export const templateEditableElementInitializedRequested = (type, id, ref, isNonBackgroundImage) => ({
    type: TEMPLATE_EDITABLE_ELEMENT_INITIALIZED_REQUESTED,
    payload: { type, id, ref, isNonBackgroundImage },
});

export const TEMPLATE_EDITABLE_ELEMENT_REMOVE_REQUESTED = 'BUSINESS:TEMPLATE_EDITABLE_ELEMENT_REMOVE_REQUESTED';
export const templateEditableElementRemoveRequested = (type, id) => ({
    type: TEMPLATE_EDITABLE_ELEMENT_REMOVE_REQUESTED,
    payload: { type, id },
});