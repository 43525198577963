import { createSelector } from "reselect";

import { rootIdKeySelector } from "./template";
import { editableIdKeySelector } from "./editableElement";
import { idSelector } from "./helpers";
import { getEl } from '../helpers/registry';
import { getPosition } from "../../business/helpers/utilities";

export const highlightsSelector = (state) => state.ui.highlights;
export const highlightedSelector = (state, props) => !!highlightsSelector(state)[props.id];

export const createOverlayPositionSelector = () => createSelector(idSelector, templateTypeSelector, rootIdKeySelector, editableIdSelector, (editableId, templateType, rootIdKey, editableKey) => {
    const rootRef = getEl(templateType);
    const editableEl = getEl(templateType + editableId);

    if (!rootRef || !editableEl) { return {}; }

    const templatePos = getPosition(rootRef.current);
    const editablePos = getPosition(editableEl.current);

    // Calculate relative position of Editable Element on page before scaling
    const relativePos = {
        top: Math.abs(editablePos.top - templatePos.top),
        bottom: Math.abs(editablePos.bottom - templatePos.bottom),
        left: Math.abs(editablePos.left - templatePos.left),
        right: Math.abs(editablePos.right - templatePos.right),
    };

    // Calculate the scaling factor of the template
    const templateScale = templatePos.offsetWidth / templatePos.width;

    // Calculate the position of the Editable Element on page after scaling
    return {
        top: relativePos.top * templateScale || 0,
        bottom: relativePos.bottom * templateScale || 0,
        left: relativePos.left * templateScale || 0,
        right: relativePos.right * templateScale || 0,
        width: editablePos.offsetWidth,
        height: editablePos.offsetHeight,
    };
});

