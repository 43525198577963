import React from 'react';

import ImageEditor from './ImageEditor/container';
import SpinnerContainer from "./SpinnerContainer/container";
import Flash from "./Flash/container";

export default class Root extends React.Component {
    render() {
        return(
            <div>
                <Flash/>
                <ImageEditor/>
                <SpinnerContainer/>
            </div>
        );
    }
}
