import { connect } from 'react-redux';
import TemplateElement from './component';
import {templateTypeSelector} from "../../../selectors/template";

const mapStateToProps = () => {
    return (state, ownProps) => {
        return {
            templateType: templateTypeSelector(state),
        }
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    // defaultTextMetricsInitializedDispatch: (type, id, defaultTextMetrics) => dispatch(templateDefaultMetricsInitialized(type, id, defaultTextMetrics)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) =>({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    // defaultTextMetricsInitialized: (defaultTextMetrics) => dispatchProps.defaultTextMetricsInitializedDispatch(stateProps.templateType, ownProps.id, defaultTextMetrics),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TemplateElement);
