export default (image, cropArea) => {
    //image { x: x-pos, y: y-pos, height: height, width: width }
    //cropArea { x: x-pos, y: y-pos, height: height, width: width }

    const cropImageWidthScaleRatio = image.width / cropArea.width;
    const cropImageHeightScaleRatio = image.height / cropArea.height;

    return {
        height: cropImageHeightScaleRatio,
        width: cropImageWidthScaleRatio
    };
};