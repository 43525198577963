import { connect } from 'react-redux';

import Cropper from './component';

import * as ui from '../../actions/interfaceActions';
import { editableElementDataSelector } from '../../selectors/editableElement';

const mapStateToProps = (state, ownProps) => {
    const {image} = editableElementDataSelector(state, ownProps);

    return {
        frame: {width: image.width, height: image.height},
        originalValue: image,
    };
};

const mapDispatchToProps = dispatch => ({
    onCropChangeDispatch: (id, src, position, size) => dispatch(ui.editableImageUpdated(id, src, position, size)),
    onCrop: () => dispatch(ui.cropButtonClicked()),
    onCancel: () => dispatch(ui.cropCancelButtonClicked()),
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {originalValue: {src}} = stateProps;
    const {onCropChangeDispatch} = dispatchProps;
    const {id} = ownProps;
    const onCropChange = (position, size) => onCropChangeDispatch(id, src, position, size);

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        onCropChange,
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Cropper);
