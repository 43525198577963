import { HIGHLIGHT_EDITABLE, UNHIGHLIGHT_EDITABLE, UNHIGHLIGHT_ALL_EDITABLES } from "../actions/stateModifierActions";

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case HIGHLIGHT_EDITABLE: {
            return {
                ...state,
                [payload.id]: true,
            };
        }
        case UNHIGHLIGHT_EDITABLE: {
            return {
                ...state,
                [payload.id]: false,
            };
        }
        case UNHIGHLIGHT_ALL_EDITABLES: {
            return INITIAL_STATE;
        }
        default: {
            return state;
        }
    }
};
