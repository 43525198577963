import React from 'react';
import PropTypes from 'prop-types';

import spinnerUrl from './mason_icon_red.svg';
// import spinnerUrl from './mason-spinner.gif';
import './styles.scss';

const Spinner = (props) => {
    const {message} = props;

    return (
        <div className="spinner">
            <div className="row h-100">
                <div className="col-sm-12 my-auto text-center">
                    <img src={spinnerUrl} height="120px" width="120px" alt="" className="rotate-center" />
                    { message && message.length > 0 && <div>{message}...</div> }
                </div>
            </div>
        </div>
    );
};

Spinner.propTypes = {
    message: PropTypes.string,
};

export default Spinner;
