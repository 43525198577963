import {connect} from 'react-redux';

import ElementVisibilityToggle from './component';
import { editableToggleVisibility, editableFocused, editableUnfocused } from "../../../actions/templateActions";
import {isVisibleElementSelector} from "../../../selectors/editableElement";

const mapStateToProps = (state, ownProps) => ({
    isVisible: isVisibleElementSelector(state, ownProps),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    toggle: (isVisible) => dispatch(editableToggleVisibility(ownProps.id, isVisible)),
    onFocus: () => dispatch(editableFocused(ownProps.id)),
    onUnFocus: () => dispatch(editableUnfocused(ownProps.id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ElementVisibilityToggle);
