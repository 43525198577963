import {takeEvery, put} from 'redux-saga/es/effects';

import * as ui from '../actions/interfaceActions';
import * as stateModifier from '../actions/stateModifierActions';
import * as business from '../../business/actions/businessActions';

export default function* () {
    yield takeEvery(ui.DOWNLOAD_BUTTON_CLICKED,         function*(){
        yield put(stateModifier.showSpinner('Processing'));
        yield put(business.createTemplateSnapshotRequested());
        yield put(business.createDownloadRequested());
    });
    yield takeEvery(ui.FILE_DOWNLOADED,                 function*(){
        yield put(stateModifier.hideSpinner());
        yield put(business.downloadCompleted());
    });
}
