import { TOGGLE_TEMPLATE_TYPE } from "../actions/stateModifierActions";

const INITIAL_STATE = 'rectangle';

const templateType = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case TOGGLE_TEMPLATE_TYPE: {
            return payload.templateName;
        }
        default: {
            return state;
        }
    }
};

export default templateType;
