import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SidebarComponent from './component';
import ImageFieldImageLibrary from '../ImageFieldImageLibrary/container';
import UploadForm from '../UploadForm/container';
import Cropper from '../Cropper/container';
import TemplateForm from '../TemplateFormEnteties/TemplateForm/container';

import { modalContentNameSelector, modalTitleSelector } from '../../selectors/modal';
import { currentSidebarPageSelector, isSidebarRootSelector } from '../../selectors/sidebar';
import { backButtonClicked } from '../../actions/interfaceActions';
import { SIDEBAR_CONTENT_CODES } from '../../constants';

export const SIDEBAR_CONTENT = {
    [SIDEBAR_CONTENT_CODES.imageFiledImageLibrary]: ImageFieldImageLibrary,
    [SIDEBAR_CONTENT_CODES.uploadForm]: UploadForm,
    [SIDEBAR_CONTENT_CODES.cropper]: Cropper,
    [SIDEBAR_CONTENT_CODES.templateForm]: TemplateForm,
    /* other components */
};

export const SIDEBAR_TABS = {
    [SIDEBAR_CONTENT_CODES.templateForm]: TemplateForm,
    /* other components */
};

const mapStateToProps = (state, ownProps) => ({
    title: modalTitleSelector(state),
    isOpen: !!modalContentNameSelector(state),
    contentData: currentSidebarPageSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    requestBack: () => dispatch(backButtonClicked()),
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    isSidebarRoot: isSidebarRootSelector(null, stateProps),
});

const SidebarContainer = (props) => {
    const {contentData: {contentName, contentProps}} = props;

    if (!contentName) { return null; }

    const SidebarContent = SIDEBAR_CONTENT[contentName];

    // eslint-disable-next-line
    if (!SidebarContent) {raise(`No component for content key$ ${contentName} has been defined in Sidebar`);}

    return (
        <SidebarComponent {...props}>
            <SidebarContent {...contentProps} setActiveTab={props.setActiveTab} activeTab={props.activeTab} />
        </SidebarComponent>
    );
};

SidebarContainer.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    contentData: PropTypes.shape({
        contentName: PropTypes.string,
        contentProps: PropTypes.any,
    }).isRequired,
    requestClose: PropTypes.func, //TODO unused function what purpose?
    requestBack: PropTypes.func.isRequired,
    requestOpen: PropTypes.func, //TODO unused function what purpose?
    setActiveTab: PropTypes.func,
    activeTab: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SidebarContainer);
