import { UPDATE_FORM_STATE } from "../actions/stateModifierActions";

export default (state = {}, action)=>{
    switch (action.type) {
        case UPDATE_FORM_STATE: {
            const {formName, isPending} = action.payload;

            return {
                ...state,
                [formName]: isPending,
            };
        }
        default: {
            return state;
        }
    }
};
