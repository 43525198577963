import { takeEvery, put, select, call } from 'redux-saga/es/effects';

import * as ui from '../actions/interfaceActions';
import * as stateModifier from '../actions/stateModifierActions';
import { SIDEBAR_CONTENT_CODES } from "../constants";
import { editableElementDataSelector } from '../selectors/editableElement';
import autoCrop from '../../lib/autoCrop';
import {currentTemplateTypeSelector} from "../selectors/template";
import * as autosave from "../actions/autoSaveActions";

export default function* () {
    yield takeEvery(ui.CROP_BUTTON_CLICKED, function* () {
        yield put(autosave.templateAutosave());
        yield put(ui.resetSidebarRequested());
    });
    yield takeEvery(ui.CROP_CANCEL_BUTTON_CLICKED, function* () { yield put(ui.resetSidebarRequested()); });
    yield takeEvery(ui.IMAGE_FIELD_CROP_CLICKED, function* (action) { yield put(ui.pageForwardRequested(SIDEBAR_CONTENT_CODES.cropper, {id: action.payload.id})); });
    yield takeEvery(ui.AUTO_CROP_REQUESTED, function* (action) {
        const {id, libraryImageId} = action.payload;
        const libraryImage = yield select((state) => state.business.entities.libraryImage[libraryImageId]);
        const currentEditableElement = yield select((state) => editableElementDataSelector(state, {id}));

        yield put(stateModifier.showSpinner('Cropping Image...'));
        const cropResponse = yield call(autoCrop, libraryImage.image.lrg.url, currentEditableElement);
        yield put(stateModifier.hideSpinner());

        yield put(ui.editableImageUpdated(id, libraryImage.image.lrg.url, cropResponse.position, cropResponse.scale.width));
    });
}
