import { takeEvery, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { imageLibrary } from '../../schema/imageLibrary';

import * as endpoints from './endpoints';
import * as mason_image_library from './actions';
import * as business from '../../../../business/actions/businessActions';


const handleImageLibraryRequested = function*(action) {
    try {
        const response = yield endpoints.getImageLibrary(action.payload);
        yield put(mason_image_library.imageLibraryRequestSucceeded(response));
    } catch(e) {
        yield put(mason_image_library.imageLibraryRequestFailed(e.response));
    }
};

const extractErrors = (response) => {
    return response.data.errors;
};

const extractEntities = (response)=>{
    return normalize(response.data, imageLibrary).entities;
};

export default function*() {
    yield takeEvery(mason_image_library.IMAGE_LIBRARY_REQUESTED,            handleImageLibraryRequested);
    yield takeEvery(mason_image_library.IMAGE_LIBRARY_REQUEST_SUCCEEDED,    function*(action){
        //IDK how to destructure the data in ruby so I'm doing it here
        const mergedData = {
            data: {
                ...action.payload.data.image_library,
                library_images: action.payload.data.library_images,
                count: action.payload.data.count
            }
        }
        yield put(business.imageLibraryRequestSucceeded(extractEntities(mergedData)));
    });
    yield takeEvery(mason_image_library.IMAGE_LIBRARY_REQUEST_FAILED,       function*(action){ yield put(business.imageLibraryRequestFailed(extractErrors(action.payload.error))); });
}
