import React from 'react';
import PropTypes from 'prop-types';

const TemplateStyleComponent = (props) => {
    const {themeFonts} = props;
    const styles = themeFonts && themeFonts.map(({name, font_data}) => {
        // "woff", "truetype", "opentype", "embedded-opentype"
        return `
                @font-face {
                  font-family: "${name}";
                  src: url(data:font/truetype;charset=utf-8;base64,${font_data});
                }
            `;
    }).join(' ');

    if (!styles) { return null; }

    return (
        <style>
            {`${styles}`}
        </style>
    );
};

TemplateStyleComponent.propTypes = {
    themeFonts: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        font_data: PropTypes.string.isRequired,
    })),
};

export default TemplateStyleComponent;
