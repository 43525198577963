import React from 'react';
import PropTypes from 'prop-types';

import DeleteButton from '../DeleteButton/component';

export default class LibraryImage extends React.Component {

    static propTypes = {
        onSelect: PropTypes.func.isRequired,
        url: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        deletable: PropTypes.bool.isRequired,
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]).isRequired,
        onDelete: PropTypes.func.isRequired,
        dataCy: PropTypes.number.isRequired,
    };

    handleClick = () => this.props.onSelect(this.props.id);

    handleDelete = () => this.props.onDelete(this.props.id);

    renderDeleteButton = () => this.props.deletable && <DeleteButton onConfirm={this.handleDelete} />;

    render() {
        const { url, name, dataCy } = this.props;
        const deleteBtn = this.renderDeleteButton();

        return(
            <div data-cy={`library-image-${dataCy}`} className="col-md-6 library-image">
                <div className="card">
                    <div className="hover-image-cta" onClick={this.handleClick}>
                        <img className="card-img-top img-fluid" src={url} alt={name}/>
                        <div className="hover">
                            <span className="cta">Choose this image</span>
                        </div>
                    </div>

                    <div className="inner">
                        <p className="card-text p-0 mb-1">{name}</p>
                        { deleteBtn }
                    </div>
                </div>
            </div>
        );
    }
}
