import {all} from 'redux-saga/es/effects';

import crop from './cropListener';
import template from './templateListener';
import download from './downloadListener';
import upload from './uploadListener';
import theme from './themeListener';
import imageLibrary from './imageLibraryListener';
import messages from './flashListener';
import sidebar from './sidebarListener';
import autoSave from './autoSaveListener';

export default function* () {
    yield all([
        crop(),
        template(),
        download(),
        upload(),
        theme(),
        imageLibrary(),
        messages(),
        sidebar(),
        autoSave(),
    ]);
}
