import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import humanize from '../../../helpers/humanize';
import Input from '../../Input/component';
import TextArea from "../../TextArea/component";
import RangeStylizedControl from "../../RangeStylizedControl/component";
import {isMultilineText} from "../../../../business/helpers/normalizeFonts";


export default class TextFormField extends React.Component {

    static propTypes = {
        dataCy: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        onValueChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        focusedField: PropTypes.string,
        onUnFocus: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        isLegacyTemplate: PropTypes.bool.isRequired,
        id: PropTypes.string.isRequired,
        isFocused: PropTypes.bool.isRequired,
        onSelect: PropTypes.func.isRequired,
        onUnSelect: PropTypes.func.isRequired,
        valignValue: PropTypes.number,
    };

    static defaultProps = {
        valignValue: 0,
    }

    handleMouseEnter = () => this.props.onFocus();

    handleMouseExit = () => this.props.onUnFocus();

    handleFocus = () => this.props.onSelect();

    handleBlur = () => this.props.onUnSelect();

    handleChange = event => this.props.onValueChange(event.target.value);

    rangeChange = event => this.props.onVAlignSliderChange(event.target.value);

    resetRange = () => this.props.onVAlignSliderReset();

    render() {
        const {name, value, disabled, isFocused, dataCy, valignValue, isLegacyTemplate, nodeData: {attributes}} = this.props;

        const isMultiline = isMultilineText(attributes);
        if(isMultiline){
            return (
                <div className="form-group" data-cy={`${dataCy}-text`}>
                    <div>
                        {/*<div className="d-flex justify-content-between mb-2">*/}
                        {/*    <div>*/}
                        {/*        <small>{humanize(name)}</small>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <TextArea
                            controlledFocus={isFocused}
                            className={classNames('form-control')}
                            type="text"
                            value={value}
                            onChange={this.handleChange}
                            onMouseEnter={this.handleMouseEnter}
                            onMouseLeave={this.handleMouseExit}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            disabled={disabled}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className="form-group" data-cy={`${dataCy}-text`}>
                <div>
                    {/*<div className="d-flex justify-content-between mb-2">*/}
                    {/*    <div>*/}
                    {/*        <small>{humanize(name)}</small>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <Input
                        controlledFocus={isFocused}
                        className={classNames('form-control')}
                        type="text"
                        value={value}
                        onChange={this.handleChange}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseExit}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        disabled={disabled}
                    />
                    {
                        !isLegacyTemplate ? (
                            <RangeStylizedControl
                                onChange={this.rangeChange}
                                onReset={this.resetRange}
                                value={valignValue}
                                min={-1}
                                max={1}
                                step={0.01}
                                label="Adjust text vertical alignment"
                                rangeLabels={['Down', 'Center', 'Up']}
                            />
                        ) : <div />
                    }

                </div>
            </div>
        );
    }
}
