export const DOWNLOAD_BUTTON_CLICKED = 'INTERFACE:DOWNLOAD_BUTTON_CLICKED';
export const downloadButtonClicked = () => ({
    type: DOWNLOAD_BUTTON_CLICKED
});

export const FILE_DOWNLOADED = 'INTERFACE:FILE_DOWNLOADED';
export const fileDownloaded = () => ({
    type: FILE_DOWNLOADED,
});

export const EDITABLE_IMAGE_UPDATED = 'INTERFACE:EDITABLE_IMAGE_UPDATED';
export const editableImageUpdated = (id, src, position, size, nonBackgroundImage = false) => ({
    type: EDITABLE_IMAGE_UPDATED,
    payload: { id, src, position, size, nonBackgroundImage },
});

export const THEME_COLOR_UPDATED = 'INTERFACE:THEME_COLOR_UPDATED';
export const themeColorUpdated = (themeColorName, value) => ({
    type: THEME_COLOR_UPDATED,
    payload: { themeColorName, value },
});

export const THEME_FONT_UPDATED = 'INTERFACE:THEME_FONT_UPDATED';
export const themeFontUpdated = (themeFontName, value) => ({
    type: THEME_FONT_UPDATED,
    payload: { themeFontName, value },
});

export const THEME_FONT_SCALE_UPDATED = 'INTERFACE:THEME_FONT_SCALE_UPDATED';
export const themeFontScaleUpdated = (themeFontSizeName, value) => ({
    type: THEME_FONT_SCALE_UPDATED,
    payload: { themeFontSizeName, value },
});

export const THEME_FONT_SCALE_RESET = 'INTERFACE:THEME_FONT_SCALE_RESET';
export const themeFontScaleReset = (themeFontSizeName) => ({
    type: THEME_FONT_SCALE_RESET,
    payload: { themeFontSizeName },
});

export const THEME_RESET_REQUESTED = 'INTERFACE:THEME_RESET_REQUESTED';
export const themeResetRequested = () => ({
    type: THEME_RESET_REQUESTED,
});

export const THEME_SAVE_REQUESTED = 'INTERFACE:THEME_SAVE_REQUESTED';
export const themeSaveRequested = () => ({
    type: THEME_SAVE_REQUESTED,
});

export const THEME_COLOR_SWITCH_REQUESTED = 'INTERFACE:THEME_COLOR_SWITCH_REQUESTED';
export const themeColorSwitchRequested = () => ({
    type: THEME_COLOR_SWITCH_REQUESTED,
});

export const THEME_SAVE_SUCCEEDED = 'INTERFACE:THEME_SAVE_SUCCEEDED';
export const themeSaveSucceeded = () => ({
    type: THEME_SAVE_SUCCEEDED,
});

export const THEME_SAVE_FAILED = 'INTERFACE:THEME_SAVE_FAILED';
export const themeSaveFailed = (errors) => ({
    type: THEME_SAVE_FAILED,
    payload: errors,
});

export const AUTO_CROP_REQUESTED = 'INTERFACE:AUTO_CROP_REQUESTED';
export const autoCropRequested = (id, libraryImageId) => ({
    type: AUTO_CROP_REQUESTED,
    payload: { id, libraryImageId },
});

export const CROP_BUTTON_CLICKED = 'INTERFACE:CROP_BUTTON_CLICKED';
export const cropButtonClicked = () => ({
    type: CROP_BUTTON_CLICKED,
});

export const CROP_CANCEL_BUTTON_CLICKED = 'INTERFACE:CROP_CANCEL_BUTTON_CLICKED';
export const cropCancelButtonClicked = () => ({
    type: CROP_CANCEL_BUTTON_CLICKED,
});

export const EDITABLE_TEXT_UPDATED = 'INTERFACE:EDITABLE_TEXT_UPDATED';
export const editableTextUpdated = (id, value) => ({
    type: EDITABLE_TEXT_UPDATED,
    payload: { id, value },
});

export const TEXT_VALIGN_OVERRIDE_CHANGED = 'INTERFACE:TEXT_VALIGN_OVERRIDE_CHANGED';
export const textValignOverrideChanged = (id, value) => ({
    type: TEXT_VALIGN_OVERRIDE_CHANGED,
    payload: { id, value },
});

export const TEXT_VALIGN_OVERRIDE_RESET = 'INTERFACE:TEXT_VALIGN_OVERRIDE_RESET';
export const textValignOverrideReset = (id) => ({
    type: TEXT_VALIGN_OVERRIDE_RESET,
    payload: { id },
});

export const GET_LIBRARY_IMAGES = 'INTERFACE:LIBRARY_MOUNTED';
export const getLibraryImages = (code, userImageLibraryFolder, page = 1, per_page = 10, search_term = undefined) => ({
    type: GET_LIBRARY_IMAGES,
    payload: { code, userImageLibraryFolder, page, per_page, search_term }
});

export const GET_LIBRARY_IMAGES_REQUEST_FAILED = 'INTERFACE:GET_LIBRARY_IMAGES_REQUEST_FAILED';
export const getLibraryImagesRequestFailed = (errors) => ({
    type: GET_LIBRARY_IMAGES_REQUEST_FAILED,
    payload: errors,
});

export const USER_UPLOAD_FORM_SUBMITTED = 'INTERFACE:USER_UPLOAD_FORM_SUBMITTED';
export const userUploadFormSubmitted = ({name, file, user_image_library_folder}) => ({
    type: USER_UPLOAD_FORM_SUBMITTED,
    payload: { name, file, user_image_library_folder },
});

export const USER_UPLOAD_FORM_SUCCEEDED = 'INTERFACE:USER_UPLOAD_FORM_SUCCEEDED';
export const userUploadFormSucceeded = (libraryImageId) => ({
    type: USER_UPLOAD_FORM_SUCCEEDED,
    payload: { libraryImageId },
});

export const USER_UPLOAD_FORM_FAILED = 'INTERFACE:USER_UPLOAD_FORM_FAILED';
export const userUploadFormFailed = (errors) => ({
    type: USER_UPLOAD_FORM_FAILED,
    payload: errors,
});

export const PAGE_FORWARD_REQUESTED = 'INTERFACE:PAGE_FORWARD_REQUESTED';
export const pageForwardRequested = (content, contentProps) => ({
    type: PAGE_FORWARD_REQUESTED,
    payload: { content, contentProps },
});

export const PAGE_BACK_REQUESTED = 'INTERFACE:PAGE_BACK_REQUESTED';
export const pageBackRequested = () => ({
    type: PAGE_BACK_REQUESTED,
});

export const RESET_SIDEBAR_REQUESTED = 'INTERFACE:RESET_SIDEBAR_REQUESTED';
export const resetSidebarRequested = () => ({
    type: RESET_SIDEBAR_REQUESTED,
});

export const IMAGE_FIELD_CROP_CLICKED= 'INTERFACE:IMAGE_FIELD_CROP_CLICKED';
export const imageFieldCropClicked = (id) => ({
    type: IMAGE_FIELD_CROP_CLICKED,
    payload: { id },
});

export const NEW_UPLOAD_BUTTON_CLICKED= 'INTERFACE:NEW_UPLOAD_BUTTON_CLICKED';
export const newUploadButtonClicked = (id) => ({
    type: NEW_UPLOAD_BUTTON_CLICKED,
    payload: { id },
});

export const ERRORS_RECEIVED = 'INTERFACE:ERRORS_RECEIVED';
export const errorsReceived = (error) => ({
    type: ERRORS_RECEIVED,
    payload: { error },
});

export const LIBRARY_IMAGE_CLICKED = 'INTERFACE:LIBRARY_IMAGE_CLICKED';
export const libraryImageClicked = (id, libraryImageId) => ({
    type: LIBRARY_IMAGE_CLICKED,
    payload: { id, libraryImageId },
});

export const BACK_BUTTON_CLICKED = 'INTERFACE:BACK_BUTTON_CLICKED';
export const backButtonClicked = () => ({
    type: BACK_BUTTON_CLICKED,
});

export const REQUEST_CURRENT_IMAGE_LIBRARY = 'INTERFACE:REQUEST_CURRENT_IMAGE_LIBRARY';
export const requestCurrentImageLibrary = (id, library, userImageLibraryFolder) => ({
    type: REQUEST_CURRENT_IMAGE_LIBRARY,
    payload: { id, library, userImageLibraryFolder },
});

export const USER_UPLOAD_DELETE_CLICKED = 'INTERFACE:USER_UPLOAD_DELETE_CLICKED';
export const userUploadDeleteClicked = (id) => ({
    type: USER_UPLOAD_DELETE_CLICKED,
    payload: id
});

export const EDITOR_INITIALIZED = 'INTERFACE:EDITOR_INITIALIZED';
export const editorInitialized = () => ({
    type: EDITOR_INITIALIZED,
});
