export default (image, cropArea) => {
    //image { height: height, width: width }
    //cropArea { x: x-pos, y: y-pos, height: height, width: width }

    // 100% for background-position is where the right side of the image touches the right side of the frame
    // 0% for background-position is where the left side of the image touches the left side of the frame
    const hundred_point_x = image.width - cropArea.width;
    const hundred_point_y = image.height - cropArea.height;
    const pos_percent_x = hundred_point_x && (cropArea.x / hundred_point_x);
    const pos_percent_y = hundred_point_y && (cropArea.y / hundred_point_y);

    return {
        x: pos_percent_x,
        y: pos_percent_y,
    };
};