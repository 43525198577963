import { schema } from 'normalizr';

const nodeSchema = new schema.Entity('nodes');
export const domDataSchema = new schema.Entity('root');

nodeSchema.define({
    children: [nodeSchema],
});
domDataSchema.define({
    children: [nodeSchema],
});
