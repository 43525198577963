import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import RangeStylizedControl from "../../RangeStylizedControl/component";

const FontFormField = (props) => {
    const {name, handleFontSizeChange, label, handleFontSizeReset} = props;
    const fontSizeValue = typeof props.fontSizeValue !== 'undefined' && props.fontSizeValue ? props.fontSizeValue : 0;
    const displayLabel = label ? label : name;

    return (
        <div className="font-size-form-field mb-3">
            <RangeStylizedControl
                onChange={handleFontSizeChange}
                onReset={handleFontSizeReset}
                value={fontSizeValue}
                min={-0.95}
                max={0.95}
                step={0.01}
                label={displayLabel}
                rangeLabels={['Smaller', 'Normal', 'Larger']}
            />
        </div>
    );
};

FontFormField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    fontSizeValue: PropTypes.number,
    handleFontSizeChange: PropTypes.func.isRequired,
};

export default FontFormField;
