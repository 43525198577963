import { createSelector } from 'reselect';
import { size } from 'lodash';

import { FORM_NAMES } from "../constants";
import {checkNested, getNested} from "../../business/helpers/utilities";
// import {editableElementDataSelector} from "./editableElement";

export const defaultThemeSelector = (state) => state.business.defaultTheme;
export const themeSelector = (state) => state.business.theme;
export const themeFormPendingSelector = (state) => !!state.ui.forms[FORM_NAMES.theme];

export const themeActiveColorsSelector = (state) => state.business.theme.colors;
export const themeActiveColorSelector = (state, props) => themeActiveColorsSelector(state)[props.name];

export const themeActiveFontsIdSelector = (state) => state.business.theme.fonts;
export const themeActiveFontIdSelector = (state, props) => themeActiveFontsIdSelector(state)[props.name];

export const themeActiveFontSizesSelector = (state) => state.business.theme.fontSizes; // TODO have this pull from current template size first if exists
export const themeActiveFontSizeSelector = (state, props) => themeActiveFontSizesSelector(state)[props.name];
// export const themeActiveFontSizeSelector = (state,props) => {
//     const val = themeActiveFontSizesSelector(state)[props.name];
//     console.log('val', val);
//     return typeof val !== 'undefined' ? val : 0;
// }
// export const themeActiveFontSizeSelector = (state, props) => {
//     const fontSizes = state.business.theme.fontSizes;
//     return 0;
//     // // const thisFontSize = checkNested(themeActiveFontSizeSelector(state), props.name) ? getNested(themeActiveFontSizeSelector(state), props.name) : 0;
//     // return typeof thisFontSize !== 'undefined' && thisFontSize ? thisFontSize : 0;
// }

export const themeFontsSelector = (state) => state.business.entities.userFont;
export const themeFontsArraySelector = createSelector(themeFontsSelector, (fonts) => fonts && Object.values(fonts));
