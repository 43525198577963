document.addEventListener('turbolinks:load', (event)=>{
  const form = document.getElementById('confirmation-form');

  if(form === null){ return null; }

  const stripe_public_key = document.querySelector("meta[name='stripe_public_key']").getAttribute('content');
  const clientSecret = document.querySelector("input[name='client_secret']").value;
  // eslint-disable-next-line
  const stripe =  Stripe(stripe_public_key);

  stripe
    .handleCardPayment(clientSecret)
    .then(function(result) {
      if (result.error) {
        // Show error in console
        //eslint-disable-next-line
        console.log(result.error);
      } else {
        // The card action has been handled
        // The PaymentIntent can be confirmed again on the server
        const form = document.getElementById('confirmation-form');
        form.submit();
      }
    }
  )
});
