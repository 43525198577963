import { connect } from 'react-redux';

import ColorFormField from './component';

import { themeColorUpdated } from '../../../actions/interfaceActions';
import { themeActiveColorSelector } from "../../../selectors/theme";

const mapStateToProps = (state, ownProps) => ({
    color: themeActiveColorSelector(state, ownProps),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    handleColorChange: (value) => dispatch(themeColorUpdated(ownProps.name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ColorFormField);
