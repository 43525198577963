export const PAGE_FORWARD = 'UI:STATE_MODIFIER:PAGE_FORWARD';
export const pageForward = (contentName, contentProps)=>{
    return {
        type: PAGE_FORWARD,
        payload: {
            contentName: contentName,
            contentProps: contentProps
        }
    };
};

export const PAGE_BACK = 'UI:STATE_MODIFIER:PAGE_BACK';
export const pageBack = ()=>{
    return {
        type: PAGE_BACK,
        payload: {}
    };
};

export const FRONT_PAGE = 'UI:STATE_MODIFIER:FRONT_PAGE';
export const frontPage = () => ({
    type: FRONT_PAGE,
});

export const SHOW_MODAL = 'UI:STATE_MODIFIER:SHOW_MODAL';
export const showModal = (contentName, contentProps)=>{
    return {
        type: SHOW_MODAL,
        payload: {
            contentName: contentName,
            contentProps: contentProps
        }
    };
};

export const HIDE_MODAL = 'UI:STATE_MODIFIER:HIDE_MODAL';
export const hideModal = ()=>{
    return {
        type: HIDE_MODAL
    };
};

export const SHOW_SPINNER = 'UI:STATE_MODIFIER:SHOW_SPINNER';
export const showSpinner = (message)=>{
    return {
        type: SHOW_SPINNER,
        payload: message
    };
};

export const HIDE_SPINNER = 'UI:STATE_MODIFIER:HIDE_SPINNER';
export const hideSpinner = ()=>{
    return {
        type: HIDE_SPINNER
    };
};

export const UPDATE_FORM_STATE = 'UI:STATE_MODIFIER:UPDATE_FORM_STATE';
export const updateFormState = (formName, isPending)=>{
    return {
        type: UPDATE_FORM_STATE,
        payload: {
            formName: formName,
            isPending: isPending
        }
    };
};

export const HIGHLIGHT_EDITABLE = 'UI:STATE_MODIFIER:HIGHLIGHT_EDITABLE';
export const highlightEditable = (id) => ({
    type: HIGHLIGHT_EDITABLE,
    payload: { id },
});

export const UNHIGHLIGHT_EDITABLE = 'UI:STATE_MODIFIER:UNHIGHLIGHT_EDITABLE';
export const unhighlightEditable = (id) => ({
    type: UNHIGHLIGHT_EDITABLE,
    payload: { id },
});

export const UNHIGHLIGHT_ALL_EDITABLES = 'UI:STATE_MODIFIER:UNHIGHLIGHT_ALL_EDITABLES';
export const unhighlightAllEditables = () => ({
    type: UNHIGHLIGHT_ALL_EDITABLES,
});

export const FOCUS_TEXT_FIELD = 'UI:STATE_MODIFIER:FOCUS_TEXT_FIELD';
export const focusTextField = (id) => ({
    type: FOCUS_TEXT_FIELD,
    payload: { id },
});

export const UNFOCUS_TEXT_FIELD = 'UI:STATE_MODIFIER:UNFOCUS_TEXT_FIELD';
export const unfocusTextField = () => ({
    type: UNFOCUS_TEXT_FIELD,
});
