import { SIDEBAR_CONTENT_CODES } from "../constants";

export const sidebarStackSelector = (state) => state.ui.sidebarStack;
export const currentSidebarPageSelector = (state) => {
    const sidebarStack = sidebarStackSelector(state);

    return sidebarStack[sidebarStack.length - 1] || {};
};

//* 1/25 Used for getting an upload form's parent library
export const previousSidebarPageSelector = (state) => {
    const sidebarStack = sidebarStackSelector(state);

    return sidebarStack[sidebarStack.length - 2] || {};
};

export const isSidebarRootSelector = (_, props) => props.contentData.contentName === SIDEBAR_CONTENT_CODES.templateForm;
