export const START_DOWNLOAD_REQUESTED = 'BUSINESS:START_DOWNLOAD_REQUESTED';
export const startDownloadRequested = ()=>{
    return {
        type: START_DOWNLOAD_REQUESTED,
    };
};

export const STOP_DOWNLOAD_REQUESTED = 'BUSINESS:STOP_DOWNLOAD_REQUESTED';
export const stopDownloadRequested = ()=>{
    return {
        type: STOP_DOWNLOAD_REQUESTED,
    };
};

export const TOGGLE_TEMPLATE_TYPE_REQUESTED = 'BUSINESS:TOGGLE_TEMPLATE_TYPE_REQUESTED';
export const toggleTemplateTypeRequested = (templateName) => ({
    type: TOGGLE_TEMPLATE_TYPE_REQUESTED,
    payload: { templateName },
});

export const UPDATE_EDITABLE_IMAGE_REQUESTED = 'BUSINESS:UPDATE_EDITABLE_IMAGE_REQUESTED';
export const updateEditableImageRequested = (id, src, position, size, nonBackgroundImage) => ({
    type: UPDATE_EDITABLE_IMAGE_REQUESTED,
    payload: { id, src, position, size, nonBackgroundImage },
});

export const UPDATE_EDITABLE_TEXT_REQUESTED = 'BUSINESS:UPDATE_EDITABLE_TEXT_REQUESTED';
export const updateEditableTextRequested = (id, value) => {
    return {
        type: UPDATE_EDITABLE_TEXT_REQUESTED,
        payload: { id, value },
    };
};

export const UPDATE_TEXT_VALIGN_OVERRIDE_REQUESTED = 'BUSINESS:UPDATE_TEXT_VALIGN_OVERRIDE_REQUESTED';
export const updateTextValignOverrideRequested = (id, value) => {
    return {
        type: UPDATE_TEXT_VALIGN_OVERRIDE_REQUESTED,
        payload: { id, value },
    };
};

export const RESET_TEXT_VALIGN_OVERRIDE_REQUESTED = 'BUSINESS:RESET_TEXT_VALIGN_OVERRIDE_REQUESTED';
export const resetTextValignOverrideRequested = (id) => {
    return {
        type: RESET_TEXT_VALIGN_OVERRIDE_REQUESTED,
        payload: { id },
    };
};

export const UPDATE_TEXT_FUNDAMENTAL_FONT_SCALE_REQUESTED = 'BUSINESS:UPDATE_TEXT_FUNDAMENTAL_FONT_SCALE_REQUESTED';
export const updateTextFundamentalFontScaleRequested = (id, value) => ({
    type: UPDATE_TEXT_FUNDAMENTAL_FONT_SCALE_REQUESTED,
    payload: { id, value },
});

export const UPDATE_TEXT_NO_OVERFLOW_FONT_SCALE_REQUESTED = 'BUSINESS:UPDATE_TEXT_NO_OVERFLOW_FONT_SCALE_REQUESTED';
export const updateTextNoOverflowFontScaleRequested = (id, value) => ({
    type: UPDATE_TEXT_NO_OVERFLOW_FONT_SCALE_REQUESTED,
    payload: { id, value },
});

export const UPDATE_TEXT_METRICS_REQUESTED = 'BUSINESS:UPDATE_TEXT_METRICS_REQUESTED';
export const updateTextMetricsRequested = (id, value) => ({
    type: UPDATE_TEXT_METRICS_REQUESTED,
    payload: { id, value },
});

export const UPDATE_EDITABLE_TEXT_DATA_REQUESTED = 'BUSINESS:UPDATE_EDITABLE_TEXT_DATA_REQUESTED';
export const updateEditableTextDataRequested = (id, metrics, adjustments) => ({
    type: UPDATE_EDITABLE_TEXT_DATA_REQUESTED,
    payload: { id, metrics, adjustments },
});

export const UPDATE_EDITABLE_VISIBILITY_REQUESTED = 'BUSINESS:UPDATE_EDITABLE_VISIBILITY_REQUESTED';
export const updateEditableVisibilityRequested = (id, isVisible) => ({
    type: UPDATE_EDITABLE_VISIBILITY_REQUESTED,
    payload: { id, isVisible },
});

export const UPDATE_THEME_COLOR_REQUESTED = 'BUSINESS:UPDATE_THEME_COLOR_REQUESTED';
export const updateThemeColorRequested = (themeColorName, value) => ({
    type: UPDATE_THEME_COLOR_REQUESTED,
    payload: { themeColorName, value },
});

export const UPDATE_THEME_FONT_REQUESTED = 'BUSINESS:UPDATE_THEME_FONT_REQUESTED';
export const updateThemeFontRequested = (themeFontName, value) => ({
    type: UPDATE_THEME_FONT_REQUESTED,
    payload: { themeFontName, value },
});

export const UPDATE_THEME_FONT_SIZE_REQUESTED = 'BUSINESS:UPDATE_THEME_FONT_SIZE_REQUESTED';
export const updateThemeFontSizeRequested = (themeFontSizeName, value) => ({
    type: UPDATE_THEME_FONT_SIZE_REQUESTED,
    payload: { themeFontSizeName, value },
});

export const RESET_THEME_FONT_SIZE_REQUESTED = 'BUSINESS:RESET_THEME_FONT_SIZE_REQUESTED';
export const resetThemeFontSizeRequested = (themeFontSizeName) => ({
    type: RESET_THEME_FONT_SIZE_REQUESTED,
    payload: { themeFontSizeName },
});

export const SWITCH_THEME_COLORS_REQUESTED = 'BUSINESS:SWITCH_THEME_COLORS_REQUESTED';
export const switchThemeColorsRequested = () => ({
    type: SWITCH_THEME_COLORS_REQUESTED,
});

export const INITIALIZE_THEME_COLORS_REQUESTED = 'BUSINESS:INITIALIZE_THEME_COLORS_REQUESTED';
export const initializeThemeColorsRequested = () => ({
    type: INITIALIZE_THEME_COLORS_REQUESTED,
});

export const INITIALIZE_THEME_FONTS_REQUESTED = 'BUSINESS:INITIALIZE_THEME_FONTS_REQUESTED';
export const initializeThemeFontsRequested = () => ({
    type: INITIALIZE_THEME_FONTS_REQUESTED,
});

export const SAVE_TEMPLATE_THEME_REQUESTED = 'BUSINESS:SAVE_TEMPLATE_THEME_REQUESTED';
export const saveTemplateThemeRequested = () => ({
    type: SAVE_TEMPLATE_THEME_REQUESTED,
});


export const SAVE_TEMPLATE_THEME_SUCCEEDED = 'BUSINESS:SAVE_TEMPLATE_THEME_SUCCEEDED';
export const saveTemplateThemeSucceeded = (entities)=>{
  return {
    type: SAVE_TEMPLATE_THEME_SUCCEEDED,
    payload: {}
  };
};

export const SAVE_TEMPLATE_THEME_FAILED = 'BUSINESS:SAVE_TEMPLATE_THEME_FAILED';
export const saveTemplateThemeFailed = (errors)=>{
  return {
    type: SAVE_TEMPLATE_THEME_FAILED,
    payload: errors
  };
};

export const IMAGE_LIBRARY_REQUESTED = 'BUSINESS:IMAGE_LIBRARY_REQUESTED';
export const imageLibraryRequested = (code, page, per_page, search_term)=>{
    return {
        type: IMAGE_LIBRARY_REQUESTED,
        payload: {
            code, page, per_page, search_term
        }
    };
};

export const IMAGE_LIBRARY_REQUEST_SUCCEEDED = 'BUSINESS:IMAGE_LIBRARY_REQUEST_SUCCEEDED';
export const imageLibraryRequestSucceeded = (entities)=>{
    return {
        type: IMAGE_LIBRARY_REQUEST_SUCCEEDED,
        payload: entities
    };
};

export const IMAGE_LIBRARY_REQUEST_FAILED = 'BUSINESS:IMAGE_LIBRARY_REQUEST_FAILED';
export const imageLibraryRequestFailed = (errors)=>{
    return {
        type: IMAGE_LIBRARY_REQUEST_FAILED,
        payload: { errors },
    };
};

export const USER_UPLOADS_REQUESTED = 'BUSINESS:USER_UPLOADS_REQUESTED';
export const userUploadsRequested = ({user_image_library_folder, page, per_page, search_term})=>{
    // console.log('userUploadsRequested with', search_term);
    return {
        type: USER_UPLOADS_REQUESTED,
        payload: { user_image_library_folder, page, per_page, search_term }
    };
};

export const USER_UPLOADS_REQUEST_SUCCEEDED = 'BUSINESS:USER_UPLOADS_REQUEST_SUCCEEDED';
export const userUploadsRequestSucceeded = (entities)=>{
    // console.log('userUploadsRequestSucceeded with entities', entities);
    return {
        type: USER_UPLOADS_REQUEST_SUCCEEDED,
        payload: entities
    };
};

export const USER_UPLOADS_REQUEST_FAILED = 'BUSINESS:USER_UPLOADS_REQUEST_FAILED';
export const userUploadsRequestFailed = (errors)=>{
    return {
        type: USER_UPLOADS_REQUEST_FAILED,
        payload: errors
    };
};

export const CREATE_USER_UPLOAD_REQUESTED = 'BUSINESS:CREATE_USER_UPLOAD_REQUESTED';
export const createUserUploadRequested = ({name, file, user_image_library_folder})=>{
    return {
        type: CREATE_USER_UPLOAD_REQUESTED,
        payload: { name, file, user_image_library_folder }
    };
};

export const CREATE_USER_UPLOAD_SUCCEEDED = 'BUSINESS:CREATE_USER_UPLOAD_SUCCEEDED';
export const createUserUploadSucceeded = (entities)=>{
    return {
        type: CREATE_USER_UPLOAD_SUCCEEDED,
        payload: entities
    };
};

export const CREATE_USER_UPLOAD_FAILED = 'BUSINESS:CREATE_USER_UPLOAD_FAILED';
export const createUserUploadFailed = (errors)=>{
    return {
        type: CREATE_USER_UPLOAD_FAILED,
        payload: errors
    };
};

export const DELETE_USER_UPLOAD_REQUESTED = 'BUSINESS:DELETE_USER_UPLOAD_REQUESTED';
export const deleteUserUploadRequested = (id)=>{
    return {
        type: DELETE_USER_UPLOAD_REQUESTED,
        payload: id
    };
};

export const DELETE_USER_UPLOAD_SUCCEEDED = 'BUSINESS:DELETE_USER_UPLOAD_SUCCEEDED';
export const deleteUserUploadSucceeded = (id)=>{
    return {
        type: DELETE_USER_UPLOAD_SUCCEEDED,
        payload: id
    };
};

export const DELETE_USER_UPLOAD_FAILED = 'BUSINESS:DELETE_USER_UPLOAD_FAILED';
export const deleteUserUploadFailed = (id)=>{
    return {
        type: DELETE_USER_UPLOAD_FAILED,
        payload: id
    };
};

export const UPDATE_ENTITIES_REQUESTED = 'BUSINESS:UPDATE_ENTITIES_REQUESTED';
export const updateEntitiesRequested = (entities)=>{
    return {
        type: UPDATE_ENTITIES_REQUESTED,
        payload: entities
    };
};

export const DELETE_ENTITY_REQUESTED = 'BUSINESS:DELETE_ENTITY_REQUESTED';
export const deleteEntityRequested = (entity)=>{
    return {
        type: DELETE_ENTITY_REQUESTED,
        payload: entity
    };
};

export const CREATE_TEMPLATE_SNAPSHOT_REQUESTED = 'BUSINESS:CREATE_TEMPLATE_SNAPSHOT_REQUESTED';
export const createTemplateSnapshotRequested = () => ({
    type: CREATE_TEMPLATE_SNAPSHOT_REQUESTED,
});

export const CREATE_TEMPLATE_SNAPSHOT_SUCCEEDED = 'BUSINESS:CREATE_TEMPLATE_SNAPSHOT_SUCCEEDED';
export const createTemplateSnapshotSucceeded = (response) => ({
    type: CREATE_TEMPLATE_SNAPSHOT_SUCCEEDED,
    payload: response,
});

export const CREATE_TEMPLATE_SNAPSHOT_FAILED = 'BUSINESS:CREATE_TEMPLATE_SNAPSHOT_FAILED';
export const createTemplateSnapshotFailed = (error) => ({
    type: CREATE_TEMPLATE_SNAPSHOT_FAILED,
    payload: { error },
});

export const CREATE_DOWNLOAD_REQUESTED = 'BUSINESS:CREATE_DOWNLOAD_REQUESTED';
export const createDownloadRequested = ()=>{
    return {
        type: CREATE_DOWNLOAD_REQUESTED,
    };
};

export const DOWNLOAD_COMPLETED = 'BUSINESS:DOWNLOAD_COMPLETED';
export const downloadCompleted = ()=>{
    return {
        type: DOWNLOAD_COMPLETED,
    };
};

export const EDITOR_INITIALIZED_REQUESTED = 'BUSINESS:EDITOR_INITIALIZED_REQUESTED';
export const editorInitializedRequested = () => ({
    type: EDITOR_INITIALIZED_REQUESTED,
});


//* DEFAULT THEME UPDATES
export const UPDATE_DEFAULT_THEME_REQUESTED = 'BUSINESS:UPDATE_DEFAULT_THEME_REQUESTED';
export const updateDefaultThemeRequested = () => ({
    type: UPDATE_DEFAULT_THEME_REQUESTED,
});

export const UPDATE_DEFAULT_THEME_SUCCEEDED = 'BUSINESS:UPDATE_DEFAULT_THEME_SUCCEEDED';
export const updateDefaultThemeRucceeded = (response) => ({
    type: UPDATE_DEFAULT_THEME_SUCCEEDED,
    payload: response,
});

export const UPDATE_DEFAULT_THEME_FAILED = 'BUSINESS:UPDATE_DEFAULT_THEME_FAILED';
export const updateDefaultThemeRailed = (error) => ({
    type: UPDATE_DEFAULT_THEME_FAILED,
    payload: { error },
});