import axios from 'axios';

import { AUTH_TOKEN } from '../../config';

const setAuthToken = () => axios.defaults.headers.common['X-CSRF-Token'] = AUTH_TOKEN;

export const getUserUploads = ({user_image_library_folder, page, per_page, search_term}) => {
    return axios.get(`/user_uploads_api.json`, {
        params: {
            user_image_library_folder,
            page,
            per_page,
            search_term
        }
    });
};

export const createUserUpload = ({name, file, user_image_library_folder}) => {
    setAuthToken();
    const data = new FormData();
    data.append('user_upload[name]', name);
    data.append('user_upload[image]', file);
    data.append('user_upload[user_image_library_folder]', user_image_library_folder);
    const headers = { headers: {'Content-Type': 'multipart/form-data'} };
    return axios.post(`/user_uploads_api.json`, data, headers);
};

export const deleteUserUpload = ({id}) => {
    console.log('deleting image id', id);
    setAuthToken();

    const data = new FormData();
    const headers = { headers: {'Content-Type': 'multipart/form-data'} };

    return axios.delete(`/user_uploads_api/${id}.json`, data, headers);
};
